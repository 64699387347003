import React, { useState, useEffect } from "react";
import { Stack } from '@mui/material';

import * as THREE from "three"

import { useStore } from "../zustand/objects";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Radio } from '@mantine/core';
import { ActionIcon, Text, Group } from '@mantine/core';
import { IconAdjustments, IconRefresh, IconMinus, IconPlus } from '@tabler/icons-react';


const ChangeScaleLayerListButtons = ({matrix, id, currentObjectArtboard}) => {
    const {objectsAreLoaded, updateObjectMatrix} = useStore()

    const ObjectUpdate = (val) => {
        updateObjectMatrix({
          id,
          currentObjectArtboard,
          matrix: new THREE.Matrix4().copy(val),
          undo: false
        })}

        const pos = new THREE.Vector3()
        const rot = new THREE.Quaternion()
        const sca = new THREE.Vector3()

        const newMatrix = new THREE.Matrix4()
        const scaleVector = new THREE.Vector3() 

useEffect(() => {
    matrix && new THREE.Matrix4().copy(matrix).decompose(pos, rot, sca)

},[matrix.elements])

function increaseSize(e) {
    new THREE.Matrix4().copy(matrix).decompose(pos, rot, sca)
    if (e==='all') {ObjectUpdate(new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(sca.x + 0.0625, sca.y + 0.0625, sca.z + 0.0625)))}
    if (e==='x') {ObjectUpdate(new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(sca.x + 0.0625, sca.y, sca.z)))}
    if (e==='y') {ObjectUpdate(new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(sca.x, sca.y + 0.0625, sca.z)))}
    if (e==='z') {ObjectUpdate(new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(sca.x, sca.y, sca.z + 0.0625)))}
   }


    
    async function reduceSize(e) {
        new THREE.Matrix4().copy(matrix).decompose(pos, rot, sca)
        if (e==='all') {ObjectUpdate(new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(sca.x === 0.0625 ? 0.0625: sca.x - 0.0625, sca.y === 0.0625 ? 0.0625: sca.y - 0.0625,sca.z === 0.0625 ? 0.0625: sca.z - 0.0625)))}
        if (e==='x') {ObjectUpdate(new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(sca.x === 0.0625 ? 0.0625: sca.x - 0.0625, sca.y, sca.z)))}
        if (e==='y') {sca.x >= 0.0625 && ObjectUpdate(new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(sca.x, sca.y === 0.0625 ? 0.0625: sca.y - 0.0625, sca.z)))}
        if (e==='z') {sca.x >= 0.0625 && ObjectUpdate(new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(sca.x, sca.y, sca.z === 0.0625 ? 0.0625: sca.z - 0.0625)))}
   }

   function resetSize(e) {
    new THREE.Matrix4().copy(matrix).decompose(pos, rot, sca)

    ObjectUpdate(new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(1,1,1)))

   }

   const [scaleMode, setScaleMode] = useState("all")

 
    return (
        <Stack style={{ width: "100%" }} direction="column">
            <Group mb={5}>
            <Text fw={700} size="xs" mt={5}>Resize</Text>
           
            <Stack direction="row" style={{marginTop: 5}}>
            <ActionIcon onClick={() => resetSize()} size={'xs'} variant="filled" aria-label="Settings" style={{borderRadius: 15, backgroundColor:"black", marginRight:5}}>
                <IconRefresh style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>
            <ActionIcon onClick={() => reduceSize(scaleMode )} size={'xs'} variant="filled" aria-label="Settings" style={{borderRadius: 15, backgroundColor:"white", borderColor:"black", marginRight:5}}>
                <IconMinus style={{ width: '70%', height: '70%', color:"black" }} stroke={1.5} />
            </ActionIcon>
            <ActionIcon onClick={() => increaseSize(scaleMode)} size={'xs'} variant="filled" aria-label="Settings" style={{borderRadius: 15, backgroundColor:"white", borderColor:"black", marginRight:5}}>
                <IconPlus style={{ width: '70%', height: '70%', color:"black" }} stroke={1.5} />
            </ActionIcon>
            </Stack>
            </Group>
        </Stack>
    )
}

// need to change scale and then send it to function as part of a matrix4

export default ChangeScaleLayerListButtons
