import React, { useState } from 'react';
import { useStore } from "../zustand/objects";
import { Grid, Modal, Box, Typography } from "@mui/material/";
import { LoadingOverlay, Card, Button, Text, Group, ActionIcon, Textarea } from '@mantine/core';

export default function TextModal() {

  const { 
    addTextObject,
    currentObjectArtboard,
    textModalOpen,
    switchTextModalOpen
  } = useStore();

  const [textOpen, setTextOpen] = useState(false);
  const [textValue, setTextValue] = useState('')

  const onTextSubmit = () => { 
    if (textValue) {
      addTextObject({ currentObjectArtboard, textKind: "plain text", content: textValue, matrix: null, undo: false })
      switchTextModalOpen()
    }
  }

  const onTextCancel = () => {
    switchTextModalOpen()
  }

  const handleChange = (e) => {
    setTextValue(e.target.value)
  }

  return (
    <Modal
      open={textModalOpen}
      onClose={onTextCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card style={{borderRadius:32, width: 500, padding:0,
        height: 180,  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
        <Grid container style={{padding:20}}>
          <Grid item xs={12}>
          <Text size="xl" fw={700}>
          Enter scene text
        </Text>
        </Grid>
        <Grid item xs={12}>
          
        <Textarea
          onChange={handleChange}
        />
        </Grid>
        <Grid item xs={12}>
        <Group>
        <Button 
          style={{
             paddingTop: 1, paddingBottom: 1, marginTop: 15, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
          }} onClick={() => onTextCancel()}>Cancel</Button>
        <Button 
          style={{
             paddingTop: 1, paddingBottom: 1, marginTop: 15, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
          }} onClick={() => onTextSubmit()}>Submit</Button>
          </Group>
        </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}