import { saveObjectUpdate, saveObjectAdd, saveObjectDelete, deleteSpecificAIModel, getSpecificAIModel, getSpecificUserProjectAPI, getSpecificUserProjectGuestAPI, saveUserProjectAPI, getUserAIModels, sendAIModelPrompt, updateFigmaFromID } from "../api";
import create from "zustand";
import { liveblocks } from "@liveblocks/zustand";
import { createClient } from "@liveblocks/client";
import produce from "immer";
import shortid from "shortid";
import * as THREE from "three";

const immer = (config) => (set, get, api) =>
  config((fn) => set(produce(fn)), get, api);

  const client = createClient({
    publicApiKey: "pk_dev_r2ck-Xo_Bl6SyTchgnl8JuRaj4MFB0aAdCvAdKf9Tcl5exQb6q1JVeG4iDsH5g5P",
  });

const _ = require('lodash');

// ~~~~~~~~~~~~~~~~~~~~~~~~
// STATE
// ~~~~~~~~~~~~~~~~~~~~~~~~
export const useStore =  create()(liveblocks((set) => ({
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // PROJECT STATE
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  isError: false,
  screenshotStatus: false,
  isMovingVRObject: false,
  isMenuOpen: false,
  tabValue: "elements",
  isOtherPreviewOpen: false,
  isDraggingModel: false,
  cursor: {},
  position: [0,0,-5],
  rotation: [0,0,0,0],
  setPosition: (val) => set(produce((state) => {
    state.position = val
  })),
  setRotation: (val) => set(produce((state) => {
    state.rotation = val
  })),
  preview: {},
  headset: {},
  projectName: "",
  projectUserName: "",
  projectUserActivity: "",
  projectUserOutcome: "",
  projectID: null,
  exportData: [],
  exportDataCaptured: false,
  artboards: {
    1: []
  },
  screenshots: {
    1: ""
  },
  aiModels : [],
  prompts: [],
  storyboardFields: {
    1: {
      "description": "",
      "comments": "",
      "name": ""
    }
  }, 
  floorColour: "lightGrey",
  transformMode: "translate",
  storyboardPOV: {
    1:{
      "rotation":[0,0,0,0],
      "position":[0,0,0]
    }
  },
  previewCameraPosition: [0, 0, 0],
  previewCameraRotation: [0, 0, 0],
  teleportCount: 0,
  screenshotTaken: false,
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // SESSION LOCAL STATES
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  cameraSelected: false,
  textModalOpen: false,
  shareModalOpen: false,
  userAccessModalOpen: false,
  addAIModalOpen: false,
  uploadModalOpen: false,
  imageModalOpen: false,
  searchModelsModalOpen: false, 
  imagePurpose: null,
  editingID: '', 
  undoActions: [],
  redoActions: [],
  undoing: false,
  editorMode: true,
  previewVR: true,
  shapeHovered: false,
  objectsAreLoaded: false,
  firstPreviewArtboardLoadDone: false,
  groupedObjects: [],
  grouping: false,
  dragging: false,
  teleporting: false,
  overTransformControls: false,
  setTabValue: (val) => set(produce((state) => {
    state.tabValue = val
  })),
  setIsOtherPreviewOpen: () => set(produce((state) => {
    state.isOtherPreviewOpen = !state.isOtherPreviewOpen
  })),
  setIsDraggingModel: (val) => set(produce((state) => {
    state.isDraggingModel = val
  })),
  setIsMovingVRObject: (val) => set(produce((state) => {
    state.isMovingVRObject = val
  })),
  setIsError: (val) => set(produce((state) => {
    state.isError = val
  })),
  setCameraSelected: (val) => set(produce((state) => {
    state.cameraSelected = val
  })),
  setCursor: (val) => set(produce((state) => {
    state.cursor = val
  })),
  setIsMenuOpen: (val) => set(produce((state) => {
    state.isMenuOpen = val
  })),
  setPreview: (val) => set(produce((state) => {
    state.preview = val
  })),
  setHeadset: (val) => set(produce((state) => {
  state.headset = val
  })),
  setOverTransformControls: (val) => set(produce((state) => {
    state.overTransformControls = val
  })),
  setGrouping: (val) => set(produce((state) => {
    state.grouping = val
    // state.groupedObjects = []
    state.selectedObjectID != 0 && state.groupedObjects.push(state.selectedObjectID)
    state.selectedObjectID = "0";
  })),
  setTeleporting: (val) => set(produce((state) => {
    state.teleporting = val
  })),
  setDragging: (val) => set(produce((state) => {
    state.dragging = val
  })),
  setTeleportCount: (val) => set(produce((state) => {
    state.teleportCount = val
  })),
  loadObjects: () => set(produce((state) => ({ objectsAreLoaded: true }))),
  setFirstPreviewArtboardLoadDone: (value) => set(produce((state) => {
    state.firstPreviewArtboardLoadDone = value
  })),
  setExportData: (value) => set(produce((state) => {
    state.exportData.push(value)
  })),
  setExportDataCaptured: (value) => set(produce((state) => {
    state.exportDataCaptured = value
  })),
  unloadObjects: () => set(produce(() => ({ objectsAreLoaded: false }))),
  switchTextModalOpen: () =>
    set(produce((state) => {
      state.textModalOpen = !state.textModalOpen;
    })),
  switchImageModalOpen: () =>
    set(produce((state) => {
      state.imageModalOpen = !state.imageModalOpen;
    })),
  switchsearchModelsModalOpen: () =>
    set(produce((state) => {
      state.searchModelsModalOpen = !state.searchModelsModalOpen;
    })),
  switchShareModalOpen: () =>
    set(produce((state) => {
      state.shareModalOpen = !state.shareModalOpen;
    })),
    switchUserAccessModalOpen: () =>
    set(produce((state) => {
      state.userAccessModalOpen = !state.userAccessModalOpen;
    })),
    switchAddAIModalOpen: () =>
    set(produce((state) => {
      state.addAIModalOpen = !state.addAIModalOpen;
    })),
  switchUploadModalOpen: () =>
    set(produce((state) => {
      state.uploadModalOpen = !state.uploadModalOpen;
    })),
  switchVRMode: () =>
    set(produce((state) => {
      state.previewVR = !state.previewVR;
    })),
  switchModes: () =>
    set(produce((state) => {
      state.editorMode = !state.editorMode;
    })),
  setScreenshotTaken: (value) =>
    set(produce((state) => {
      state.screenshotTaken = value
    })),
  setImagePurpose: (value) =>
    set(produce((state) => {
      state.imagePurpose = value
    })),
  setEditingID: (value) =>
    set(produce((state) => {
      state.editingID = value
    })),
  currentObjectArtboard: "1",
  selectedObjectID: "0",
  updateObjectSelected: (selectedValue) =>
    set(produce((state) => {
      state.selectedObjectID = selectedValue;
    })),
  shapeHoverChange: (value) =>
    set(produce((state) => {
      state.shapeHovered = value
    })),
  addToGroupedObjects: (id) =>
    set(produce((state) => {
      state.groupedObjects.push(id)
    })),
  removeFromGroupedObjects: (id) =>
    set(produce((state) => {
      state.groupedObjects = state.groupedObjects.filter(function (item) {
        return item !== id
      })
    })),
  clearGroupedObjects: () =>
    set(produce((state) => {
      state.groupedObjects = []
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // ZUSTAND UNDO/REDO FUNCTION
  // ~~~~~~~~~~~~~~~~~~~~~~~~  
  undoRedoZustand: (type) =>
  set(produce((state) => {
    if (type === "undo") {
      // state.liveblocks.room?.history.undo
      state.redoActions.unshift({action: type})
      state.undoActions.shift();
    } else { 
      // state.liveblocks.room?.history.redo
      state.undoActions.unshift({action: type})
      state.redoActions.shift();
    }
  })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // SWITCH TRANSFORMCONTROLS TRANSFORM MODE
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  transformModeChange: ({ transformMode }) =>
    set(produce((state) => {
      state.transformMode = transformMode;
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //  GET MODEL VIA PROMPT
  // ~~~~~~~~~~~~~~~~~~~~~~~~~
  setAIModelsByPrompts: (prompts) => prompts.map((mapped) => getSpecificAIModel(mapped)
  .then((res) => 
  set(produce((state) => {state.aiModels = [...state.aiModels, res]})))),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //  REMOVE AIMODEL
  // ~~~~~~~~~~~~~~~~~~~~~~~~~
  removeAIModel: (modelId, projectKey) =>
  deleteSpecificAIModel(modelId).then(response =>
    getUserAIModels().then(response =>
      set(produce((state) => {
        state.aiModels = response
      })))),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //  GET MODELS OWNED BY USER
  // ~~~~~~~~~~~~~~~~~~~~~~~~
    getAIModels: () => 
    getUserAIModels().then(response =>
      set(produce((state) => {
        state.aiModels = response
      }))),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //  SEND PROMPT AND GENERATE MODEL JSX, ADDING TO DATABASE
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  sendAIPrompt: (prompt, projectKey) => 
    sendAIModelPrompt({prompt: prompt, projectKey: projectKey}),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // LOAD A SPECIFIC USER PROJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // COULD SPLIT THIS. GET ALL PROJECT LEVEL INFO
  // THEN RUN SUBSEQUENT API REQUESTS TO GET ARTBOARD DATA BY ARTBOARD
  // FOR THE LATTER, SEND A RESPONSE IN THE INITIAL RESPONSE WITH AN ARRAY OF ARTBOARD NUMBERS [1,2,3,4]
  // THEN FOREACH OVER THAT, REQUEST ARTBOARD DATA AND STORYBOARD DATA FOR EACH NUMBER,
  // ALSO USING THE NUMBER AS AN ARTBOARD NUMBER PARAM FOR THE REQUEST
  getSpecificUserProject: (projectKey) =>
    getSpecificUserProjectAPI(projectKey)
      .then(response =>
        set(produce((state) => {
          state.artboards = response.artboards
          state.storyboardFields = response.storyboardFields
          state.projectName = response.projectName
          state.projectUserName = response.projectUserName
          state.projectUserActivity = response.projectUserActivity
          state.projectUserOutcome = response.projectUserOutcome
          state.projectID = projectKey
          state.objectsAreLoaded = true
          state.floorColour = response.floorColour
          state.prompts = response.prompts
        }))),
        // .then(() => set(produce((state) => {state.prompts.map((mapped) => {return getSpecificAIModel(mapped).then(res => {state.aiModels = [...state.aiModels, res]})})}))),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // LOAD A SPECIFIC USER PROJECT WITHOUT AUTH
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  getSpecificUserProjectGuest: (projectKey) =>
  getSpecificUserProjectGuestAPI(projectKey)
    .then(response =>
      set(produce((state) => {
        state.artboards = response.artboards
        state.storyboardFields = response.storyboardFields
        state.projectName = response.projectName
        state.projectUserName = response.projectUserName
        state.projectUserActivity = response.projectUserActivity
        state.projectUserOutcome = response.projectUserOutcome
        state.projectID = projectKey
        state.objectsAreLoaded = true
        state.floorColour = response.floorColour
        state.prompts = response.prompts
      }))),
      // .then(() => set(produce((state) => {state.prompts.map((mapped) => {return getSpecificAIModel(mapped).then(res => {state.aiModels = [...state.aiModels, res]})})}))),
      
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // SAVE USER PROJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  saveUserProject: () =>
    set(produce((state) => {

      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })

    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // CLEAR PROJECT STATE
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  wipeProjectState: () =>
    set(produce((state) => {
      state.artboards = { 1: [{ "id": "camera", "scale": [1, 0, 1], "object": "camera", "content": false, "category": false, "position": [0, 0, 0], "rotation": [0, 0, 0, 0], "destination": false, "objectTitle": "camera", "matrixState": new THREE.Quaternion() }] }
      state.screenshots = { 1: "" }
      state.storyboardFields = { 1: { "description": "", "comments": "", "name": "" } }
      state.projectName = ''
      state.projectUserName = ''
      state.projectUserActivity = ''
      state.projectUserOutcome = ''
      state.projectID = null
      state.firstPreviewArtboardLoadDone = false
      state.objectsAreLoaded = false
      state.floorColour = "lightGrey"
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // PROJECT ACTIONS
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // PASS THE FIELD BEING EDITED (E.G. NAME) TO A GENERAL EDIT PROJECT FUNCTION
  // THEN XANO FINDS THAT SPECIFIC PROJECT FIELD AND PASSES THE NEW VAL
  updateProjectName: (newVal) =>
    set(produce((state) => {
      state.projectName = newVal;
        // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
 
    })),
  setFloorColour: (value) =>
    set(produce((state) => {

      state.floorColour = value
        // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
 
    })),
  updateProjectUserName: (newVal) =>
    set(produce((state) => {
      state.projectUserName = newVal;
        // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
 
    })),
  updateProjectUserActivity: (newVal) =>
    set(produce((state) => {
      state.projectUserActivity = newVal;
        // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
 
    })),
  updateProjectUserOutcome: (newVal) =>
    set(produce((state) => {
      state.projectUserOutcome = newVal;
        // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, storyboardPOV: state.storyboardPOV, floorColour: state.floorColour })

 
    })),
  undoIndexPlus: () => set(produce((state) => {
    state.undoIndex++
  })),
  addScreenshot: (artboard, screenshot) =>
    set(produce((state) => {

      state.screenshots[artboard] = screenshot
 
    })),
  setScreenshotStatus: (val) =>
    set(produce((state) => {

      state.screenshotStatus = val
 
    })),
  addArtboardPositional: (position) =>
    set(produce((state) => {


      // CAPTURE NUMBERS OF CURRENT ARTBOARDS
      const firstHalf = Object.keys(state.artboards).slice(0, position)
      const secondHalf = Object.keys(state.artboards).slice(position)

      // SET UP OBJECTS TO BE LATER COPIED OVER AS NEW ARTBOARDS/STORYBOARD FIELDS ETC
      // Create an empty object to be used to store deep clones of all artboards/storyboardFields before the removed artboard
      let firstHalfArtboardClones = {}
      let firstHalfStoryboardFieldsClones = {}
      let firstHalfStoryboardPOVClones = {}

      // Create an empty object to be used to store deep clones of all artboards/storyboardFields subsequent to the removed artboard
      let secondHalfArtboardClones = {}
       let secondHalfStoryboardFieldsClones = {}
       let secondHalfStoryboardPOVClones = {}

       // CLONE DATA FROM STATE ONTO OBJECTS TO BE COPIED
      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const firstArtboardCloneMap = () => {
        firstHalf.map(x => {
          firstHalfArtboardClones[x] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const firstStoryboardFieldsCloneMap = () => {
        firstHalf.map(x => {
          firstHalfStoryboardFieldsClones[x] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const firstStoryboardPOVCloneMap = () => {
        firstHalf.map(x => {
          firstHalfStoryboardPOVClones[x] = _.cloneDeep(state.storyboardPOV[x])
        }
        )
      }
       // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const secondArtboardCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfArtboardClones[newNumber + 1] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const secondStoryboardFieldsCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfStoryboardFieldsClones[newNumber + 1] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const secondStoryboardPOVCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfStoryboardPOVClones[newNumber + 1] = _.cloneDeep(state.storyboardPOV[x])
        }
        )
      }
      // ADD BLANK ARTBOARDS/STORYBOARD FIELDS ONTO END OF FIRST HALF OBJECT FOR NEW ARTBOARD
      const addNewArtboard = () => {
        let pos = parseInt(position)
        firstHalfArtboardClones[pos + 1] = []
      }
      const addNewStoryboardField = () => {
        let pos = parseInt(position)
        firstHalfStoryboardFieldsClones[pos + 1] =  {
          "name": "",
          "comments": "",
          "description": ""
        }
      }
      const addNewStoryboardPOV = () => {
        let pos = parseInt(position)
        firstHalfStoryboardPOVClones[pos + 1] =  {
          "rotation": [0,0,0,0],
          "position": [0,1,0]
        }
      }

      const cloneFunctions = () => {
        firstArtboardCloneMap()
        firstStoryboardFieldsCloneMap()
        firstStoryboardPOVCloneMap()
        secondArtboardCloneMap()
        secondStoryboardFieldsCloneMap()
        secondStoryboardPOVCloneMap()
      }

      const addFunctions = () => {
        addNewArtboard()
        addNewStoryboardField()
        addNewStoryboardPOV()
      }
      // RUN FUNCTIONS
      cloneFunctions()
      addNewArtboard()
      addNewStoryboardField()
      addNewStoryboardPOV()
     
      //COMBINE EVERYTHING (PRINTING FOR TEST)
      // console.log({...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones})
      // console.log({...firstHalfArtboardClones, ...secondHalfArtboardClones})

      state.artboards = {...firstHalfArtboardClones, ...secondHalfArtboardClones}
      state.storyboardFields = {...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones}
      state.storyboardPOV = {...firstHalfStoryboardPOVClones, ...secondHalfStoryboardPOVClones}
      //one way to do it [..._.cloneDeep(artboardClones[x])]
      //   state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, storyboardPOV: state.storyboardPOV, floorColour: state.floorColour })
 
    })),
    //DUPLICATE ARTBOARD
    copyArtboardPositional: (position) =>
    set(produce((state) => {


      // CAPTURE NUMBERS OF CURRENT ARTBOARDS
      const firstHalf = Object.keys(state.artboards).slice(0, position)
      const secondHalf = Object.keys(state.artboards).slice(position)

      // SET UP OBJECTS TO BE LATER COPIED OVER AS NEW ARTBOARDS/STORYBOARD FIELDS ETC
      // Create an empty object to be used to store deep clones of all artboards/storyboardFields before the removed artboard
      let firstHalfArtboardClones = {}
      let firstHalfStoryboardFieldsClones = {}
      let firstHalfStoryboardPOVClones = {}

      // Create an empty object to be used to store deep clones of all artboards/storyboardFields subsequent to the removed artboard
      let secondHalfArtboardClones = {}
       let secondHalfStoryboardFieldsClones = {}
       let secondHalfStoryboardPOVClones = {}

       // CLONE DATA FROM STATE ONTO OBJECTS TO BE COPIED
      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const firstArtboardCloneMap = () => {
        firstHalf.map(x => {
          firstHalfArtboardClones[x] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const firstStoryboardFieldsCloneMap = () => {
        firstHalf.map(x => {
          firstHalfStoryboardFieldsClones[x] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const firstStoryboardPOVCloneMap = () => {
        firstHalf.map(x => {
          firstHalfStoryboardPOVClones[x] = _.cloneDeep(state.storyboardPOV[x])
        }
        )
      }
       // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const secondArtboardCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfArtboardClones[newNumber + 1] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const secondStoryboardFieldsCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfStoryboardFieldsClones[newNumber + 1] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const secondStoryboardPOVCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfStoryboardPOVClones[newNumber + 1] = _.cloneDeep(state.storyboardPOV[x])
        }
        )
      }
      // ADD BLANK ARTBOARDS/STORYBOARD FIELDS ONTO END OF FIRST HALF OBJECT FOR NEW ARTBOARD
      const addNewArtboard = () => {
        let pos = parseInt(position)
        firstHalfArtboardClones[pos + 1] = _.cloneDeep(state.artboards[pos])
      }
      const addNewStoryboardField = () => {
        let pos = parseInt(position)
        firstHalfStoryboardFieldsClones[pos + 1] =  _.cloneDeep(state.storyboardFields[pos])
      }
      const addNewStoryboardPOV = () => {
        let pos = parseInt(position)
        firstHalfStoryboardPOVClones[pos + 1] =  _.cloneDeep(state.storyboardPOV[pos])
      }

      const cloneFunctions = () => {
        firstArtboardCloneMap()
        firstStoryboardFieldsCloneMap()
        firstStoryboardPOVCloneMap()
        secondArtboardCloneMap()
        secondStoryboardFieldsCloneMap()
        secondStoryboardPOVCloneMap()
      }

      const addFunctions = () => {
        addNewArtboard()
        addNewStoryboardField()
        addNewStoryboardPOV()
      }
      // RUN FUNCTIONS
      cloneFunctions()
      addNewArtboard()
      addNewStoryboardField()
      addNewStoryboardPOV()
     
      //COMBINE EVERYTHING (PRINTING FOR TEST)
      // console.log({...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones})
      // console.log({...firstHalfArtboardClones, ...secondHalfArtboardClones})

      state.artboards = {...firstHalfArtboardClones, ...secondHalfArtboardClones}
      state.storyboardFields = {...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones}
      state.storyboardPOV = {...firstHalfStoryboardPOVClones, ...secondHalfStoryboardPOVClones}
      //one way to do it [..._.cloneDeep(artboardClones[x])]
      //   state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, storyboardPOV: state.storyboardPOV, floorColour: state.floorColour })
 
    })),
    // MOVE ARTBOARD
    moveArtboard: (source, destination) =>
      set(produce((state) => {
        const sourceName = source + 1
        const newArtboardKeys = Object.keys(state.artboards)
        newArtboardKeys.splice(source, 1)
        newArtboardKeys.splice(destination, 0, sourceName.toString())
        
        const newArtboards = {}
        let artboardIndex = 0
        for (const key of newArtboardKeys) {
          newArtboards[artboardIndex + 1] = _.cloneDeep(state.artboards[key]);
          artboardIndex++
        }

        const newStoryboardFields =  {}
        let storyIndex = 0
        for (const key of newArtboardKeys) {
          newStoryboardFields[storyIndex + 1] = _.cloneDeep(state.storyboardFields[key]);
          storyIndex++
        }

        const newStoryboardPOV =  {}
        let povIndex = 0
        for (const key of newArtboardKeys) {
          newStoryboardPOV[povIndex + 1] = _.cloneDeep(state.artboards[key]);
          povIndex++
        }

        
        // console.log("keys :", newArtboardKeys)
        // console.log("artboards :", newArtboards)
        // console.log("storyboard :", newStoryboardFields)
        // console.log("POV :", newStoryboardPOV)

      state.artboards = {...newArtboards}
      state.storyboardFields = {...newStoryboardFields}
      state.storyboardPOV = {...newStoryboardPOV}
     
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, storyboardPOV: state.storyboardPOV, floorColour: state.floorColour })

      // // CAPTURE NUMBERS OF CURRENT ARTBOARDS
      // const firstHalf = Object.keys(state.artboards).slice(0, destination)
      // const secondHalf = Object.keys(state.artboards).slice(destination)

      // // SET UP OBJECTS TO BE LATER COPIED OVER AS NEW ARTBOARDS/STORYBOARD FIELDS ETC
      // // Create an empty object to be used to store deep clones of all artboards/storyboardFields before the removed artboard
      // let firstHalfArtboardClones = {}
      // let firstHalfStoryboardFieldsClones = {}
      // let firstHalfStoryboardPOVClones = {}

      // // Create an empty object to be used to store deep clones of all artboards/storyboardFields subsequent to the removed artboard
      // let secondHalfArtboardClones = {}
      //  let secondHalfStoryboardFieldsClones = {}
      //  let secondHalfStoryboardPOVClones = {}

      //  // CLONE DATA FROM STATE ONTO OBJECTS TO BE COPIED
      // // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      // const firstArtboardCloneMap = () => {
      //   firstHalf.map(x => {
      //     firstHalfArtboardClones[x] = _.cloneDeep(state.artboards[x])
      //   }
      //   )
      // }
      // const firstStoryboardFieldsCloneMap = () => {
      //   firstHalf.map(x => {
      //     firstHalfStoryboardFieldsClones[x] = _.cloneDeep(state.storyboardFields[x])
      //   }
      //   )
      // }
      // const firstStoryboardPOVCloneMap = () => {
      //   firstHalf.map(x => {
      //     firstHalfStoryboardPOVClones[x] = _.cloneDeep(state.storyboardPOV[x])
      //   }
      //   )
      // }
      //  // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      // const secondArtboardCloneMap = () => {
      //   secondHalf.map(x => {
      //     let newNumber = parseInt(x)
      //     secondHalfArtboardClones[newNumber + 1] = _.cloneDeep(state.artboards[x])
      //   }
      //   )
      // }
      // const secondStoryboardFieldsCloneMap = () => {
      //   secondHalf.map(x => {
      //     let newNumber = parseInt(x)
      //     secondHalfStoryboardFieldsClones[newNumber + 1] = _.cloneDeep(state.storyboardFields[x])
      //   }
      //   )
      // }
      // const secondStoryboardPOVCloneMap = () => {
      //   secondHalf.map(x => {
      //     let newNumber = parseInt(x)
      //     secondHalfStoryboardPOVClones[newNumber + 1] = _.cloneDeep(state.storyboardPOV[x])
      //   }
      //   )
      // }
      // // ADD BLANK ARTBOARDS/STORYBOARD FIELDS ONTO END OF FIRST HALF OBJECT FOR NEW ARTBOARD
      // const addNewArtboard = () => {
      //   let pos = parseInt(destination)
      //   firstHalfArtboardClones[pos + 1] = scene.scene
      // }
      // const addNewStoryboardField = () => {
      //   let pos = parseInt(destination)
      //   firstHalfStoryboardFieldsClones[pos + 1] =  {
      //     "name": scene.Name,
      //     "comments": "",
      //     "description": scene.storyboard_fields.description
      //   }
      // }
      // const addNewStoryboardPOV = () => {
      //   let pos = parseInt(destination)
      //   firstHalfStoryboardPOVClones[pos + 1] =  {
      //     "rotation": [0,0,0,0],
      //     "position": [0,1,0]
      //   }
      // }

      // const cloneFunctions = () => {
      //   firstArtboardCloneMap()
      //   firstStoryboardFieldsCloneMap()
      //   firstStoryboardPOVCloneMap()
      //   secondArtboardCloneMap()
      //   secondStoryboardFieldsCloneMap()
      //   secondStoryboardPOVCloneMap()
      // }

      // // RUN FUNCTIONS
      // cloneFunctions()
      // addNewArtboard()
      // addNewStoryboardField()
      // addNewStoryboardPOV()
     

      // state.artboards = {...firstHalfArtboardClones, ...secondHalfArtboardClones}
      // state.storyboardFields = {...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones}
      // state.storyboardPOV = {...firstHalfStoryboardPOVClones, ...secondHalfStoryboardPOVClones}
     
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, storyboardPOV: state.storyboardPOV, floorColour: state.floorColour })
 
   
      })),
    addArtboardTemplatePositional: (position, scene) =>
    set(produce((state) => {


      // CAPTURE NUMBERS OF CURRENT ARTBOARDS
      const firstHalf = Object.keys(state.artboards).slice(0, position)
      const secondHalf = Object.keys(state.artboards).slice(position)

      // SET UP OBJECTS TO BE LATER COPIED OVER AS NEW ARTBOARDS/STORYBOARD FIELDS ETC
      // Create an empty object to be used to store deep clones of all artboards/storyboardFields before the removed artboard
      let firstHalfArtboardClones = {}
      let firstHalfStoryboardFieldsClones = {}
      let firstHalfStoryboardPOVClones = {}

      // Create an empty object to be used to store deep clones of all artboards/storyboardFields subsequent to the removed artboard
      let secondHalfArtboardClones = {}
       let secondHalfStoryboardFieldsClones = {}
       let secondHalfStoryboardPOVClones = {}

       // CLONE DATA FROM STATE ONTO OBJECTS TO BE COPIED
      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const firstArtboardCloneMap = () => {
        firstHalf.map(x => {
          firstHalfArtboardClones[x] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const firstStoryboardFieldsCloneMap = () => {
        firstHalf.map(x => {
          firstHalfStoryboardFieldsClones[x] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const firstStoryboardPOVCloneMap = () => {
        firstHalf.map(x => {
          firstHalfStoryboardPOVClones[x] = _.cloneDeep(state.storyboardPOV[x])
        }
        )
      }
       // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const secondArtboardCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfArtboardClones[newNumber + 1] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const secondStoryboardFieldsCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfStoryboardFieldsClones[newNumber + 1] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const secondStoryboardPOVCloneMap = () => {
        secondHalf.map(x => {
          let newNumber = parseInt(x)
          secondHalfStoryboardPOVClones[newNumber + 1] = _.cloneDeep(state.storyboardPOV[x])
        }
        )
      }
      // ADD BLANK ARTBOARDS/STORYBOARD FIELDS ONTO END OF FIRST HALF OBJECT FOR NEW ARTBOARD
      const addNewArtboard = () => {
        let pos = parseInt(position)
        firstHalfArtboardClones[pos + 1] = scene.scene
      }
      const addNewStoryboardField = () => {
        let pos = parseInt(position)
        firstHalfStoryboardFieldsClones[pos + 1] =  {
          "name": scene.Name,
          "comments": "",
          "description": scene.storyboard_fields.description
        }
      }
      const addNewStoryboardPOV = () => {
        let pos = parseInt(position)
        firstHalfStoryboardPOVClones[pos + 1] =  {
          "rotation": [0,0,0,0],
          "position": [0,1,0]
        }
      }

      const cloneFunctions = () => {
        firstArtboardCloneMap()
        firstStoryboardFieldsCloneMap()
        firstStoryboardPOVCloneMap()
        secondArtboardCloneMap()
        secondStoryboardFieldsCloneMap()
        secondStoryboardPOVCloneMap()
      }

      // RUN FUNCTIONS
      cloneFunctions()
      addNewArtboard()
      addNewStoryboardField()
      addNewStoryboardPOV()
     
      //COMBINE EVERYTHING (PRINTING FOR TEST)
      // console.log({...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones})
      // console.log({...firstHalfArtboardClones, ...secondHalfArtboardClones})

      state.artboards = {...firstHalfArtboardClones, ...secondHalfArtboardClones}
      state.storyboardFields = {...firstHalfStoryboardFieldsClones, ...secondHalfStoryboardFieldsClones}
      state.storyboardPOV = {...firstHalfStoryboardPOVClones, ...secondHalfStoryboardPOVClones}
      //one way to do it [..._.cloneDeep(artboardClones[x])]
      //   state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, storyboardPOV: state.storyboardPOV, floorColour: state.floorColour })
 
    })),

  addArtboard: () =>
    set(produce((state) => {
      let newNumber = Object.keys(state.artboards).length + 1;
      state.artboards[newNumber] = [
        ...state.artboards[Object.keys(state.artboards).length]
      ];
      state.storyboardFields[newNumber] = { "description": "", "comments": "", "name": "" }
      //   state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
 
    })),
  addUndoArtboard: (key, remainderArrayLength, value, storyboardValue) =>
    set(produce((state) => {

      const removeArtboardAction = {
        action: "removeArtboard",
        artboardNumber: key,
        oldArtboardData: value,
        oldStoryboardData: storyboardValue,
        remainderArrayLength: remainderArrayLength
      }

      let keysToChange = Object.keys(state.artboards).slice(state.artboards.length - (remainderArrayLength -= 1))

      let artboardClones = {}
      let storyboardFieldsClones = {}

      const artboardReplaceMap = () => {
        keysToChange.map(x => {
          let newKey = parseFloat(x)
          return state.artboards[newKey += 1] = _.cloneDeep(state.artboards[x])

        })
      }

      const storyboardFieldsReplaceMap = () => {
        keysToChange.map(x => {
          let newKey = parseFloat(x)
          return state.storyboardFields[newKey += 1] = _.cloneDeep(state.storyboardFields[x])

        })
      }

      // keysToChange.length >= 0 && artboardCloneMap()
      // keysToChange.length >= 0 && storyboardFieldsCloneMap()

      keysToChange.length > 1 && artboardReplaceMap()
      keysToChange.length > 1 && storyboardFieldsReplaceMap()

      state.artboards[key] = [...value]
      state.storyboardFields[key] = storyboardValue
      state.redoActions.unshift(removeArtboardAction)
      state.undoActions.shift();
      state.redoActions.length > 10 && state.redoActions.pop()

        // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
 
    })),
  removeArtboard: (key, undo) =>
    set(produce((state) => {

      // ~~~~~~~~~~~~~~~~~~~~~~~~
      //REMOVE ARTBOARD
      // ~~~~~~~~~~~~~~~~~~~~~~~~
      // Create an array copy of all artboard numbers AFTER the removed artboard
      let keysToChange = Object.keys(state.artboards).slice(key)
      // Create an empty object to be used to store deep clones of all artboards/storyboardFields subsequent to the removed artboard
      let artboardClones = {}
      let storyboardFieldsClones = {}
      let storyboardPOVClones = {}
      // iterate through array of subsequent artboards/storyboardFields, making a clone of each real state artboard into artboardClones (same for storyboards)
      const artboardCloneMap = () => {
        keysToChange.map(x => {
          artboardClones[x] = _.cloneDeep(state.artboards[x])
        }
        )
      }
      const storyboardFieldsCloneMap = () => {
        keysToChange.map(x => {
          storyboardFieldsClones[x] = _.cloneDeep(state.storyboardFields[x])
        }
        )
      }
      const storyboardPOVCloneMap = () => {
        keysToChange.map(x => {
          storyboardPOVClones[x] = _.cloneDeep(state.storyboardPOV[x])
        }
        )
      }

      // Create an object to hold a copy of the entire state, for undo/redo
      const undoArtboard = _.cloneDeep(state.artboards[key])

      const undoStoryboardFields = state.storyboardFields[key]
      
      const undoStoryboardPOV = state.storyboardPOV[key]

      // Ran after the selected artboard is deleted. This iterates through the state and replaces artboards with the new true data
      // if we didn't do this, removing an artboard like '3' would create a bad line of data (artboards: 1, 2, 4, 5)
      // this lets us keep the names of the artboards concurrent (1, 2, 3, 4, 5) remove 3 => (1, 2, 3, 4)
      const artboardReplaceMap = () => {
        keysToChange.map(x => {
          return state.artboards[x - 1] = [..._.cloneDeep(artboardClones[x])]

        })
      }
      // so we need to run keysToChange to get the artboard numbers that are being cloned
      // then we need to run artboardCloneMap to copy the artboard data for those artboards in keysToChange
      // then we need to run artboardReplaceMap to get the artboard data referenced in keysToChange 
      // and create new entries in state, naming artboards correctly (so artboard minus one if needed)
      // and copy the data from artboardClones to those artboards
      // (the same process is happening for the storyboardFields)
      // we also need to ensure the artboard is changed if you're deleting an artboard behind the current
      // so first active thing is checking if there are any artboards after the one you're deleting
      // if there are, you clone those artboards using the above process
      const storyboardFieldsReplaceMap = () => {
        keysToChange.map(x => {
          return state.storyboardFields[x - 1] = _.cloneDeep(storyboardFieldsClones[x])

        })
      }
      const storyboardPOVReplaceMap = () => {
        keysToChange.map(x => {
          return state.storyboardPOV[x - 1] = _.cloneDeep(storyboardPOVClones[x])

        })
      }
      // if the current artboard is greater than the deleted one, it can potentially crash the app, especially if 
      // it's the final artboard that gets deleted. this function changes the current artboard
      const changeCurrentArtboard = () => {
        state.currentObjectArtboard = state.currentObjectArtboard - 1
      }
      // this checks that there are actually artboards after the deleted one, then runs the cloning function further up
      keysToChange.length > 0 && artboardCloneMap()
      keysToChange.length > 0 && storyboardFieldsCloneMap()
      keysToChange.length > 0 && storyboardPOVCloneMap()

      // this deletes the selected arboard
      delete state.artboards[key];
      delete state.storyboardFields[key];
      delete state.storyboardPOV[key];
      // this iterates through the array of subsequent artboards and replaces state with the right data
      keysToChange.length > 0 && artboardReplaceMap()
      keysToChange.length > 0 && storyboardFieldsReplaceMap()
      keysToChange.length > 0 && storyboardPOVReplaceMap()
      // this fires the 'change current artboard' function if the current  artboard is greater than the deleted one
      state.currentObjectArtboard > key && changeCurrentArtboard()
      // this deletes the final artboard from state, as we don't need it, otherwise we'd have two copies of the final artboard data
      delete state.artboards[keysToChange.slice(-1)]
      delete state.storyboardFields[keysToChange.slice(-1)]
      delete state.storyboardPOV[keysToChange.slice(-1)]

        // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, storyboardPOV: state.storyboardPOV, floorColour: state.floorColour })
 
      // it appears the save function in XANO isn't replaceing the artboards object with the new one in params.
      // it's instead somehow shorhorning the changes into the same number of artboards
      // in some cases the state of the final remaining artboard is duplicated across all the artboards that
      // were deleted, in others this doesn't happen...
      // this is resolved, it was an issue on Xano's side
      // record function details
      // MY UNDO FUNCTION
      const removeArtboardAction = {
        action: "removeArtboard",
        artboardNumber: key,
        oldArtboardData: undoArtboard,
        oldStoryboardData: undoStoryboardFields,
        oldStoryboardPOV: undoStoryboardPOV,
        remainderArrayLength: keysToChange.length
      }

      // // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(removeArtboardAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      // // // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(removeArtboardAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
    })),
  updateArtboard: (artboard) => {
    set(produce((state) => {
      state.currentObjectArtboard = artboard;

    }))

  },
  updateStoryboardPOV: (position, rotation, artboard) => {
    set(produce((state) => {
      state.storyboardPOV[artboard].position = position
      state.storyboardPOV[artboard].rotation = rotation
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, storyboardPOV: JSON.stringify(state.storyboarPOV), floorColour: state.floorColour })
      // type === 'position' ? 
      // state.previewCameraPosition = value
      // : state.previewCameraRotation = value
    }))
  },
  updatePreviewCamera: (type, value) => {
    set(produce((state) => {
      type === 'position' ? 
      state.previewCameraPosition = value
      : state.previewCameraRotation = value
    }))
  },
  updatePreviewCameraRotation: (rotation) => {
    set(produce((state) => {
      // state.previewCameraPosition = [state.artboards[artboard].find((x) => x.id === "camera").position[0], 1, state.artboards[artboard].find((x) => x.id === "camera").position[2]]
      state.previewCameraRotation = rotation
    }))
  },
  updateArtboardAndPreviewCamera: (artboard) => {
    set(produce((state) => {
      state.teleportCount = 0
      state.currentObjectArtboard = artboard;
      state.selectedObjectID = "0"
      // state.previewCameraPosition = [state.artboards[artboard].find((x) => x.id === "camera").position[0], 1, state.artboards[artboard].find((x) => x.id === "camera").position[2]]
    }))
  },
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE CONTENT OF TEXT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateStoryboardFields: (artboard, fieldType, fieldValue) =>
    set(produce((state) => {
      //change field value to new content
      state.storyboardFields[artboard][fieldType] = fieldValue;

        // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
 
    })),
  updateTextContent: ({ id, currentObjectArtboard, content, undo }) =>
    set(produce((state) => {
      // get current content in state before change is applied
      const stateContent = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).content
      //change state content to new content
      state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).content = content;
        // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectUpdate({ projectKey: state.projectID, artboard: currentObjectArtboard, objectID: id, field: "content", data: content })
      //record old and new content in object
      const contentAction = {
        action: "updateTextContent",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        oldValue: stateContent,
        newValue: content
      }
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(contentAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()
      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(contentAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()
      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //ADD TEXT OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  addTextObject: ({ id, currentObjectArtboard, textKind, content, isLocked, colour, bGColour, position, rotation, scale, undo, matrix }) =>
    set(produce((state) => {

      const newObject = {
        id: shortid.generate(),
        object: textKind,
        category: "text",
        content: content,
        position: [0, 0, 0],
        rotation: [0, 0, 0, 0],
        matrixState: new THREE.Matrix4(),
        scale: [1, 1, 1],
        destination: "",
        objectTitle: content,
        colour: "black",
        bGColour: " "
      }

      const addAction = {
        action: "addTextObject",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        objectName: textKind,
        categoryName: "text",
        oldPosition: position,
        oldScale: scale,
        oldRotation: rotation,
        oldMatrixState: matrix,
        oldColour: colour,
        oldBGColour: bGColour,
        oldTextKind: textKind,
        oldContent: content,
        objectTitle: content,
        isLocked: isLocked
      }
      if (undo === false) {
        state.artboards[currentObjectArtboard].push({
          id: newObject.id,
          object: textKind,
          category: "text",
          content: content,
          position: [0, 0, 0],
          rotation: [0, 0, 0, 0],
          matrixState: new THREE.Matrix4(),
          scale: [1, 1, 1],
          destination: "",
          objectTitle: content,
          colour: "black",
          bGColour: " "
        });
        state.undoActions.unshift(addAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()
      }
      else {
        state.artboards[currentObjectArtboard].push({
          id: id,
          object: textKind,
          category: "text",
          objectName: textKind,
          content: content,
          textKind: textKind,
          position: position,
          rotation: rotation,
          matrixState: matrix,
          scale: scale,
          destination: "",
          objectTitle: content,
          colour: colour,
          bGColour: bGColour
        });
        state.redoActions.unshift(addAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()
      }
        // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectAdd({ projectKey: state.projectID, artboard: currentObjectArtboard, object: newObject})  

    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //COPY OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  copyObject: ({ currentObjectArtboard, id, undo }) =>
    set(produce((state) => {
      const duplicateObject = state.artboards[currentObjectArtboard].find((x) => x.id === id)
      const newID = shortid.generate()

      const newObject = {
        id: newID,
        object: duplicateObject.object,
        category: duplicateObject.category,
        content: duplicateObject.content,
        position: duplicateObject.position,
        rotation: duplicateObject.rotation,
        matrixState: duplicateObject.matrixState,
        scale: duplicateObject.scale,
        pose: duplicateObject.pose,
        destination: "",
        colour: duplicateObject.colour,
        objectTitle: `${duplicateObject.objectTitle} copy`,
        image: duplicateObject.image,
        imageSize: duplicateObject.imageSize,
        imageTexture: duplicateObject.imageTexture,
        isLocked: false,
        curved: duplicateObject.curved,
        curveAmount: duplicateObject.curveAmount,
        bGColour: duplicateObject.bGColour,
        figmaID: duplicateObject.figmaID,
        figmaURL: duplicateObject.figmaURL,
        figmaSize: duplicateObject.figmaSize
      }

      const addAction = {
        action: "copyObject",
        objectId: newID,
        objectArtboard: currentObjectArtboard,
        objectName: duplicateObject.object,
        categoryName: duplicateObject.category,
        oldPosition: duplicateObject.position,
        oldScale: duplicateObject.scale,
        oldRotation: duplicateObject.rotation,
        oldMatrixState: duplicateObject.matrixState,
        oldColour: duplicateObject.colour,
        oldBGColour: duplicateObject.bGColour,
        objectTitle: duplicateObject.objectTitle,
        imageTexture: duplicateObject.imageTexture,
        imageSize: duplicateObject.imageSize,
        image: duplicateObject.image,
        isLocked: false,
        curved: duplicateObject.curved,
        curveAmount: duplicateObject.curvedAmount
        // isGrouped: false
      }
      if (undo === false) {
        (duplicateObject.category === "shape") ?
          state.artboards[currentObjectArtboard].push({
            id: newID,
            object: duplicateObject.object,
            category: duplicateObject.category,
            content: false,
            position: duplicateObject.position,
            rotation: duplicateObject.rotation,
            matrixState: duplicateObject.matrixState,
            scale: duplicateObject.scale,
            destination: "",
            colour: duplicateObject.colour,
            objectTitle: `${duplicateObject.objectTitle} copy`,
            imageTexture: duplicateObject.imageTexture,
            isLocked: false
          }) :
          (duplicateObject.category === "image") ?
            state.artboards[currentObjectArtboard].push({
              id: newID,
              category: duplicateObject.category,
              content: false,
              position: duplicateObject.position,
              rotation: duplicateObject.rotation,
              matrixState: duplicateObject.matrixState,
              scale: duplicateObject.scale,
              pose: duplicateObject.pose,
              destination: "",
              colour: duplicateObject.colour,
              objectTitle: `${duplicateObject.objectTitle} copy`,
              image: duplicateObject.image,
              imageSize: duplicateObject.imageSize,
              isLocked: false,
              curved: duplicateObject.curved,
              curveAmount: duplicateObject.curveAmount
            })
            :
            state.artboards[currentObjectArtboard].push({
              id: newID,
              object: duplicateObject.object,
              category: duplicateObject.category,
              content: duplicateObject.content,
              position: duplicateObject.position,
              rotation: duplicateObject.rotation,
              matrixState: duplicateObject.matrixState,
              scale: duplicateObject.scale,
              pose: duplicateObject.pose,
              destination: "",
              colour: duplicateObject.colour,
              bGColour: duplicateObject.bGColour,
              objectTitle: `${duplicateObject.objectTitle} copy`,
              isLocked: false,
              curved: duplicateObject.curved,
              curveAmount: duplicateObject.curveAmount
            })
        state.undoActions.unshift(addAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()


      }
      else {
        (duplicateObject.category === "shape") ?
          state.artboards[currentObjectArtboard].push({
            id: newID,
            object: duplicateObject.object,
            category: duplicateObject.category,
            content: false,
            position: duplicateObject.position,
            rotation: duplicateObject.rotation,
            matrixState: duplicateObject.matrixState,
            scale: duplicateObject.scale,
            destination: "",
            colour: duplicateObject.colour,
            objectTitle: `${duplicateObject.objectTitle} copy`,
            imageTexture: duplicateObject.imageTexture,
            isLocked: false
          }) :
          (duplicateObject.category === "image") ?
            state.artboards[currentObjectArtboard].push({
              id: newID,
              category: duplicateObject.category,
              content: false,
              position: duplicateObject.position,
              rotation: duplicateObject.rotation,
              matrixState: duplicateObject.matrixState,
              scale: duplicateObject.scale,
              pose: duplicateObject.pose,
              destination: "",
              colour: duplicateObject.colour,
              objectTitle: `${duplicateObject.objectTitle} copy`,
              image: duplicateObject.image,
              imageSize: duplicateObject.imageSize,
              isLocked: false,
              curved: duplicateObject.curved,
              curveAmount: duplicateObject.curveAmount
            })
            :
            state.artboards[currentObjectArtboard].push({
              id: newID,
              object: duplicateObject.object,
              category: duplicateObject.category,
              content: duplicateObject.content,
              position: duplicateObject.position,
              rotation: duplicateObject.rotation,
              matrixState: duplicateObject.matrixState,
              scale: duplicateObject.scale,
              pose: duplicateObject.pose,
              destination: "",
              colour: duplicateObject.colour,
              bGColour: duplicateObject.bGColour,
              objectTitle: `${duplicateObject.objectTitle} copy`,
              isLocked: false,
              curved: duplicateObject.curved,
              curveAmount: duplicateObject.curveAmount
            })
        state.redoActions.unshift(addAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()
      }
        // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectAdd({ projectKey: state.projectID, artboard: currentObjectArtboard, object: newObject})  

    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  //ADD OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  addObject: ({ currentObjectArtboard, id, undo, category, object, position, scale, rotation, matrix, colour, pose, image, imageSize, imageTexture, aiKey, figmaID, figmaURL, figmaSize, uploadedURL, isLocked, copiedUndo, curved, curveAmount, bGColour, uploadId, databaseURL, databaseId }) =>
    set(produce((state) => {
      const newObject = { 
        id: shortid.generate(),
        object: object,
        category: category,
        content: false,
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Euler(),
        matrixState: new THREE.Matrix4(),
        scale:  [1, 1, 1], 
        destination: "",
        colour: "white",
        objectTitle: object,
        figmaID: figmaID,
        figmaURL: figmaURL,
        figmaSize: figmaSize,
        imageTexture: imageTexture,
        image:image,
        imageSize: imageSize,
        aiKey: aiKey,
        curved: false,
        curveAmount: 3,
        pose: { 
          name: pose.name,
          modelPath: pose.modelPath
        },
        bGColour: bGColour,
        isLocked: false,
        uploadedURL: uploadedURL,
        uploadId: uploadId,
        databaseURL: databaseURL,
        databaseId: databaseId
        // modelPath: modelPath
      }

      const addAction = {
        action: "addObject",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        objectName: object,
        categoryName: category,
        oldPosition: position,
        oldScale: scale,
        oldRotation: rotation,
        oldMatrixState: matrix,
        oldColour: colour,
        oldBGColour: bGColour,
        objectTitle: object,
        imageTexture: imageTexture,
        image:image,
        imageSize: imageSize,
        isLocked: isLocked,
        pose: {
          name: pose.name,
          modelPath: pose.modelPath
        },
        aiKey: aiKey,
        curved: curved,
        curveAmount: curveAmount,
        figmaID: figmaID,
        figmaURL: figmaURL,
        figmaSize: figmaSize,
        uploadedURL: uploadedURL,
        uploadId: uploadId,
        databaseURL: databaseURL,
        databaseId: databaseId
        // modelPath: modelPath
        // isGrouped: false
      }
      if (undo === false) {
        if (!copiedUndo) {
          (category === "shape") ?
            state.artboards[currentObjectArtboard].push({
              id: newObject.id,
              object: object,
              category: category,
              content: false,
              position: new THREE.Vector3(0, 0, 0),
              rotation: new THREE.Euler(),
              matrixState: new THREE.Matrix4(),
              scale: [1, 1, 1],
              destination: "",
              colour: "white",
              objectTitle: object,
              imageTexture: imageTexture,
              isLocked: false
              // isGrouped: false
            }) :
            (category === "image") ?
              state.artboards[currentObjectArtboard].push({
                id: newObject.id,
                category: category,
                object: "image",
                content: false,
                position: new THREE.Vector3(0, 0, 0),
                rotation: new THREE.Euler(), 
                matrixState: new THREE.Matrix4(),
                scale: [1, 1, 1],
                pose: pose,
                destination: "",
                colour: "white",
                objectTitle: category,
                image: image,
                imageSize: imageSize, 
                isLocked: false,
                curved: false,
                curveAmount: 3,
                pose: {
                  name: figmaID,
                  modelPath: figmaURL
                }
                // isGrouped: false
              }) :
              (category === "figma") ?
                state.artboards[currentObjectArtboard].push({
                  id: newObject.id,
                  category: category,
                  content: false,
                  position: new THREE.Vector3(0, 0, 0),
                  rotation: new THREE.Euler(),
                  matrixState: new THREE.Matrix4(),
                  scale: [1, 1, 1],
                  destination: "",
                  colour: "white",
                  objectTitle: category,
                  figmaID: figmaID,
                  figmaURL: figmaURL,
                  figmaSize: figmaSize,
                  isLocked: false,
                  curved: false,
                  curveAmount: 3,
                  pose: {
                    name: figmaID,
                    modelPath: figmaURL
                  },
                })
                :
                (category === "uploaded") ? 
                state.artboards[currentObjectArtboard].push({
                id: newObject.id,
                category: category,
                object: object,
                content: false,
                position: new THREE.Vector3(0, 0, 0),
                rotation: new THREE.Euler(),
                matrixState: new THREE.Matrix4(),
                scale: [1, 1, 1],
                pose: {
                  name: pose.name,
                  modelPath: pose.modelPath
                },
                destination: "",
                colour: "white",
                objectTitle: object,
                isLocked: false,
                uploadedURL: uploadedURL,
                uploadId: uploadId
                  // isGrouped: false
                })
              :
              (category === "ai") ? 
                state.artboards[currentObjectArtboard].push({
                  id: newObject.id,
                category: category,
                object: object,
                content: false,
                position: new THREE.Vector3(0, 0, 0),
                rotation: new THREE.Euler(),
                matrixState: new THREE.Matrix4(),
                scale: [1, 1, 1],
                pose: {
                  name: pose.name,
                  modelPath: pose.modelPath
                },                
                destination: "",
                colour: "white",
                objectTitle: object,
                isLocked: false,
                databaseURL: databaseURL,
                databaseId: databaseId
                  // isGrouped: false
                })
              :
              state.artboards[currentObjectArtboard].push({
                id: newObject.id,
                object: object,
                category: category,
                content: false,
                position: new THREE.Vector3(0, 0, 0),
                rotation: new THREE.Euler(),
                matrixState: new THREE.Matrix4(),
                scale: [1, 1, 1],
                pose: {
                  name: pose.name,
                  modelPath: pose.modelPath
                },
                destination: "",
                colour: "white",
                objectTitle: object,
                isLocked: false,
                aiKey: aiKey,
                curved: false,
                curveAmount: 3,
                bGColour: bGColour
                // modelPath: modelPath
                // isGrouped: false
              })
        }
        else {
          (category === "shape") ?
            state.artboards[currentObjectArtboard].push({
              id: id,
              object: object,
              category: category,
              content: false,
              position: position,
              scale: scale,
              rotation: rotation,
              matrixState: matrix,
              destination: "",
              colour: colour,
              objectTitle: object,
              imageTexture: imageTexture,
              isLocked: isLocked,
              pose: pose
              // isGrouped: false
            }) : 
            (category === "image") ?
              state.artboards[currentObjectArtboard].push({
                id: id,
                object: "image",
                category: category,
                content: false,
                position: position,
                rotation: rotation,
                matrixState: matrix,
                scale: scale,
                pose: {
                  name: pose.name,
                  modelPath: pose.modelPath
                },
                destination: "",
                colour: "white",
                objectTitle: category,
                image: image,
                imageSize: imageSize,
                isLocked: isLocked,
                curved: curved,
                curveAmount: curveAmount
                // isGrouped: false
              }) :
              (category === "figma") ?
                state.artboards[currentObjectArtboard].push({
                  id: id,
                  category: category,
                  content: false,
                  position: new THREE.Vector3(0, 0, 0),
                  rotation: new THREE.Euler(),
                  matrixState: new THREE.Matrix4(),
                  scale: [1, 1, 1],
                  destination: "",
                  colour: "white",
                  objectTitle: category,
                  figmaID: figmaID,
                  figmaURL: figmaURL,
                  figmaSize: figmaSize,
                  isLocked: false,
                  curved: curved,
                  curveAmount: curveAmount
                })
              :
              (category === "uploaded") ? 
              state.artboards[currentObjectArtboard].push({
                id: id,
                object: object,
                category: category,
                content: false,
                position: position,
                scale: scale,
                rotation: rotation,
                matrixState: matrix,
                destination: "",
                colour: colour,
                objectTitle: object,
                isLocked: isLocked,
                uploadedURL: uploadedURL,
                uploadId: uploadId
                // isGrouped: false
              })
              :
              (category === "ai") ? 
                state.artboards[currentObjectArtboard].push({
                  id: id,
                category: category,
                object: object,
                content: false,
                position: new THREE.Vector3(0, 0, 0),
                rotation: new THREE.Euler(),
                matrixState: new THREE.Matrix4(),
                scale: [1, 1, 1],
                pose: {
                  name: pose.name,
                  modelPath: pose.modelPath
                },
                destination: "",
                colour: "white",
                objectTitle: object,
                isLocked: false,
                databaseURL: databaseURL,
                databaseId: databaseId
                  // isGrouped: false
                })
              :
              state.artboards[currentObjectArtboard].push({
                id: id,
                object: object,
                category: category,
                content: false,
                position: position,
                scale: scale,
                rotation: rotation,
                matrixState: matrix,
                destination: "",
                colour: colour,
                objectTitle: object,
                isLocked: isLocked,
                aiKey: aiKey,
                curved: false,
                curveAmount: 3,
                pose: {
                  name:pose.name,
                  modelPath: pose.modelPath
                },
                bGColour: bGColour
              })
        }
        state.undoActions.unshift(addAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      else {
        (category === "shape") ?
          state.artboards[currentObjectArtboard].push({
            id: id,
            object: object,
            category: category,
            content: false,
            position: position,
            scale: scale,
            rotation: rotation,
            matrixState: matrix,
            destination: "",
            colour: colour,
            objectTitle: object,
            imageTexture: imageTexture,
            isLocked: isLocked
            // isGrouped: false
          }) :
          (category === "image") ?
            state.artboards[currentObjectArtboard].push({
              id: shortid.generate(),
              object: "image",
              category: "image",
              content: false,
              position: position,
              rotation: rotation,
              matrixState: matrix,
              scale: scale,
              pose: {
                name: pose.name,
                modelPath: pose.modelPath
              },
              destination: "",
              colour: "rgb(250, 199, 16)",
              objectTitle: category,
              image: image,
              imageSize: imageSize,
              isLocked: isLocked,
              curved:curved,
              curveAmount: curveAmount
              // isGrouped: false
            })
            :
            (category === "uploaded") ? 
            state.artboards[currentObjectArtboard].push({
              id: id,
              object: object,
              category: category,
              content: false,
              position: position,
              scale: scale,
              rotation: rotation,
              matrixState: matrix,
              destination: "",
              colour: colour,
              objectTitle: object,
              isLocked: isLocked,
              uploadedURL: uploadedURL,
              uploadId: uploadId
              // isGrouped: false
            })
            :
            (category === "ai") ? 
                state.artboards[currentObjectArtboard].push({
                id: shortid.generate(),
                category: category,
                object: object,
                content: false,
                position: new THREE.Vector3(0, 0, 0),
                rotation: new THREE.Euler(),
                matrixState: new THREE.Matrix4(),
                scale: [1, 1, 1],
                pose: {
                  name: pose.name,
                  modelPath: pose.modelPath
                },
                destination: "",
                colour: "white",
                objectTitle: object,
                isLocked: false,
                databaseURL: databaseURL,
                databaseId: databaseId
                })
              :
            state.artboards[currentObjectArtboard].push({
              id: id,
              object: object,
              category: category,
              content: false,
              position: position,
              scale: scale,
              rotation: rotation,
              matrixState: matrix,
              destination: "",
              colour: colour,
              objectTitle: object,
              imageTexture: imageTexture,
              isLocked: isLocked,
              pose: {
                name: pose.name,
                modelPath: pose.modelPath
              },
              aiKey: aiKey,
              curved: curved,
              curveAmount: curveAmount,
              bGColour: bGColour
            })
        state.redoActions.unshift(addAction) 
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
        // state.saveUserProject() 
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectAdd({ projectKey: state.projectID, artboard: currentObjectArtboard, object: newObject})  
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
 
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // REMOVE OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  removeObject: ({ currentObjectArtboard, id, undo, category, content, textKind, object, position, scale, rotation, colour, matrix, copiedUndo, image, imageSize, imageTexture, curved, curveAmount, pose, uploadedURL, bGColour }) =>
    set(produce((state) => {
      state.artboards[currentObjectArtboard].splice(
        state.artboards[currentObjectArtboard].findIndex((x) => x.id === id),
        1 
      );
      // record function details
      const removeAction = {
        action: (category !== "text") ? "removeObject" : "removeTextObject",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        objectName: object,
        categoryName: category,
        oldPosition: position,
        oldScale: scale,
        oldRotation: rotation,
        oldMatrixState: matrix,
        oldColour: colour,
        oldBGColour: bGColour,
        oldContent: content,
        oldTextKind: textKind,
        objectTitle: object,
        copiedUndo: copiedUndo,
        pose: pose,
        imageSize: imageSize,
        image: image,
        imageTexture: imageTexture,
        curved: curved,
        curveAmount: curveAmount,
        uploadedURL: uploadedURL
      }
      // send to undo array if undo is false, as in it's not an undo action being fired, so we need to be able to undo it
      if (undo === false) {
        state.undoActions.unshift(removeAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()
      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(removeAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()
      }
        // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectDelete({ projectKey: state.projectID, artboard: currentObjectArtboard, objectID: id })
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE MATRIX OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateObjectMatrix: ({ id, currentObjectArtboard, matrix, undo }) =>
    set(produce((state) => {
      // get current position in state before change is applied
      // console.log(matrix)
      const stateArrayMatrix = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).matrixState
      //check if new/old position are same 
      if (Array.isArray(stateArrayMatrix) &&
        Array.isArray(matrix) &&
        stateArrayMatrix.length === matrix.length &&
        stateArrayMatrix.every((val, index) => val === matrix[index])) {
      }
      //if not equal, change position and add to newUndoActions
      //change state position to new position sent from model component
      else {
        state.artboards[currentObjectArtboard].find(
          (x) => x.id === id
        ).matrixState = new THREE.Matrix4().copy(matrix);
        // state.dragging = false

          // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectUpdate({ projectKey: state.projectID, artboard: currentObjectArtboard, objectID: id, field: "matrixState", data: matrix })


        //record old and new position in object, with function type, id and artboard
        const matrixAction = {
          action: "updateObjectMatrix",
          objectId: id,
          objectArtboard: currentObjectArtboard,
          oldValue: stateArrayMatrix,
          newValue: matrix
          // oldValue: [...stateArrayPosition],
          // newValue: [...position]
        }


        // send to undo array if undo is false, as in it's not an undo action being fired
        if (undo === false) {
          state.undoActions.unshift(matrixAction)
          state.redoActions.shift();
          state.undoActions.length > 10 && state.undoActions.pop()

        }
        // send to redo array if undo is true, as in this is an undo being fired please enable redo
        else {
          state.redoActions.unshift(matrixAction)
          state.undoActions.shift();
          state.redoActions.length > 10 && state.redoActions.pop()

        }
        // if (id === "camera") {
        //   state.previewCameraPosition = position;
        // }
      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE POSITION OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // ADD AN 'UPDATE OBJECT' SAVE API FUNCTION THAT FINDS THE OBJECT BY ID IN XANO
  // THEN ADD THE ENTIRE NEW OBJECT OBJECT INCLUDING ALL NEW STUFF (POSITION, COLOUR OR OTHERWISE)
  updateObjectPosition: ({ id, currentObjectArtboard, position, undo }) =>
    set(produce((state) => {


      // get current position in state before change is applied
      const stateArrayPosition = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).position
      //check if new/old position are same
      if (Array.isArray(stateArrayPosition) &&
        Array.isArray(position) &&
        stateArrayPosition.length === position.length &&
        stateArrayPosition.every((val, index) => val === position[index])) {
      }
      //if not equal, change position and add to newUndoActions
      //change state position to new position sent from model component
      else {
        state.artboards[currentObjectArtboard].find(
          (x) => x.id === id
        ).position = position;
          // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
   


        //record old and new position in object, with function type, object id and artboard
        const positionAction = {
          action: "updateObjectPosition",
          objectId: id,
          objectArtboard: currentObjectArtboard,
          oldValue: stateArrayPosition,
          newValue: position
          // oldValue: [...stateArrayPosition],
          // newValue: [...position]
        }
        // send to undo array if undo is false, as in it's not an undo action being fired
        if (undo === false) {
          state.undoActions.unshift(positionAction)
          state.redoActions.shift();
          state.undoActions.length > 10 && state.undoActions.pop()

        }
        // send to redo array if undo is true, as in this is an undo being fired please enable redo
        else {
          state.redoActions.unshift(positionAction)
          state.undoActions.shift();
          state.redoActions.length > 10 && state.redoActions.pop()

        }
        if (id === "camera") {
          state.previewCameraPosition = position;
        }
      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE ROTATION OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateObjectRotation: ({ id, currentObjectArtboard, rotation, undo }) =>
    set(produce((state) => {
      // console.log(rotation)
      // capture rotation before change
      const stateArrayRotation = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).rotation
      //check if new/old rotation are same
      if (Array.isArray(stateArrayRotation) &&
        Array.isArray(rotation) &&
        stateArrayRotation.length === [rotation.x, rotation.y, rotation.z].length &&
        stateArrayRotation.every((val, index) => val === [rotation.x, rotation.y, rotation.z][index])) {

      }
      //if not equal, change rotation
      else {
        state.artboards[currentObjectArtboard].find(
          (x) => x.id === id
        ).rotation = rotation
          // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
   
        //record action including the old state
        const rotationAction = {
          action: "updateObjectRotation",
          objectId: id,
          objectArtboard: currentObjectArtboard,
          oldValue: stateArrayRotation,
          newValue: rotation
        }
        // send to undo array if undo is false, as in it's not an undo action being fired
        if (undo === false) {
          state.undoActions.unshift(rotationAction)
          state.redoActions.shift();
          state.undoActions.length > 10 && state.undoActions.pop()

        }
        // send to redo array if undo is true, as in this is an undo being fired please enable redo
        else {
          state.redoActions.unshift(rotationAction)
          state.undoActions.shift();
          state.redoActions.length > 10 && state.redoActions.pop()

        }
      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE SCALE OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateObjectScale: ({ id, currentObjectArtboard, scale, undo }) =>
    set(produce((state) => {
      // capture scale before change
      const stateArrayScale = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).scale
      //check if new/old scale are same
      if (Array.isArray(stateArrayScale) &&
        Array.isArray(scale) &&
        stateArrayScale.length === scale.length &&
        stateArrayScale.every((val, index) => val === scale[index])) {

      }
      //if not equal, change scale
      else {
        state.artboards[currentObjectArtboard].find(
          (x) => x.id === id
        ).scale = scale;
          // state.saveUserProject()
      saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
   
        //record action including the old state
        const scaleAction = {
          action: "updateObjectScale",
          objectId: id,
          objectArtboard: currentObjectArtboard,
          oldValue: [...stateArrayScale],
          newValue: [...scale]
        }
        // send to undo array if undo is false, as in it's not an undo action being fired
        if (undo === false) {
          state.undoActions.unshift(scaleAction)
          state.redoActions.shift();
          state.undoActions.length > 10 && state.undoActions.pop()

        }
        // send to redo array if undo is true, as in this is an undo being fired please enable redo
        else {
          state.redoActions.unshift(scaleAction)
          state.undoActions.shift();
          state.redoActions.length > 10 && state.redoActions.pop()

        }
      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE CLICK DESTINATION OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateDestination: ({ id, currentObjectArtboard, destination, undo }) =>
    set(produce((state) => {
      // capture destination before change
      const stateDestination = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).destination

      state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).destination = destination;
        // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectUpdate({ projectKey: state.projectID, artboard: currentObjectArtboard, objectID: id, field: "destination", data: destination })


      //record action including the old state
      const colourShapeAction = {
        action: "updateDestination",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        oldValue: stateDestination,
        newValue: destination
      }
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(colourShapeAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(colourShapeAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE ISLOCKED OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateIsLocked: ({ id, undo }) =>
    set(produce((state) => {
      // capture destination before change
      const stateIsLocked = state.artboards[state.currentObjectArtboard].find(
        (x) => x.id === id
      ).isLocked
      state.artboards[state.currentObjectArtboard].find(
        (x) => x.id === id
      ).isLocked = !stateIsLocked;
        // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "isLocked", data: !stateIsLocked })

      //record action including the old state
      const colourIsLockedAction = {
        action: "updateIsLocked",
        objectId: id,
        objectArtboard: state.currentObjectArtboard,
        oldValue: stateIsLocked,
        newValue: !stateIsLocked
      }
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(colourIsLockedAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(colourIsLockedAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE CURVED OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateCurved: ({ id, undo }) =>
  set(produce((state) => {
    // capture destination before change
    const stateCurved = state.artboards[state.currentObjectArtboard].find(
      (x) => x.id === id
    ).curved
    state.artboards[state.currentObjectArtboard].find(
      (x) => x.id === id
    ).curved = !stateCurved
      // state.saveUserProject()
    // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
    saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "curved", data: !stateCurved })
    //record action including the old state
    const curvedAction = {
      action: "updateCurved",
      objectId: id,
      objectArtboard: state.currentObjectArtboard,
      oldValue: stateCurved,
      newValue: !stateCurved
    }
    // send to undo array if undo is false, as in it's not an undo action being fired
    if (undo === false) {
      state.undoActions.unshift(curvedAction)
      state.redoActions.shift();
      state.undoActions.length > 10 && state.undoActions.pop()

    }
    // send to redo array if undo is true, as in this is an undo being fired please enable redo
    else {
      state.redoActions.unshift(curvedAction)
      state.undoActions.shift();
      state.redoActions.length > 10 && state.redoActions.pop()

    }
  })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE CURVEAMOUNT OF OBJECT
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateCurveAmount: ({ id, val, undo }) =>
  set(produce((state) => {
    // capture destination before change
    const stateCurveAmount = state.artboards[state.currentObjectArtboard].find(
      (x) => x.id === id
    ).curveAmount
    state.artboards[state.currentObjectArtboard].find(
      (x) => x.id === id
    ).curveAmount = val
      // state.saveUserProject()
    // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
    saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "curveAmount", data: val })
    //record action including the old state
    const curveAmountAction = {
      action: "updateCurveAmount",
      objectId: id,
      objectArtboard: state.currentObjectArtboard,
      oldValue: stateCurveAmount,
      newValue: val
    }
    // send to undo array if undo is false, as in it's not an undo action being fired
    if (undo === false) {
      state.undoActions.unshift(curveAmountAction)
      state.redoActions.shift();
      state.undoActions.length > 10 && state.undoActions.pop()

    }
    // send to redo array if undo is true, as in this is an undo being fired please enable redo
    else {
      state.redoActions.unshift(curveAmountAction)
      state.undoActions.shift();
      state.redoActions.length > 10 && state.redoActions.pop()

    }
  })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE COLOUR OF SHAPE OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateShapeColour: ({ id, currentObjectArtboard, colour, undo }) =>
    set(produce((state) => {
      // capture colour before change
      const stateColour = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).colour

      state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).colour = colour;
        // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "colour", data: colour })

      //record action including the old state
      const colourShapeAction = {
        action: "updateShapeColour",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        oldValue: stateColour,
        newValue: colour
      }
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(colourShapeAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(colourShapeAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE COLOUR OF MODEL OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateModelColour: ({ id, currentObjectArtboard, colour, undo }) =>
    set(produce((state) => {
      // capture colour before change
      const stateColour = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).colour

      state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).colour = colour;
        // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "colour", data: colour })


      //record action including the old state
      const colourModelAction = {
        action: "updateModelColour",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        oldValue: stateColour,
        newValue: colour
      }
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(colourModelAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(colourModelAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE BACKGROUND COLOUR OF TEXT OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateTextBackgroundColour: ({ id, currentObjectArtboard, bGColour, undo }) =>
    set(produce((state) => {
      // capture colour before change
      const stateColour = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).bGColour

      state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).bGColour = bGColour;
        // state.saveUserProject() 
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "bGColour", data: bGColour })


      //record action including the old state
      const colourTextBackgroundAction = {
        action: "updateTextBackgroundColour",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        oldValue: stateColour,
        newValue: bGColour
      }
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(colourTextBackgroundAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(colourTextBackgroundAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
    })),
// ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE URL OF FIGMA OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateFigmaURL: ({ id, currentObjectArtboard, figmaID, undo }) =>
  updateFigmaFromID(figmaID).then((res) => 
    set(produce((state) => {
      // capture colour before change
      const stateFigmaURL = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).figmaURL

      // state.artboards[currentObjectArtboard].find(
      //   (x) => x.id === id
      // ).figmaURL = res.image.url;
      // state.artboards[currentObjectArtboard].find(
      //   (x) => x.id === id
      // ).pose.modelPath = res.image.url;
        // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "figmaURL", data: res.image.url })


      //record action including the old state
      const figmaURLAction = {
        action: "updateFigmaURL",
        objectId: id,
        oldValue: stateFigmaURL,
        newValue: res.URL
      }
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(figmaURLAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(figmaURLAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
    }))),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE POSE OF OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updatePose: ({ id, currentObjectArtboard, pose, modelPath, undo }) =>
    set(produce((state) => {
      // capture pose before change
      const statePose = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).pose

      state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).pose = pose;
      // state.artboards[currentObjectArtboard].find(
      //   (x) => x.id === id
      // ).modelPath = modelPath;

      saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "pose", data: pose })


      //record action including the old state
      const poseAction = {
        action: "updatePose",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        oldValue: statePose,
        newValue: pose,
        newModelPath: modelPath
      }
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(poseAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(poseAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE TITLE OF OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateObjectTitle: (id, currentObjectArtboard, title, undo) =>
    set(produce((state) => {
      // capture title before change
      const stateTitle = state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).objectTitle

      state.artboards[currentObjectArtboard].find(
        (x) => x.id === id
      ).objectTitle = title;


      //record action including the old state
      const titleAction = {
        action: "updateObjectTitle",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        oldValue: stateTitle,
        newValue: title
      }
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(titleAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(titleAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
        // state.saveUserProject()
        saveObjectUpdate({ projectKey: state.projectID, artboard: currentObjectArtboard, objectID: id, field: "objectTitle", data: title })
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
 
    })),
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE IMAGE TEXTURE FOR SHAPE OBJECT 
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateImageTexture: (id, currentObjectArtboard, val, undo) =>
    set(produce((state) => {
      // capture texture before change

      const stateImageTexture = state.artboards[state.currentObjectArtboard].find(
        (x) => x.id === state.selectedObjectID
      ).imageTexture

      state.artboards[state.currentObjectArtboard].find(
        (x) => x.id === state.selectedObjectID
      ).imageTexture = val;
        // state.saveUserProject()
      // saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
      saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "imageTexture", data: val })

      //record action including the old state
      const imageTextureAction = {
        action: "updateImageTexture",
        objectId: id,
        objectArtboard: currentObjectArtboard,
        oldValue: stateImageTexture,
        newValue: val
      }
      // send to undo array if undo is false, as in it's not an undo action being fired
      if (undo === false) {
        state.undoActions.unshift(imageTextureAction)
        state.redoActions.shift();
        state.undoActions.length > 10 && state.undoActions.pop()

      }
      // send to redo array if undo is true, as in this is an undo being fired please enable redo
      else {
        state.redoActions.unshift(imageTextureAction)
        state.undoActions.shift();
        state.redoActions.length > 10 && state.redoActions.pop()

      }
    })),
    // ~~~~~~~~~~~~~~~~~~~~~~~~
  // UPDATE IMAGE OBJECT URL
  // ~~~~~~~~~~~~~~~~~~~~~~~~
  updateImageObjectURL: (id, currentObjectArtboard, image, imageSize, undo) =>
  set(produce((state) => {
    // capture texture before change

    const stateImageURL = state.artboards[state.currentObjectArtboard].find(
      (x) => x.id === state.selectedObjectID
    ).image
    const stateImageSize = state.artboards[state.currentObjectArtboard].find(
      (x) => x.id === state.selectedObjectID
    ).imageSize

    state.artboards[state.currentObjectArtboard].find(
      (x) => x.id === state.selectedObjectID
    ).image = image;
    state.artboards[state.currentObjectArtboard].find(
      (x) => x.id === state.selectedObjectID
    ).imageSize = imageSize;
      // state.saveUserProject()
    saveUserProjectAPI({ projectID: state.projectID, projectName: state.projectName, projectUserName: state.projectUserName, projectUserActivity: state.projectUserActivity, projectUserOutcome: state.projectUserOutcome, artboards: state.artboards, screenshots: state.screenshots, storyboardFields: state.storyboardFields, floorColour: state.floorColour })
    // saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "imageSize", data: imageSize })
    // saveObjectUpdate({ projectKey: state.projectID, artboard: state.currentObjectArtboard, objectID: id, field: "image", data: image })

    //record action including the old state
    const imageURLAction = {
      action: "updateImageTexture",
      objectId: id,
      objectArtboard: currentObjectArtboard,
      oldImage: stateImageURL,
      newImage: image,
      oldImageSize: stateImageSize,
      newImageSize: imageSize
    }
    // send to undo array if undo is false, as in it's not an undo action being fired
    if (undo === false) {
      state.undoActions.unshift(imageURLAction)
      state.redoActions.shift();
      state.undoActions.length > 10 && state.undoActions.pop()

    }
    // send to redo array if undo is true, as in this is an undo being fired please enable redo
    else {
      state.redoActions.unshift(imageURLAction)
      state.undoActions.shift();
      state.redoActions.length > 10 && state.redoActions.pop()

    }
  }))
  }),
{ client,
  presenceMapping: {
    cursor: true,
    preview: true,
    headset: true,
  },
  storageMapping: {
    floorColour: true,
    projectName: true,
    artboards: true,
    storyboardFields: true,
    storyboardPOV: true
  }}
  ))
  //  CAN WE GET THE ROOM OUTSIDE OF HERE? MAYBE THERE'S A 'GET ROOM' METHOD, LIKE THERE'S THE 'ENTER ROOM' METHOD USED IN VIEWPORT

export const subscribeToEditor = (projectKey) => {
  const roomToSubscribe = client.getRoom(`matchboxxr-editor${projectKey}`)

  roomToSubscribe.subscribe("event", ({ event }) => {
    if (event.type === "BRING") {
      useStore.setState({ currentObjectArtboard: event.artboard })
    }
  });
}

export const bringToArtboard = (artboard) => {
  const roomRealtime = client.getRoom(`matchboxxr-editor${useStore.getState().projectID}`)
  roomRealtime.broadcastEvent({ type: "BRING", artboard: artboard })

}

