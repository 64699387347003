import React, { useRef, useEffect, useState, Suspense, useCallback } from "react";
import { TransformControls, Plane, PivotControls, useTexture} from "@react-three/drei";
import { useXR, useXREvent } from "@react-three/xr";
import { useStore } from "../zustand/objects";
import { useThree, useLoader } from "react-three-fiber";
import * as THREE from "three";

import debounce from 'lodash.debounce';

 
import {CurvedPanels, CurvedPanelsTexture} from "./ModelFiles/UI/CurvedPanels" 
import VRControls from "./VRControls";
import {getFigmaFrameFromID, testFigmaFrame} from "../api";
import { MyLocationOutlined } from "@mui/icons-material";

export function FigmaObject({ orbitControls, undoing, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, object, colour, category, pose, image, figmaID, figmaURL, figmaSize, isLocked, matrixState, curved, curveAmount }) {

// WE NEED A FUNCTION IN THE STORE THAT GETS THE LATEST URL FOR A FRAME AND HOLDS IT, 
// SO THAT ALL PEOPLE A SYNCED TO THIS URL AND SO THAT IT CAN BE UPDATED FROM ANY COMPONENT

  const {
    updateObjectSelected,
    selectedObjectID,
    transformMode,
    updateObjectMatrix,
    transformModeChange,
    grouping,
    addToGroupedObjects,
    setIsDraggingModel,
    isDraggingModel
  } = useStore();

  const transformControls = useRef();
  const transformChild = useRef();
  const grabcontrols = useRef();

  async function updatePositionVR() {
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: new THREE.Matrix4().copy(grabcontrols.current.matrixWorld),
      undo: false
    })
  }


const [vrHovered, setVrHovered] = useState(false)
const [vrSelected, setVrSelected] = useState(false)
const [vrDragging, setVrDragging] = useState(false)

const xr = useXR()

const handleVRSelectEnd = () => {
  if (xr.isPresenting) {
       if (vrSelected){
       updatePositionVR().then(() => grabcontrols.current.position.set(
         0,0,0
       )).then(() => grabcontrols.current.rotation.set(
        0,0,0
      )).then(() => setVrSelected(false))
}
    }
}

useXREvent('selectend', () => handleVRSelectEnd())


const handleDrag = useCallback(
  debounce(
    (e) =>
    selectedObjectID === id ? ObjectUpdate(e) : ''
  , 60)
  )

 
  // declare empty position, rotation and scale for .decompose()
  const pos = new THREE.Vector3()
  const rot = new THREE.Quaternion()
  const sca = new THREE.Vector3()

  // get local copy of matrixState
  const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  newMatrix.decompose(pos, rot, sca)
  // create matrix for gizmo that doesn't include scale, which is applied to the group instead
  const [stateGrabbed, setStateGrabbed] = useState(new THREE.Matrix4().copy(matrixState))
  const gizmoMatrix = new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(1, 1, 1))
  // the below stops the object snapping back to old position while moving
  const [gizmoMatrixx, setGizmoMatrixx] = useState(gizmoMatrix)

  


  const [interimMatrix, setInterimMatrix] = useState(matrixState)

  const startDragging = () => {
    setIsDraggingModel(true)
  }

  const stopDragging = () => {
    setIsDraggingModel(false)
  }

  const handleDragging = useCallback(
    debounce(
      (e) =>
      selectedObjectID === id && setInterimMatrix(e)
    ,30))

  const ObjectUpdate = (e) => {
    const pos2 = new THREE.Vector3()
    const rot2 = new THREE.Quaternion()
    const sca2 = new THREE.Vector3() 
    const toDecompose = new THREE.Matrix4().copy(e)
    toDecompose.decompose(pos2, rot2, sca2)
    // send update to zustand
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: e,
      undo: false
    })}


  const missedShape = () => {
    !isLocked && !isDraggingModel && updateObjectSelected('0')
    transformModeChange({
      transformMode: "translate"
    })
  };

  

 
  const passdownFunctionEditor = () => {
    // !isDraggingModel && !isLocked ? !grouping ? updateObjectSelected(id) : addToGroupedObjects(id) : updateObjectSelected('0') && transformModeChange({
    //   transformMode: "translate"
    // })
    if (isDraggingModel === false) {
      !isLocked ? updateObjectSelected(id) : updateObjectSelected('0')  
    }
  }



  if (!objectsAreLoaded) {
    return
  } else {
    return (
      selectedObjectID === id ? !isLocked ? transformMode === "translate" ?
        <group onPointerMissed={(e) => {
          // e.stopPropagation()
          missedShape()
        }}>
          <PivotControls
            depthTest={false}
            lineWidth={5}
            matrix={!isDraggingModel && new THREE.Matrix4().copy(matrixState)}
            active={selectedObjectID === id ? [true, true, true] : [false, false, false]}

            onDragStart={() => startDragging()}
            onDragEnd={() => {
              ObjectUpdate(interimMatrix)
              stopDragging()}}
            onDrag={(e) => handleDragging(e)}

            ref={transformControls}
            visible={selectedObjectID === id ? true : false}
            fixed={true}
             scale={150}
            annotations={false} 
          >
            <group ref={transformChild}
            >
              <Suspense fallback={null}>
              {figmaURL && !curved ? <FigmaImagePlane figmaURL={figmaURL} figmaSize={figmaSize} />
              : <CurvedPanelsTexture colour={colour} clickFunction={passdownFunctionEditor} imageTexture={figmaURL} curveAmount={curveAmount} width={figmaSize.x / 800} height={figmaSize.y / 400}/>
              }
                {/* <Image transparent opacity={1} ref={imageRef} position={[0, imageSize.height / 1600, 0]} scale={[imageSize.width / 800, imageSize.height / 800, 1]} url={image} /> */}
              </Suspense></group>
          </PivotControls></group> :
        <TransformControls
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          // rotation={objectsAreLoaded && new THREE.Euler().setFromRotationMatrix(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showY={selectedObjectID === id ? true : false}
          showX={selectedObjectID === id ? true : false}
          showZ={selectedObjectID === id ? true : false}
          // showX={selectedObjectID === id && (transformMode === "translate" || transformMode === "scale") ? true : (category === "shape") ? selectedObjectID === id && true : false}
          // showZ={selectedObjectID === id && (transformMode === "translate" || transformMode === "scale") ? true : false}
          translationSnap={0.125}
          // rotationSnap={0.31416}
          scaleSnap={0.025}
          ref={transformControls}
          mode={'scale'}
        >
          <group ref={transformChild} onClick={() => passdownFunctionEditor()}>
            <Suspense fallback={null}>
            {figmaURL && !curved ? <FigmaImagePlane figmaURL={figmaURL} figmaSize={figmaSize} />
              : <CurvedPanelsTexture colour={colour} clickFunction={passdownFunctionEditor} imageTexture={figmaURL} curveAmount={curveAmount} width={figmaSize.x / 800} height={figmaSize.y / 400}/>
              }
            </Suspense>
          </group>
        </TransformControls>

        :
        <TransformControls
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromRotationMatrix(matrixState)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showY={false}
          showX={false}
          showZ={false}
          translationSnap={0.125}
          // rotationSnap={0.31416}
          scaleSnap={0.025}
          ref={transformControls}
          mode={transformMode}
        >
          <Suspense fallback={null}>
            <group onClick={() => passdownFunctionEditor()}>
            {figmaURL && !curved ? <FigmaImagePlane figmaURL={figmaURL} figmaSize={figmaSize} />
              : <CurvedPanelsTexture colour={colour} clickFunction={passdownFunctionEditor} imageTexture={figmaURL} curveAmount={curveAmount} width={figmaSize.x / 800} height={figmaSize.y / 400}/>
              }
            </group>
          </Suspense>
        </TransformControls>
        :
        <VRControls
          isLocked={isLocked}
          idprop={id}
          currentObjectArtboard={currentObjectArtboard}
          objectsAreLoaded={objectsAreLoaded}
          matrixState={new THREE.Matrix4().copy(matrixState)}
          rot={rot}
          scale={sca}
        >
          <Suspense fallback={null}>
            <group onClick={() => passdownFunctionEditor()}>
                  {figmaURL && !curved ? <FigmaImagePlane figmaURL={figmaURL} figmaSize={figmaSize} />
              : <CurvedPanelsTexture colour={colour} clickFunction={passdownFunctionEditor} imageTexture={figmaURL} curveAmount={curveAmount} width={figmaSize.x / 800} height={figmaSize.y / 400}/>
              }
            </group>
          </Suspense>
        </VRControls>
    );
  }
};


export function GroupedFigmaObject({ orbitControls, undoing, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, object, colour, category, pose, image, imageSize, figmaID, isLocked, matrixState, curved, curveAmount }) {

  const { scene } = useThree();
  const modelRef = useRef()

  const [figmaURL, setFigmaURL] = useState()
  const [figmaSize, setfigmaSize] = useState()

  useEffect(() => {
    Promise.resolve(scene.getObjectByName(id).remove(modelRef.current))
      .then(Promise.resolve(modelRef.current.applyMatrix4(matrixState)))
      .then(Promise.resolve(scene.getObjectByName(id).attach(modelRef.current)))
      getFigmaFrameFromID(figmaID).then(e => configureFigmaFrame(e.URL, e.size))
  }, []);
 
  const configureFigmaFrame = (url, size) => {
    setFigmaURL(url)
    setfigmaSize(size) }

  const texture = useTexture(figmaURL)

  const imageRef = useRef()
  if (!objectsAreLoaded) {
    return
  } else {
    return (
      <group
        ref={modelRef}
      // scale={scale}
      >
        <Suspense fallback={null}>
          <group>
          {figmaURL && !curved ? <Plane ref={imageRef} position={[0,figmaSize.y * 2, 0]} smoothness={4} scale={figmaSize && [figmaSize.x / 400, figmaSize.y / 400, 1]}>
                      <meshPhongMaterial
                        receiveShadow
                        attach="material"
                        opacity={1} transparent
                        map={figmaURL && texture}
                      />
                    </Plane>
              : <CurvedPanelsTexture colour={colour} imageTexture={figmaURL} curveAmount={curveAmount} width={figmaSize.x / 800} height={figmaSize.y / 400}/>
              }
          
          </group>
        </Suspense>
      </group>
    );
  }
};


//PREVIEW VERSION
export const PreviewFigmaObject = ({ position, rotation, scale, id, destination, colour, pose, figmaID, figmaURL, figmaSize, matrixState, curved, curveAmount }) => {

  const { shapeHoverChange, updateArtboardAndPreviewCamera, removeObject, currentObjectArtboard } = useStore();

  const passdownFunctionPreview = () => {
    destination && updateArtboardAndPreviewCamera(destination)
    destination && shapeHoverChange(false);

  }

  const hoverShape = (e) => {
    shapeHoverChange(e)
  }


 


  return ( 
    <>
      <Suspense fallback={null}>
        <group
          // scale={scale}
          scale={new THREE.Vector3().setFromMatrixScale(matrixState)}
          position={new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={new THREE.Euler().setFromRotationMatrix(matrixState)}
          onPointerOver={() => destination && hoverShape(true)}
          onPointerOut={() => destination && hoverShape(false)} destination={destination} onClick={passdownFunctionPreview} colour={colour} pose={pose}>
            {figmaURL && !curved ? <FigmaImagePlane figmaID={figmaID} figmaURL={figmaURL} figmaSize={figmaSize} />
              : <CurvedPanelsTexture figmaID={figmaID} figmaURL={figmaURL} colour={colour} imageTexture={figmaURL} curveAmount={curveAmount} width={figmaSize.x / 800} height={figmaSize.y / 400}/>
              }
        </group>
      </Suspense>

    </>
  ); 
};


// THE PLANE IN A NESTED OBJECT TO ALLOW USETEXTURE TO WORK

const FigmaImagePlane = ({figmaURL, figmaSize, figmaID}) => {

  const [ready, setReady] = useState(true)

//   var request = new XMLHttpRequest();  
// request.open('GET', figmaURL, true);
// request.onreadystatechange = function(){
   
//     if (request.readyState === 4){
//         if (request.status === 404) {  
//             console.log("404")
//         }  
//     }
//     else if (request.status === 200){
//       setReady(true)
//     }
// };

// useEffect(() => {
  
// request.send();
// },[])

 

  const {objectsAreLoaded} = useStore()

  const imageRef = useRef()
 
  
  const texture = useTexture(figmaURL)


 return (
  <Suspense fallback={null}>
  {texture && <Plane  ref={imageRef} position={[0, figmaSize.y / 800, 0]} smoothness={4} scale={[figmaSize.x / 400, figmaSize.y / 400, 1]}>
                     {ready && <meshPhongMaterial
                        receiveShadow
                        attach="material"
                        opacity={1} transparent
                        map={texture}
                        side={THREE.DoubleSide}
                      />} 
                    </Plane>
                    }
                    </Suspense>)
}


