import React, { useState, Suspense, useEffect } from "react";
import { Typography, Box, FormControl, RadioGroup } from "@mui/material/";
import { useStore } from "../zustand/objects";
import DeleteIcon from "@mui/icons-material/Delete"
import Grid from "@mui/material/Grid";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Modal from '@mui/material/Modal';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Tooltip from '@mui/material/Tooltip';

// MANTINE
import { Card, Button,  Switch, Text, Group, Slider, ActionIcon, Loader, Textarea } from '@mantine/core';


import ModelAttributions from "../ModelAttributions.json";
import { getSinglePose } from "../api.js"

import ObjectTitleName from './ObjectTitleName';
import ModelColourButton from './ModelColourButton';
import TextObjectBackgroundColourButton from './TextObjectBackgroundColourButton';
import LinksToButton from './LinksToButton';
import PosesButton from './PosesButton';
import ChangeScaleLayerListButtons from './ChangeScaleLayerListButtons';
import { ConstructionOutlined } from "@mui/icons-material";

const LayerListItem = ({
  id,
  object,
  category,
  position,
  scale,
  rotation,
  destination,
  content,
  textKind,
  colour,
  bGColour,
  pose,
  title,
  texture,
  isLocked, 
  matrix,
  figmaID,
  image,
  imageSize,
  curved,
  curveAmount,
  uploadedURL
}) => {
  const {
    artboards,
    updateIsLocked,
    removeObject,
    currentObjectArtboard,
    objectsAreLoaded,
    updateObjectSelected,
    transformModeChange,
    selectedObjectID,
    updateTextContent,
    transformMode,
    switchImageModalOpen, 
    setImagePurpose,
    setEditingID,
    updateImageTexture,
    addToGroupedObjects,
    removeFromGroupedObjects,
    groupedObjects,
    grouping,
    copyObject,
    updateFigmaURL,
    textModalOpen,
    switchTextModalOpen
  } = useStore();

  

  const [textValue, setTextValue] = useState(content)
  const [textOpen, setTextOpen] = useState(false);
  const [attrOpen, setAttrOpen] = useState(false);
  const openListItem = () => {
    updateObjectSelected(id);
  };

  const closeListItem = () => {
    updateObjectSelected('0');
  };
  

  const onEditTextClick = () => {
    setTextOpen(true)
    switchTextModalOpen(true)
  }

  const onAttrClick = () => {
    setAttrOpen(true)
  }

  const onTextSubmit = () => {
    updateTextContent({ id, currentObjectArtboard, content: textValue, undo: false })
    setTextOpen(false)
    switchTextModalOpen(false)
  }

  const onTextCancel = () => {
    setTextOpen(false)
  }

  const onAttrCancel = () => {
    setAttrOpen(false)
  }

  const handleChange = (e) => {
    setTextValue(e.target.value)
  }

  const onAddImageTextureClick = () => {
    setEditingID(id)
    switchImageModalOpen()
    setImagePurpose("texture")
}

  const onEditImageURLClick = () => {
    setEditingID(id)
    switchImageModalOpen()
    setImagePurpose("objectEdit")
}

const onDeleteImageTextureClick = () => {
  updateImageTexture(id, currentObjectArtboard, null, false)
}

const onLockItemClick = () => {
  updateIsLocked({id: id, undo: false})
  !isLocked ? updateObjectSelected('0') : 
  updateObjectSelected(id)
}
 
const onCopyItemClick = () => {
  copyObject({currentObjectArtboard: currentObjectArtboard, id: id, undo: false})
}

const checkboxClick = (e) => {
  e.currentTarget.checked ? addToGroupedObjects(id) : removeFromGroupedObjects(id) 
  console.log(groupedObjects)
}

const updateFrameURL = () => {
  updateFigmaURL({id: id, currentObjectArtboard: currentObjectArtboard, figmaID: figmaID, undo: false})
}


  if (!objectsAreLoaded) {
    return <div><Loader color="black" />Loading...</div>;
  } else {
    return (
      <Suspense fallback={null}> 
      <>

        {/* CC ATTRIBUTION Modal */}
        {id !== "camera" && category !== "aiModel" && category !== "shape" && category !== "text" && category !== "image" && category !== "UI" && category !== "figma" && category !== "uploaded" && category !== "apple" && category !== "microsoft" && category!== "floatgridsUi"  && category!== "floatgridsLayout" &&  <AttributionModal attrOpen={attrOpen} onAttrCancel={onAttrCancel} id={id} category={category} object={object} pose={pose} ModelAttributions={ModelAttributions} />}
        {/* TEXT MODAL */}
        <Modal
          open={textOpen}
          onClose={onTextCancel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
         <Card style={{borderRadius:32, width: 500, padding:0,
        height: 180,  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
          <Grid container style={{padding:20}}>
          <Grid item xs={12}>
            <Text size="xl" fw={700}>
              Edit text
            </Text>
            </Grid>
            <Grid item xs={12}>
            <Textarea
              value={textValue}
              onChange={handleChange}
            />
            </Grid>
            <Grid item xs={12}>
            <Group>
            <Button style={{
             paddingTop: 1, paddingBottom: 1, marginTop: 15, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
          }} onClick={() => onTextCancel()}>Cancel</Button>
            <Button style={{
             paddingTop: 1, paddingBottom: 1, marginTop: 15, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
          }} onClick={() => onTextSubmit()}>Submit</Button>
            </Group>
            </Grid>
            </Grid>
          </Card>
        </Modal>


          
        <Grid container spacing={1}>
          {isLocked ? 
                <Button  style={{
                  paddingTop: 1, paddingBottom: 1, marginTop: 5, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
               }} onClick={() => onLockItemClick()} leftSection={<LockIcon fontSize="24" />}>Unlock</Button> :
                  <>
                    <Grid container spacing={2} style={{
                      cursor: "pointer"
                    }}>
                    </Grid>
                    <div style={styles.transformType}>
                      
                      {id === "camera" ? ('') : (<>
                        <Grid item xs={12}>
                          <Group>
                        <ObjectTitleName open={true} id={id} title={title} />
                        {category === "text" ? (<Button
                      style={{
                        height: 28, width: 82, inline: true, marginTop: 5, fontSize: 12, backgroundColor: 'black',
                        borderTopLeftRadius: 32, borderBottomLeftRadius: 32, borderTopRightRadius: 32, borderBottomRightRadius: 32
                      }}  
                        onClick={() => onEditTextClick()}
                      >
                        Edit text
                      </Button>) :
                        ('')}
                        <RadioGroup style={styles.transformTypeRadio} value={transformMode} onChange={(e) => {
                          transformModeChange({
                            transformMode: e.target.value
                          })
                          updateObjectSelected(id);
                        }}>
                          <ChangeScaleLayerListButtons matrix={matrix} id={id} currentObjectArtboard={currentObjectArtboard}/>

                        </RadioGroup>
                        </Group>
                        </Grid></>)}
                        

                    </div>
                    {/* {ModelPoses[object] && */}
                      <PosesButton pose={pose} id={id} object={object} />
                    {/* } */}

                    {
                      category === "image" && 
                      <CurveToggle curved={curved} curveAmount={curveAmount} id={id}/>
                      }
                      {category === "figma" && 
                      <CurveToggle curved={curved} curveAmount={curveAmount} id={id}/>
                    }
                      {category === "apple" && 
                      <CurveToggle curved={curved} curveAmount={curveAmount} id={id}/>
                    }
                      {category === "background" && 
                      <CurveToggle curved={curved} curveAmount={curveAmount} id={id}/>
                    }
                      {category === "microsoft" && 
                      <CurveToggle curved={curved} curveAmount={curveAmount} id={id}/>
                    }
                      {category === "floatgridsUi" && 
                      <CurveToggle curved={curved} curveAmount={curveAmount} id={id}/>
                    }
                      {category === "floatgridsLayout" && 
                      <CurveToggle curved={curved} curveAmount={curveAmount} id={id}/>
                    }
                    {id === "camera" ? ''
                      : (<>
                        <div>
                          <FormControl fullWidth>
                            <Grid container spacing={0}>

                              {category === "figma" &&
                                <Grid item xs={7}>
                                  <Button onClick={() => updateFrameURL()}
                                    style={{
                                      height: 28, paddingTop: 1, paddingBottom: 1, inline: true, marginTop: 15, fontSize: 12, backgroundColor: 'black',
                                      borderTopLeftRadius: 32, borderBottomLeftRadius: 32, borderTopRightRadius: texture ? 0 : 32, borderBottomRightRadius: texture ? 0 : 32
                                    }}>
                                    Update</Button>
                                </Grid>}


                              {/* TEXTURE BUTTON */}
                              {category === "shape" && (
                                <Grid item xs={8}>
                                  <Group wrap="nowrap" gap={0}>
                                    <Button onClick={() => onAddImageTextureClick()}
                                      style={{
                                        height: 28, width: texture ? 80 : 100, inline: true, marginTop: 15, fontSize: 12, backgroundColor: 'black',
                                        borderTopLeftRadius: 32, borderBottomLeftRadius: 32, borderTopRightRadius: texture ? 0 : 32, borderBottomRightRadius: texture ? 0 : 32
                                      }}>
                                      Texture</Button>
                                    {texture && ( <ActionIcon
                                      onClick={() => onDeleteImageTextureClick()}
                                      variant="filled"
                                      color='black'
                                      size={'xs'}
                                      style={{marginLeft: 2, height: 28, inline: true, marginTop: 15, fontSize: 12, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 32, borderBottomRightRadius: 32}}
                                    >
                                      <DeleteIcon style={{width:15}} size={'xs'} />
                                    </ActionIcon>)}
                                    </Group>
                                  </Grid>)} 
                            
                              {/* TEXTURE BUTTON FOR SCENERY */}
                              {/* {category === "scenery" && (
                                <Grid item xs={7}>
                                  <Group wrap="nowrap" gap={0}>
                                    <Button onClick={() => onAddImageTextureClick()}
                                      style={{
                                        height: 28, width: texture ? 80 : 100, inline: true, marginTop: 8, fontSize: 12, backgroundColor: 'black',
                                        borderTopLeftRadius: 32, borderBottomLeftRadius: 32, borderTopRightRadius: texture ? 0 : 32, borderBottomRightRadius: texture ? 0 : 32
                                      }}>
                                      Texture</Button>
                                    {texture && ( <ActionIcon
                                      onClick={() => onDeleteImageTextureClick()}
                                      variant="filled"
                                      color='black'
                                      size={'xs'}
                                      style={{marginLeft: 2, height: 28, inline: true, marginTop: 8, fontSize: 12, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 32, borderBottomRightRadius: 32}}
                                    >
                                      <DeleteIcon style={{width:15}} size={'xs'} />
                                    </ActionIcon>)}
                                    </Group>
                                  </Grid>)}  */}

                                    {/* TEXTURE BUTTON FOR UI */}
                                {/* {category === "UI" && (
                                <Grid item xs={7}>
                                  <Group wrap="nowrap" gap={0}>
                                  <Button onClick={() => onAddImageTextureClick()} 
                                  style={{ width:texture ? 80 : 100, height: 28,  inline: true, marginTop: 8, fontSize: 12, backgroundColor:'black', 
                                  borderTopLeftRadius: 32, borderBottomLeftRadius: 32, borderTopRightRadius: texture ? 0 : 32, borderBottomRightRadius: texture ? 0 : 32 }}>
                                    Texture</Button>
                                    {texture && ( <ActionIcon
                                      onClick={() => onDeleteImageTextureClick()}
                                      variant="filled"
                                      color='black'
                                      size={'xs'}
                                      style={{marginLeft: 2, height: 28, inline: true, marginTop: 8, fontSize: 12, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 32, borderBottomRightRadius: 32}}
                                    >
                                      <DeleteIcon style={{width:15}} size={'xs'} />
                                    </ActionIcon>)}
                                    </Group> </Grid>)}  */}



                                  {/* EDIT IMAGE */}
                                  {(category === "image") && (
                                    <Grid item xs={7}>
                                      <Button onClick={() => onEditImageURLClick()} 
                                      style={{ height: 28, paddingTop: 1, paddingBottom: 1, inline: true, marginTop: 15, fontSize: 12, backgroundColor:'black', 
                                      borderRadius: 32}}
                                      >
                                        Edit image</Button>
                                    </Grid>
                                  )}  



                                  {/* IMAGE ATTRIBUTION */}
                                  {category !== "ai" && category !== "shape" && category !== "text" && category !== "image" && category !== "figma" && category !== "apple" && category !== "microsoft" && category !== "UI" && category !== "floatgridsUi" && category !== "floatgridsLayout" && category !== "uploaded" &&
                                    <Grid item xs={8}><Button
                                    style={{ height: 28, paddingTop: 1, paddingBottom: 1, inline: true, marginTop: 15, fontSize: 12, backgroundColor:'black', 
                                    borderTopLeftRadius: 32, borderBottomLeftRadius: 32, borderTopRightRadius: 32, borderBottomRightRadius: 32 }}
                                      onClick={() => onAttrClick()}
                                    >
                                      View creator
                                    </Button>
                                    </Grid>}

                                {category === "text" && <Grid item xs={7}><TextObjectBackgroundColourButton bGColour={bGColour} id={id} /></Grid> }


                              {/* MODEL COLOR */}
                              {category !== "ai" && id !== "camera" && id !== "image" && category !== "figma" && category !== "apple" && category !== "microsoft" && category !== "floatgridsUi" && category !== "floatgridsLayout" && category !== "uploaded" && (
                                <Grid item xs={2}><ModelColourButton colour={colour} id={id} /></Grid>
                              )}
                              {category === "scenery" && 
                              <Grid item xs={10}></Grid>}
                                  
                                  


                                {/* Links to */}
                              <Grid item xs={6}>
                                <LinksToButton id={id} destination={destination} />
                              </Grid>
                              


                                {category !== "figma" && <Grid item xs={2}>
                                    <div style={{ cursor: 'pointer', padding: 5, marginTop: 15 }}><Tooltip title="Copy object">
                                      <ContentCopyIcon onClick={() => onCopyItemClick()} />
                                    </Tooltip></div>
                                  </Grid>}

                                  <Grid item xs={2}>
                                    <div style={{ cursor: 'pointer', padding: 5, marginTop: 15 }}><Tooltip title="Lock controls">
                                      <LockOpenIcon onClick={() => onLockItemClick()} />
                                </Tooltip></div>
                              </Grid>
                                  <Grid item xs={2}>
                                    <div style={{ cursor: 'pointer', padding: 5, marginTop: 15 }}><Tooltip title="Lock controls">
                                      <DeleteIcon onClick={() => removeObject({ currentObjectArtboard: currentObjectArtboard, id:id, undo: false, content:content, textKind:textKind, object:object, category:category, position:position, scale:scale, rotation:rotation, matrix:matrix, colour:colour, image:image, imageSize:imageSize, imageTexture: texture, curved: curved, curveAmount: curveAmount, pose: pose, uploadedURL: uploadedURL })}
                                  />
                                </Tooltip></div>
                              </Grid>

                             
                                </Grid>
                          </FormControl>
                        </div>
                      </>)}
                  </>}
        </Grid>
      </> 
      </Suspense>
    );
  }
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "5px",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
    padding: "2.5%",
    width: "100%",
    height: "auto"
  },
  containerSelected: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "5px",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
    padding: "2.5%",
    width: "100%",
    height: "auto",
    backgroundColor: "lightGrey"
  },
  transformType: {
    flexDirection: "column"
  },
  transformTypeRadio: {
    display: "flex",
    flexDirection: "column",
  },
  radioItem: {
    display: "flex",
    flexDirection: "row"
  },
  position: {
    display: "flex"
  },
  positionVector: {
    flex: 1,
    display: "flex"
  },
  inputField: {
    width: "100%"
  },
  dropdownContainer: {

  }
};

export default LayerListItem;


const CurveToggle = ({curved, id, curveAmount}) => {

  const {updateCurved, updateCurveAmount} = useStore()
 
  const handleChange = () => {
    updateCurved({id:id, undo: false})
  }
  const handleChangeCurveAmount = (e) => {
    updateCurveAmount({id:id, val:e, undo: false})
  }



  return (
    <div style={{width:"100%", paddingTop:8, paddingBottom:8}}>
    <Switch
      color="black"
      fw={500} size={'xs'}
      label="Curve"
      onChange={() => handleChange()} type="checkbox" name="curve" checked={curved}
    />
    {curved && <Slider
        color="black"
        defaultValue={curveAmount}
        onChange={(e) => handleChangeCurveAmount(e)}
        step={1}
        marks={[{ value: 0 },{ value: 3 },{ value: 6 },{ value: 9},{ value: 12 }]}
        min={1}
        max={12}
      />}
      </div>


  )
}


const AttributionModal = ({attrOpen, onAttrCancel, id, category, object, pose, ModelAttributions}) => {
  
const [attributionInfo, setAttributionInfo] = useState(null)

  useEffect(() => {
    attrOpen && getSinglePose(pose.name).then(e => setAttributionInfo(e.attribution))
  },[attrOpen])

  return (
    <Modal
    open={attrOpen}
    onClose={onAttrCancel}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Card style={{width:400, borderRadius:32, padding:0,top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
          <div style={{ width:'100%', height:"100%", padding:20, paddingTop:10, margin:0, backgroundImage: 'url("/image/background-grid.png")' }}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Model Attribution
      </Typography>
      {attributionInfo ? 
      <Suspense key={id} fallback={null}>
        <Typography>
        {attributionInfo}
      </Typography>
      </Suspense> :
      <Loader color="black"/>}
      

      <Button style={{
             paddingTop: 1, paddingBottom: 1, marginTop: 15, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
          }} onClick={() => onAttrCancel()}>Close</Button>
          </div>
    </Card>
  </Modal>
  )
}