import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import Input from '@mui/material/Input';

import FormControl from '@mui/material/FormControl';
import { useStore } from "../zustand/objects";
import { Button } from '@mantine/core';


const ObjectTitleName = ({open, title, id, isLocked}) => {

  const [editing, setEditing] = useState(false)
  const { updateObjectTitle, currentObjectArtboard } = useStore();

  const handleClick = () => {
    setEditing(true)
  }

  const handleSubmit = (val) => {
    updateObjectTitle(id, currentObjectArtboard, val, false)
    setEditing(false)
  }

  
  

  return (
    
      <>
 
      {editing ?
        <FormControl onBlur={(e) => handleSubmit(e.target.value)}>
          <Input  id="outlined-basic" defaultValue={title} autoFocus variant="outlined" onKeyPress={(e) => { if (e.key === 'Enter') { handleSubmit(e.target.value) } }} />
        </FormControl>
        :
        <><Button 
        style={{
          height: 28, width: 82, inline: true, marginTop: 5, fontSize: 12, backgroundColor: 'black',
          borderTopLeftRadius: 32, borderBottomLeftRadius: 32, borderTopRightRadius: 32, borderBottomRightRadius: 32
        }} 
       onClick={() => !isLocked && handleClick()} size={"small"} >
          Rename
          {/* {title} */}
        </Button>
        </>}  
        </>
 
  );
};


export default ObjectTitleName;
