import React, { useState, useEffect, Suspense } from 'react';
import useDrivePicker from 'react-google-drive-picker'
import Toolbar from '@mui/material/Toolbar';
import SwitchToPreviewButton from "./SwitchToPreviewButton";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ProjectName from "./ProjectName";
import SharePreviewButton from "./SharePreviewButton";
import MenuButton from "./MenuButton";
import StagedAIModel from "./StagedAIModel";
import IosShareIcon from '@mui/icons-material/IosShare';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { Grid, Typography } from "@mui/material/";
import { useDisclosure } from '@mantine/hooks';
import RedoButton from "./RedoButton";
import { MiroShareButton, GoogleShareButton } from "./ShareModal";
import UndoButton from "./UndoButton";
import AddModelButton from "./AddModelButton";
import {FloorColourButton, ImageButton} from "./FloorColourButton";
import StoryboardLinkButton from "./StoryboardLinkButton";
import { useStore } from "../zustand/objects";
import { useAccountStore } from "../zustand/account"; 
import { UploadModal } from './UploadModal';
import { AIModal } from './AIModal';
import ImageModal from "./ImageModal";
import { TemplateDropdown } from './TemplateDropdown';
import {SubscribeReminderBar} from "../components/AppNavBar";

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ImageIcon from '@mui/icons-material/Image';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import TitleIcon from '@mui/icons-material/Title';
import { getMiroBoard, sendStoryboardToMiro, getUserMiroFrames, getUserGoogleSlidesThumbAndLength, exportToGSlides, saveUserScreenshotsAPI, getProjectGuestsAPI, getUserHasAI, deleteUserMiroEntry, deleteUserGoogleEntry, getGoogleSyncEntry, getMiroSyncEntry, getGooglePickerCreds } from "../api";

import { useForm } from '@mantine/form';

import { Tooltip, TextInput, Spoiler, Select, Card, HoverCard, Button, Tabs, Text, Group, Loader, Modal, ActionIcon, LoadingOverlay } from '@mantine/core';



const CaptureScreenshots = () => {

  const {
    setScreenshotStatus
  } = useStore();

  useState(() => {
    setScreenshotStatus(true)

  }, [])

  return ''

}


export default function TopProjectAppBar({ mode, projectKey }) {

  const [hasAI, setHasAI] = useState(false)
  const [uploadOpen, setUploadOpen] = useState(false);
  const [aiOpen, setAiOpen] = useState(false);
  

  useEffect(() => {
    getUserHasAI().then(res => setHasAI(res))
  }, [])

  const setUpload = () => {
    setUploadOpen(true)
  }
  const closeUpload = () => {
    setUploadOpen(false)
  }

  const setAi = () => {
    setAiOpen(true)
  }
  const closeAi = () => {
    setAiOpen(false)
  }

  const {helpOpen, setHelpOpen} = useAccountStore();

  const [helpHover, setHelpHover] = useState(false)


  return ( 
  <>
    {/* <SubscribeReminderBar/> */}
    <Toolbar color="transparent" style={{ height: 40, minHeight: 40, zIndex: 500, display: "flex", justifyContent: "space-between", background: 'transparent', boxShadow: 'none' }} >


      {/* <Stack direction={"row"} spacing={3}> */}

        {mode === "editor" ?
          <>
          {/* <Card
            shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)"
            style={{ marginLeft: 10, marginTop: 10, paddingLeft: 10, paddingRight: 10, padding: 5, backgroundColor: "white", borderRadius: 16, display: "flex", position: "relative" }}> */}
            <Group gap={10}>
              <MenuButton />
              <ProjectName />
              <Tooltip label="Basic instructions">
                <ActionIcon
                  size={"xs"}
                  variant='transparent'
                  color={!helpHover ? "#424242" : "#868e96"}
                  onPointerOver={() => setHelpHover(true)}
                  onPointerLeave={() => setHelpHover(false)}
                  onClick={() => setHelpOpen(!helpOpen)}>
                  <HelpOutlineIcon fontSize="small" />
                </ActionIcon>
                </Tooltip>
              <Group gap={0}>
                <UndoButton />
                <RedoButton />
                </Group>
            </Group>
          {/* </Card> */}
            {/* <Card
              shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)"
              style={{ marginLeft: 10, marginTop: 10, paddingLeft: 10, paddingRight: 10, padding: 5, backgroundColor: "white", borderRadius: 16, display: "flex", position: "relative" }}> */}
              <Group gap={10}>
                <GenerateObject setAi={setAi} />
                <FigmaObject/>
                <UploadObject setUpload={setUpload} />
                <AddTextObject/>
                <ImageButton />
                <AddModelButton />
                <TemplateDropdown/>
                {/* <FloorColourButton /> */}
              </Group>
            {/* </Card> */}
            </>
          // </Box>
          : ''}      

      <UsersModal projectKey={projectKey} />
      <AddAIModal projectKey={projectKey} />
      <UploadModal close={closeUpload} open={uploadOpen} />
      <AIModal close={closeAi} open={aiOpen} />
      <ImageModal />



      {/* <Card
        shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)"
        style={{ marginLeft: 10, marginTop: 10, paddingLeft: 15, paddingRight: 15, padding: 5, backgroundColor: "white", borderRadius: 16, display: "flex", position: "relative" }}> */}
        <Group gap={1}>
          <ExportToAPIButton projectKey={projectKey} />
          <SharePreviewButton projectKey={projectKey} />
          <StoryboardLinkButton projectKey={projectKey} />
          <SwitchToPreviewButton mode={mode} projectKey={projectKey} />
        </Group>
      {/* </Card> */}



    </Toolbar>
    </>
  );
}


const UsersModal = ({ projectKey }) => {

  const {
    switchShareModalOpen,
    shareModalOpen,
    projectID
  } = useStore();



  const {
    switchUserAccessModalOpen,
    userAccessModalOpen
  } = useStore();

  const onUserModalCancel = () => {
    switchUserAccessModalOpen()
  }

  const [guests, setGuests] = useState([])

  const getGuests = () => {
    getProjectGuestsAPI(projectKey).then(res =>
      setGuests(res.projectGuests)
    )

  }

  useEffect(() => {
    getGuests()
  }, [userAccessModalOpen])




  return (
    <Modal
      opened={userAccessModalOpen}
      // onClose={onUserModalCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" >Set user access</Typography>

          </Grid>
          <Grid item xs={12}>
            <form style={{ marginTop: 0 }}>
              <input type="checkbox" />
              <label style={{ marginLeft: 10 }}>
                Logged-out preview access?
              </label>
            </form>
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Project guests</Typography>
            <form style={{ marginTop: 5 }}>
              <input placeholder="Enter user email address" type="text" />
              <button>Submit</button>
            </form>
          </Grid>
          <Grid item xs={12}>
            {userAccessModalOpen && guests.length > 0 ?
              guests.map((mapped, i) =>
                <Typography key={i} style={{ marginTop: 5 }} variant="body1">{mapped.email} – {mapped.access} access</Typography>)
              :
              <Typography style={{ marginTop: 5 }} variant="body1">No guest editors.</Typography>}
          </Grid>

        </Grid>
      </Box>
    </Modal>
  );
}



const AddAIModal = ({ projectKey }) => {

  const {
    switchAddAIModalOpen,
    addAIModalOpen,
    getAIModels,
    addObject,
    sendAIPrompt,
    currentObjectArtboard,
    aiModels,
    removeAIModel
  } = useStore();

  let array = []

  const [sending, setSending] = useState(false)

  const onAIModalCancel = () => {
    switchAddAIModalOpen()
  }

  async function switchSending() {
    setSending(!sending)
  }


  const handleClick = (e) => {
    e.preventDefault()
    setSending(true)
    sendAIPrompt(e.target[0].value, projectKey)
      .then(() => {
        setSending(false)
        getAIModels()
      })
  }

  const handleSelect = (e) => {
    addObject({
      currentObjectArtboard,
      category: 'aiModel',
      object: e.prompt,
      undo: false,
      id: null,
      position: null,
      scale: null,
      rotation: null,
      matrix: null,
      pose: null,
      imageTexture: null,
      aiKey: e.id,
      imageTexture: null,
      image: null,
      curved: false,
      curveAmount: 3,
      bGColour: null
    })
  }


  const handleDeleteClick = (e) => {
    removeAIModel(e)
  }


  return (
    <Modal
      opened={addAIModalOpen}
      // onClose={onAIModalCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        overflow: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Generate a 3D object using AI (beta)</Typography>

          </Grid>

          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">Enter a prompt to generate a 3D object for your project.</Typography>
            <form style={{ marginTop: 5 }} onSubmit={(e) => handleClick(e)}>
              <input placeholder="Enter prompt for AI" type="text" />
              <button disabled={sending} type="submit">{sending ? "sending" : "Generate"}</button>
            </form>
          </Grid>
          {aiModels && aiModels.map((mapped, i) =>
            <Grid key={i} item xs={12} md={6}>
              <div sx={{ height: 6, width: "auto" }}><StagedAIModel prompt={mapped.prompt} /></div>
              <button onClick={() => handleSelect(mapped)}
              >Add {mapped.prompt}</button>
              <button onClick={() => handleDeleteClick(mapped.id)}
              >Delete</button>
            </Grid>)}

        </Grid>
      </Box>
    </Modal>
  );
}

const UploadObject = ({ setUpload }) => {

  const {
    setImagePurpose
  } = useStore();

  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(50);
  const [hover, setHover] = useState(false)

  useEffect(() => {
    show ? setDivWidth(150) : setDivWidth(50)
  })

  const handleClose = () => {
    setShow(false);
    setShowText(false);
  };

  const handleClick = () => {
    setUpload()
    setImagePurpose("object")
  }

  return (
    <Tooltip label="Uploads">
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: "100%",
        justifyContent: 'flex-start',
        margin: "2.5px",
        paddingBottom: 0.5
      }}
    >

      <ActionIcon
        size={"xs"}
        variant='transparent'
        color={!hover ? "#424242" : "#868e96"}
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
        onClick={() => handleClick()}>
        <FileUploadOutlinedIcon fontSize="small" />
      </ActionIcon>
    </Box>
    </Tooltip>
  )
}




const FigmaObject = () => {

  const {
    setImagePurpose,
    switchImageModalOpen
  } = useStore();

  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(50);
  const [hover, setHover] = useState(false)

  useEffect(() => {
    show ? setDivWidth(150) : setDivWidth(50)
  })

  const handleClick = () => {
    setImagePurpose("figma")
    switchImageModalOpen()
  }

  return (
    <Tooltip label="Embed a Figma frame">
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: "100%",
        justifyContent: 'flex-start',
        margin: "2.5px",
        paddingBottom: 0.5
      }}
    >

      <ActionIcon
        size={"xs"}
        variant='transparent'
        color={!hover ? "#424242" : "#868e96"}
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
        onClick={() => handleClick()}>
        <DesignServicesIcon fontSize="small" />
      </ActionIcon>
    </Box>
    </Tooltip>
  )
}




const GenerateObject = ({ setAi }) => {

  const {
    switchAddAIModalOpen,
    addAIModalOpen
  } = useStore();

  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(50);
  const [hover, setHover] = useState(false)

  useEffect(() => {
    show ? setDivWidth(150) : setDivWidth(50)
  })

  const handleClose = () => {
    setShow(false);
    setShowText(false);
  };

  return (
    <Tooltip label="Generate model with AI">
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: "100%",
        justifyContent: 'flex-start',
        margin: "2.5px",
        paddingBottom: 0.5
      }}
    >
          
      <ActionIcon
        style={{ marginLeft: 10 }}
        size={"xs"}
        variant='transparent'
        color={!hover ? "#424242" : "#868e96"}
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
        onClick={() => {
          setAi()
        }}>
        <AutoAwesomeIcon fontSize="small" />
      </ActionIcon>
    </Box>
      </Tooltip>
  )
}



const AddTextObject = () => {

  const {
    switchTextModalOpen,
    addAIModalOpen
  } = useStore();

  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(50);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    show ? setDivWidth(150) : setDivWidth(50)
  })

  const handleClose = () => {
    setShow(false);
    setShowText(false);
  };

  const onAddTextClick = () => {
    switchTextModalOpen()
}

  return (
    <Tooltip label="Add text">
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: "100%",
        justifyContent: 'flex-start',
        margin: "2.5px",
        paddingBottom: 0.5
      }}
    >
      <ActionIcon
        size={"xs"}
        variant='transparent'
        color={!hover ? "#424242" : "#868e96"}
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
        onClick={() => {
          onAddTextClick()
        }}>
        <TitleIcon fontSize="small" />
      </ActionIcon>
    </Box>
      </Tooltip>
  )
}


const ExportToAPIButton = ({ projectKey }) => {

  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [hover, setHover] = useState(false)

  const handleModalOpen = () => {
    setExportModalOpen(true)
  }
  const handleModalClose = () => {
    setExportModalOpen(false)
  }
  const [opened, { open, close }] = useDisclosure(false);

  const {setTabValue, tabValue} = useStore()

  const handleClick = () => {
    setTabValue("scenes")
    open()
    // console.log(tabValue)
  }

  return (<>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: "100%",
        justifyContent: 'flex-start',
        margin: "2.5px",
        paddingBottom: 0.5
      }} 
    >
      <ActionIcon
        style={{ marginLeft: 10 }}
        size={"xs"}
        variant='transparent'
        color={!hover ? "#424242" : "#868e96"}
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
        onClick={() => {
          setTabValue("scenes")
          open()
        }}
        // onClick={() => open()}
        // onClick={open}
      >
        <IosShareIcon fontSize="small" />
      </ActionIcon></Box>

   {opened && <ExportModalContent closeFunction={close} opened={opened} projectKey={projectKey} />}

  </>
  )
}










const ExportModalContent = ({ projectKey, closeFunction, opened }) => {


  const {
    screenshots, 
    setScreenshotStatus,
    screenshotStatus
  } = useStore();



  // console.log(opened)

  const screenshotArray = Object.values(screenshots)

  async function saveScreenshots() {
    saveUserScreenshotsAPI({ projectKey: projectKey, screenshots: [...screenshotArray] })
  }

  const [openPicker, authResponse] = useDrivePicker();

  const [haveEntry, setHaveEntry] = useState(false)
  const [haveGoogleEntry, setHaveGoogleEntry] = useState(false)
  const [sending, setSending] = useState(false)
  const [fileSelected, setFileSelected] = useState(false)
  const [miroFileSelected, setMiroFileSelected] = useState(false)
  const [selectedFile, setSelectedFile] = useState({ name: '', thumbnail: '', type: '', id: '', url: '' })
  const [selectedMiroFile, setSelectedMiroFile] = useState({ name: '', thumbnail: '', id: '', url: '', frames: [] })
  const [selectedSlide, setSelectedSlide] = useState(0)
  const [selectedFrame, setSelectedFrame] = useState('None')
  const [miroError, setMiroError] = useState(false)
  const [miroErrorMessage, setMiroErrorMessage] = useState('No error')
  const [loadingAfterGoogleSubmit, setLoadingAfterGoogleSubmit] = useState(false)
  const [loadingAfterMiroSubmit, setLoadingAfterMiroSubmit] = useState(false)

  const screenshotFunction = () => {
    setScreenshotStatus(true)
  }

  

  useState(() => {
    
    screenshotFunction()
    setFileSelected(false)
    getMiroSyncEntry().then((res) => { res === 200 && setHaveEntry(true) })
    getGoogleSyncEntry().then((res) => { res === 200 && setHaveGoogleEntry(true) })
  }, [])

  const deleteEntry = () => {
    deleteUserMiroEntry()
    setHaveEntry(false)
  }
  const deleteGoogleSlidesEntry = () => {
    deleteUserGoogleEntry()
    setHaveGoogleEntry(false)
  }
      
  const handleClose = () => {
    closeFunction()
    setScreenshotStatus(false)
    setFileSelected(false)
  }


  const handleExportClick = (id, slidesLength) => {
    setSending(true)
    saveUserScreenshotsAPI({ projectKey: projectKey, screenshots: [...screenshotArray] })
      .then(() => exportToGSlides({ presentationID: id, index: slidesLength, projectKey: projectKey }))
      .then(() => setScreenshotStatus(false))
      .then(() => setSending(false))

  }

  async function setUpSelectedFile({name, type, id, url, thumbnail, slides}) {
    setSelectedFile({
      name: name,
      type: type,
      id: id,
      url: url,
      thumbnail: thumbnail,
      slides: slides
    })
  }

  const handlePickerSelect = ( type, id, url) => {
    getUserGoogleSlidesThumbAndLength(id)
      .then((res) => {
        setSelectedFile({
          name: res.title, 
          type: type,
          id: id,
          url: url,
          thumbnail: res.imageURL,
          slides:[...Array(res.slidesLength).keys()].map(i => i + 1) 
        })
        setFileSelected(true)
        setLoadingAfterGoogleSubmit(false)
      }
      )
  }


  const formMiro = useForm({
    initialValues: {
      url: ''
    },

    validate: {
      url: (value) => (/^https:\/\/miro\.com\/app\/board\/([^/]+)\/?/.test(value) ? null : 'Invalid URL'),
      // url: (value) => (/^https:\/\/miro\.com\/app\/board\/([^/]+)\//.test(value) ? null : 'Invalid URL'),
    },
  });
 
  const handleMiroSelect = (boardId, url, name, picture) => {
    
    getUserMiroFrames(boardId)
      .then((res) => {
        setSelectedMiroFile({
          name: name,
          id: boardId,
          url: url,
          thumbnail: picture,
          frameData:[{id: "None", name: "None"},...res]
        })
        setMiroFileSelected(true)
        setLoadingAfterMiroSubmit(false)
      })

  }

  function extractMiroId(url) {
    // Regular expression to match the desired part of the URL
    // const regex = /\/board\/([^/]+)\/?/;
    const regex = /\/board\/([^/]+)(=\/)?/;

  
    // Match the URL against the regex
    const match = url.match(regex);
  
    // If a match is found, return the extracted ID
    if (match) {
      return(match[1]);
    } else {
      // Return an error message if no match is found
      return('Invalid URL.');
    }
  }

  

  

  const generateError = () => {
    setMiroErrorMessage("Incomplete export. Was your frame too small for the storyboards?")
    setMiroError(true)
  }

  const handleMiroExportClick = (boardId, frameId) => {
    setSending(true)
    saveUserScreenshotsAPI({projectKey:projectKey, screenshots: [...screenshotArray]})
    .then(() =>sendStoryboardToMiro({boardId: boardId, projectKey: projectKey, frameId: frameId}))
    .then((res) => (res === 201) ? setScreenshotStatus(false) : generateError())
    .then(() =>setScreenshotStatus(false))
    .then(() =>setSending(false))
  }

  const handleReset = () => {
    setMiroError(false)
    setMiroErrorMessage('No error')
    setMiroFileSelected(false)
  }

  const form = useForm({
    initialValues: {
      url: ''
    },

    validate: { 
      // url: (value) => (/^https:\/\/docs\.google\.com\/presentation\/d\/([^/]+)\/edit(?:#[^/]+)?$/.test(value) ? null : 'Invalid URL'),
      url: (value) => (
        /^https:\/\/docs\.google\.com\/presentation\/d\/([^/]+)\/?(?:\/[^/]+)?(?:#.*)?$/.test(value) 
        ? null 
        : 'Invalid URL'
      ),
    },
  });

  
  const selectFunction = (url) => {
    setLoadingAfterMiroSubmit(true)
    const id = extractMiroId(url)
    saveScreenshots().then(() => setScreenshotStatus(false))
    getMiroBoard(id).then(res => handleMiroSelect(id, url, res.name, res.picture))
    formMiro.reset();

  }


  const handleSubmitGoogleURL = (url) => {
    setLoadingAfterGoogleSubmit(true)
    const id = extractId(url)
    saveScreenshots().then(() => setScreenshotStatus(false))
    handlePickerSelect('pres', id, url)
    form.reset();
  }
  
  function extractId(url) {
    // Regular expression to match the desired part of the URL
    const regex = /\/d\/([^/]+)\/edit/;
  
    // Match the URL against the regex
    const match = url.match(regex);
  
    // If a match is found, return the extracted ID
    if (match) {
      return(match[1]);
    } else {
      // Return an error message if no match is found
      return('Invalid URL.');
    }
  }



  const clearMiroFileAndURL = () => {
    setMiroFileSelected(false)
  }
  const clearFileAndURL = () => {
    setFileSelected(false)
  }


  return (
    <Modal
      zIndex={1000}
      yOffset={150}
      radius={32}
      style={{ overflow: 'hidden' }}
      styles={{
        content: { maxHeight: 460, overflow: 'hidden' }
      }}
      withCloseButton={false}
      size='sm'
      opened={opened} 
      onClose={() => handleClose()}
    >
      {/* {opened && <CaptureScreenshots />} */}
      {/* <Card style={{borderRadius:32, overflow:'scroll'}}> */}
      <LoadingOverlay loaderProps={{ color: 'black' }} visible={sending} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      <LoadingOverlay loaderProps={{ color: 'black' }} visible={loadingAfterGoogleSubmit} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      <LoadingOverlay loaderProps={{ color: 'black' }} visible={loadingAfterMiroSubmit} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      
      <Group>
        <Text size="xl" fw={700}>Export your storyboards</Text>
        <Group justify="center">
          <HoverCard width={280} shadow="md" zIndex={1001}>
            <HoverCard.Target>
              <HelpOutlineIcon />
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm" >
                Storyboard images incomplete? Try closing and reopening this modal and exporting again.
              </Text>
            </HoverCard.Dropdown>
          </HoverCard>
        </Group></Group>
      <Tabs defaultValue="miro">
        <Tabs.List>
          <Tabs.Tab value="miro">
            Miro
          </Tabs.Tab>
          <Tabs.Tab value="google-slides" >
            Google Slides
          </Tabs.Tab>
        </Tabs.List>

        {/* MIRO */}
        <Tabs.Panel value="miro">
          <div style={{ height: 380, overflow: 'scroll' }}>
            {haveEntry ? <><Button onClick={() => deleteEntry()}
              style={{
                width: 120, marginTop: 20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
              }}>Unlink Miro</Button>
               {!miroFileSelected && <Spoiler maxHeight={20} showLabel="Show more" hideLabel="Hide" mb={40}>
              <Text style={{ backgroundColor: "transparent" }} size='sm' >
              This is a 3D ideas tool. It is not designed to secure sensitive content.
              Avoid creating, uploading, or sharing sensitive content. <a href="https://www.matchboxxr.com/policies" target="_blank">Read more</a></Text>
              </Spoiler>}
              {miroFileSelected && <Button
                onClick={() => clearMiroFileAndURL()}
                style={{
                  width: 160, marginTop: 20, marginBottom: 20, paddingBottom: 1,marginLeft:5, inline: true, backgroundColor: 'black', borderRadius: 32
                }}>Enter new URL</Button>}
              {/* {miroFileSelected && <Button
                onClick={() => setMiroFileSelected(false)}
                style={{
                  width: 140, marginTop: 20, marginBottom: 20, paddingBottom: 1, marginLeft:5, inline: true, backgroundColor: 'black', borderRadius: 32
                }}>Browse Boards</Button>} */}
              
                 
              {
                miroError ? 
                <Card key={selectedMiroFile.id} withBorder radius="md" p={10} style={{ marginBottom: 20, borderRadius: 16}}
                      shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                    <Stack direction={"column"}>{miroErrorMessage}<Button
                onClick={() => handleReset()}
                style={{
                  width: 100, marginTop: 20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
                }}>Reset</Button></Stack></Card>
                
               : miroFileSelected ?
                <Card key={selectedMiroFile.id} withBorder radius="md" p={0} style={{ marginBottom: 20, borderRadius: 16 }}
                      shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                  <Suspense fallback={<Loader color="black" />}>
                    <Stack direction={"column"}>
                      {/* THUMBNAIL */}
                    <a href={selectedMiroFile.url} target="_blank" style={{cursor: 'pointer', textDecoration:'none',backgroundColor:'none', border:'none'}}>
                      {selectedMiroFile.thumbnail ? <img referrerPolicy="no-referrer" src={selectedMiroFile.thumbnail} style={{ width: 350, height: 196.875, objectFit:'cover', position:'relative' }} /> :
                    <div style={{ width: 350, height: 196.875, backgroundColor: '#F4B400' }} />}</a>
                    <Stack pl={2} pr={2} pt={1} direction={"column"}  >
                      {/* TITLE */}
                    <Text size="xl" fw={700}><span style={{ whiteSpace: 'nowrap', maxWidth:300,textOverflow: 'ellipsis', overflow: 'hidden', paddingLeft:4, paddingRight:4,backgroundColor:"#fac710", position:"absolute", top:150}}>
                      {selectedMiroFile.name}</span></Text>

                    <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                    {/* BOARD SELECTION DROPDOWN */}

                    <div style={{width:175}} >
                      <Select
                        comboboxProps={{ zIndex: 1000 }} 
                        label="Frame selected"
                        placeholder="None"
                        // data={['None',...selectedMiroFile.frames]}
                       
                        data={selectedMiroFile.frameData.map((obj, i) => ({value: obj.id, label: obj.name ? i + ": " + obj.name : i + ": (no title)"}))}
                        value={selectedFrame}
                        onChange={(val) => setSelectedFrame(val)}
                      /></div>

                    {/* SHARE BUTTON */}
                    <Button
                      onClick={() => handleMiroExportClick(selectedMiroFile.id, selectedFrame)}
                      style={{
                        width: 100, marginTop: 20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
                      }}>Export</Button>
                      </Stack>
                      </Stack>
                      </Stack>
                  </Suspense> </Card>:
                   <form onSubmit={formMiro.onSubmit((values) => selectFunction(values.url))}>
                   <TextInput
                     withAsterisk
                     label="Miro URL"
                     placeholder="Enter your board URL"
                     {...formMiro.getInputProps('url')}
                   />
                    <Button style={{
                         width: 100, marginTop: 20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
                       }} type="submit">Submit</Button>
                   </form>   
                //  <><div>Select a board</div>
                // <Suspense fallback={<Loader color="black" />}>
                //   <MiroBoardsMapped selectFunction={selectFunction} selectedMiroFile={selectedMiroFile} projectKey={projectKey} />
                //   </Suspense>
                // </>
              }
            </>

              : <MiroShareButton projectKey={projectKey} />
            }
          </div>
        </Tabs.Panel>

          {/* GOOGLE */}
        <Tabs.Panel value="google-slides">
          <div style={{ height: 460, overflow: 'scroll' }}>
            {haveGoogleEntry ? <><Button
              onClick={() => deleteGoogleSlidesEntry()}
              style={{
                width: 150, marginTop: 20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
              }}>Unlink Google</Button>
             {fileSelected && <Button
                onClick={() => clearFileAndURL()}
                style={{
                  width: 160, marginTop: 20, marginBottom: 20, paddingBottom: 1,marginLeft:5, inline: true, backgroundColor: 'black', borderRadius: 32
                }}>Enter new URL</Button>}
             
              {!fileSelected && <Spoiler maxHeight={20} showLabel="Show more" hideLabel="Hide" mb={40}>
              <Text style={{ backgroundColor: "transparent" }} size='sm' >
              This is a 3D ideas tool. It is not designed to secure sensitive content.
              Avoid creating, uploading, or sharing sensitive content. <a href="https://www.matchboxxr.com/policies" target="_blank">Read more</a></Text>
              </Spoiler>}
                {/* SELECTED GOOGLE SLIDES DOC */}
              {
                fileSelected ? (selectedFile.type === 'pres') ?
                <Card key={selectedFile.id} withBorder radius="md" p={0} style={{ marginBottom: 20, borderRadius: 16 }}
                      shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                  <Suspense fallback={<Loader color="black" />}>
                    <Stack direction={"column"}>
                      {/* THUMBNAIL */}
                    <a href={selectedFile.url} target="_blank" style={{cursor: 'pointer', textDecoration:'none',backgroundColor:'none', border:'none'}}>
                      {selectedFile.thumbnail ? <img referrerPolicy="no-referrer" src={selectedFile.thumbnail} style={{ width: 350, height: "auto", position:'relative' }} /> :
                    <div style={{ width: 350, height: 196.875, backgroundColor: '#F4B400' }} />}</a>
                    <Stack pl={2} pr={2} pt={1} direction={"column"}  >
                      {/* TITLE */}
                    <Text size="xl" fw={700}><span style={{ whiteSpace: 'nowrap', maxWidth:300,textOverflow: 'ellipsis', overflow: 'hidden', paddingLeft:4, paddingRight:4,backgroundColor:"#fac710", position:"absolute", top:150}}>{selectedFile.name}</span></Text>

                    <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                    {/* SLIDE SELECTION DROPDOWN */}

                    <div style={{width:145}} >
                      <Select
                        comboboxProps={{ zIndex: 1000 }} 
                        label="Slide selected"
                        placeholder="0"
                        data={[0,...selectedFile.slides]}
                        value={selectedSlide}
                        onChange={(val) => setSelectedSlide(val)}
                      /></div>

                    {/* SHARE BUTTON */}
                    <Button
                      onClick={() => handleExportClick(selectedFile.id, selectedSlide)}
                      style={{
                        width: 100, marginTop: 20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
                      }}>Export</Button>
                      </Stack>
                      </Stack>
                      </Stack>
                  </Suspense> </Card>:
                  <Suspense fallback={<Loader color="black" />}>
                    Please select a Google Slides presentation only.
                  </Suspense> :
                  // <form onSubmit={form.onSubmit((values) => extractId(values))}>
                  <form onSubmit={form.onSubmit((values) => handleSubmitGoogleURL(values.url))}>
                  <TextInput
                    withAsterisk
                    label="Google Slides URL"
                    placeholder="Enter your presentation URL"
                    {...form.getInputProps('url')}
                  />
                   <Button style={{
                        width: 100, marginTop: 20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
                      }} type="submit">Submit</Button>
                  </form>          
              }
              {/* <GoogleSlidesMapped projectKey={projectKey}/> */}

            </>
              : <>
                  <GoogleShareButton projectKey={projectKey} /></>

            }
          </div>

        </Tabs.Panel>
      </Tabs>
      {/* </Card>  */}
    </Modal>

  );
}
