import React, {useEffect, useState} from "react";
import { useStore } from "../zustand/objects";
import {Box} from "@mui/material/";
import RedoIcon from '@mui/icons-material/Redo';
import { Card, Button,  Switch, Text, Group, Slider, ActionIcon } from '@mantine/core';


const RedoButton = () => {
  const {
    redoActions,
    undoRedoZustand,
    saveUserProject
  } = useStore();

  const redo = useStore((state) => state.liveblocks.room?.history.redo);


  const asyncRedo = async () => {
    redo()
    undoRedoZustand('redo')
   }


  const handleRedoClick = () => {
    redoActions.length > 0 && asyncRedo().then(() => saveUserProject())
  }
  

  // // KEYBOARD OBJECT FUNCTIONS
  // function handleKeyDown(event) {
  //   if (event.repeat) { return }  
  //   if (event.key === 'Z' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
  //     handleRedoClick()
  //   } 
  //   // if (event.key === 'Z' && (event.ctrlKey || event.metaKey)) {
  //   //   handleUndoClick()
  //   // }
  // }


  // useEffect(() => {
  //   function handleKeyDown(event) {
  //     if (event.repeat) { return }  
  //     if (event.key === 'z' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
  //       handleRedoClick()
  //     }
  //   }
  //   document.addEventListener("keydown", handleKeyDown)
  
  //   return () => document.removeEventListener("keydown", handleKeyDown)
  // }, [redo])

  // useEffect(() => {
  //   // Add keydown listener only if artboards have elements
  //     document.addEventListener('keydown', handleKeyDown);
  //   // Cleanup function to remove the listener on unmount
  //   return () => document.removeEventListener('keydown', handleKeyDown);
  // }, [history]); 

  return (
    <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: "100%",
          margin: "2.5px",
          paddingBottom: 0.5
        }}>
          <ActionIcon disabled={redoActions.length <= 0}
          size={'sm'}
          onClick={() => handleRedoClick()}
          // onClick={() => redoRemake()}
          variant="transparent"
          color="#424242"
          bg={'none'}
          >
            <RedoIcon fontSize={'small'} />
          </ActionIcon>
          {/* <button style={{backgroundColor: "none"}} disabled={redoActions.length <= 0} size={"small"} onClick={() => redoRemake()}>redo</button> */}
      </Box>
  );
};

export default RedoButton;
