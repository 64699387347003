import React from 'react';
import { useAccountStore } from "./zustand/account";
import { Navigate, Outlet } from 'react-router-dom';
 
export const PrivateRoute = props => {
    const {isAuthUser, stripeStatus, userStatus} = useAccountStore();
  
    const { path, type, component } = props;

    const pathName = (userStatus === 'loggedOut') ? '/login' : '/account'
    // const pathName = (userStatus === 'loggedOut') ? '/login' : '/membership'
    // const auth = null; // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    // trialing free option, by now not redirecting...
    // return  (userStatus === 'loggedIn-active') ? <Outlet {...props} />  : <Navigate to={pathName} /> 
    // return  (userStatus === 'loggedIn-active') ? <Outlet {...props} />  : <Navigate to={pathName} /> 
    // return  (userStatus === 'loggedOut') ? <Navigate to={'/login'} /> : <Outlet {...props} /> 
    return  (userStatus === 'loggedIn-active' || userStatus === 'loggedIn-none') ? <Outlet {...props} /> : <Navigate to={'/login'} /> 

}
