import React, { useEffect, useLayoutEffect, Suspense, useState, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Grid } from "@mui/material/";
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useStore } from "../zustand/objects";

import AppNavBar from "../components/AppNavBar";
import { Screenshot } from "../components/Screenshot";

import DeleteProjectModal from "../components/DeleteProjectModal"; 
import ProjectsTable from "../components/ProjectsTable";
import { postMiroAuthData, getFigmaFrameFromLink, getUserGoogleSlides, exportToGSlides, saveUserScreenshotsAPI, getUserGoogleSlidesThumbAndLength } from "../api";
import { Card, Button,  Image, Stack, TextInput, Text, Group, Badge, ActionIcon, Loader, LoadingOverlay } from '@mantine/core';
import { idText } from "typescript";

// we need to store the permission key in XANO and then it can be shared with any collaborating user

export const MiroAuthCallbackPage = () => {

    const [haveToken, setHaveToken] = useState(false)
    const [boards, setBoards] = useState([])
   
    const urlParams = new URLSearchParams(window.location.search);
    const stateParam = urlParams.get('state');
    const codeParam = urlParams.get('code');

const getMiroAccessToken = (data
    ) => {
      return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/miroAccessToken', {
        method: 'POST',
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('user')}`
        },
        body: JSON.stringify({
            authorisationCode: codeParam
          })
      })
        .then((response) => response.json())
        .then((data) => {
          return data
        })
        .catch((error) => {
          console.error("Error:", error);
    
        });
    }


const getUserMiroBoardsAPI = (
    data
  ) => {
    return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getMiroBoardsByUser', {
      method: 'GET',
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('user')}`
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        console.error("Error:", error);
  
      });
  }




  useEffect(() => {
// GET ALL A USER'S MIRO BOARDS
getMiroAccessToken()
.then(() => getUserMiroBoardsAPI())
// .then((res) => boards.concat([res.response.result.data]))
.then((res) => setBoards([...res.response.result.data]))
// .then((res) => console.log(res.response.result.data))
.then(() => setHaveToken(true))

  },[])
    

 
    const GridBackground = () => {
        return (
            <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100vh", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

        )
    }

    return (
        <div>
            <Suspense fallback={<div>Loading... </div>}>
                <GridBackground />
                <Grid container>
                    <Grid item xs={12} md={12} sx={{ mt: 8, mb: 4 }}>
                        <Box sx={{ marginLeft: 10, flexGrow: 1 }}>
                            {/* <Typography component="h1" variant="h4" fontWeight={'700'}>
                                This is where we browse miro projects and pick one to export the mbxr projects to, using the projectKey in the URL.
                            </Typography> */}
                            <a href={`https://mbxr.io/editor/${stateParam}`}>Return to editor</a>
                        </Box>
                    </Grid>
                    
                  {/* {haveToken && <MiroBoardsMapped boards={boards} projectKey={stateParam}/>} */}
                    
                </Grid>
            </Suspense>
        </div>
    )
};







export const MiroBoardsMapped = ({projectKey, selectFunction}) => {


  const [haveToken, setHaveToken] = useState(false)
  const [boards, setBoards] = useState([])
  const [sending, setSending] = useState(false)
  const [showExportButton, setShowExportButton] = useState(false)

const {screenshots, artboards, setScreenshotStatus} = useStore()


const screenshotArray = Object.values(screenshots)

const saveScreenshots = () => {

  // saveUserScreenshotsAPI({projectKey:projectKey, screenshots: screenshots})
  saveUserScreenshotsAPI({projectKey:projectKey, screenshots: [...screenshotArray]})
}


const getUserMiroBoardsAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getMiroBoardsByUser', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}



  useEffect(() => {
    // GET ALL A USER'S MIRO BOARDS
    // setScreenshotStatus(true)
getUserMiroBoardsAPI()
.then((res) => setBoards([...res.response.result.data]))
// .then(() => window.setTimeout(function () {
//   saveScreenshots()
// }, 300))
.then(() => setHaveToken(true))
.then(() => setShowExportButton(true))

// .then(() => setScreenshotStatus(false))
// .then(() => saveScreenshots())
    
      },[])



   const sendStoryboardToMiro = (data
        ) => {
          return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/CreateMiroStoryboard', {
            method: 'POST',
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('user')}`
            },
            body: JSON.stringify({
                boardId: data.boardId,
                projectKey: projectKey,
                frameId: data.frameId
              })
          })
            .then((response) => response.json())
            .then((data) => {
              return data
            })
            .catch((error) => {
              console.error("Error:", error);
        
            });
        }

        const [shotArtboard, setShotArtboard] = useState()

       async function updateScreenshots() {
          Object.keys(artboards).forEach((artboard, i) =>  setTimeout(() => {
            setShotArtboard(artboard);
          }, i * 250))
          // Object.keys(artboards).forEach((artboard, i) =>  setTimeout(() => {
          //   console.log(artboard);
          // }, i * 250))
          // Object.keys(artboards).forEach((artboard) => console.log(artboard))
          // Object.keys(artboards).forEach((artboard) => setShotArtboard(artboard))
          
        }

        // useLayoutEffect(() => {
          
        // },[shotArtboard])
    
async function saveScenes() {
  saveScreenshots()
}

    const handleExportClick = (boardId) => {
      setSending(true)
      saveUserScreenshotsAPI({projectKey:projectKey, screenshots: [...screenshotArray]})
      .then(() =>sendStoryboardToMiro(boardId))
      .then(() =>setScreenshotStatus(false))
      .then(() =>setSending(false))
    }

    const handleSelectClick = (name, boardId, url, picture) => {
      selectFunction(name, boardId, url, picture)
    }
 
return (<> 
    {/* {Object.keys(artboards).map((mapped) => (<div key={mapped} ><Screenshot projectKey={projectKey} guestMode={false} artboard={mapped} /></div>)) } */}
  <LoadingOverlay loaderProps={{ color: 'black' }} visible={sending} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
  {!showExportButton && <div style={{width:"100%", height:"50%", display:"flex", justifyContent:"center", alignItems:"center"}}>
  <div>
  <Loader color="black" /></div></div>}
  {haveToken && boards.map((mapped) => (
        <Card key={mapped.id} withBorder radius="md" p={0} style={{  marginBottom:20, borderRadius:16}}
            shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
          <Group wrap="nowrap" gap={0}>
          
                {mapped.picture ? 
                <a href={mapped.viewLink} target="_blank" style={{cursor: 'pointer', textDecoration:'none',backgroundColor:'none', border:'none'}}>
                  <div style={{ width: 160, height: 90, overflow:'hidden'}}>
                    <img src={mapped.picture.imageURL} style={{width:160, height:160 }}/></div></a> : 
                    <a href={mapped.viewLink} target="_blank" style={{cursor: 'pointer', textDecoration:'none',backgroundColor:'none', border:'none'}}>
                    <div style={{ width: 160, height: 90, backgroundColor: '#F4B400' }} /></a>}
                <Stack style={{marginLeft:20}}>
                    <Text size="sm" style={{whiteSpace:'nowrap', marginLeft:5, marginRight:5, marginTop:5, textOverflow: 'ellipsis', overflow:'hidden', maxWidth:140}}>{mapped.name}</Text>
                {<Button
                    onClick={() => mapped.picture ? handleSelectClick(mapped.name, mapped.id, mapped.viewLink, mapped.picture.imageURL) 
                     : handleSelectClick(mapped.name, mapped.id, mapped.viewLink, null) }
                    style={{
                        width: 150, paddingTop: 1, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
                    }}>Select board</Button>}
                {/* {<Button
                    onClick={() => handleExportClick(mapped.id)}
                    style={{
                        width: 150, paddingTop: 1, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
                    }}>Export to board</Button>} */}
                    </Stack>
            </Group></Card>
))}</>)

}









export const GoogleSlidesMapped = ({projectKey}) => {


  const [haveToken, setHaveToken] = useState(false)
  const [slides, setSlides] = useState([])
  const [responded, setResponded] = useState(false)
  

const {screenshots, artboards, setScreenshotStatus} = useStore()


const screenshotArray = Object.values(screenshots)

const saveScreenshots = () => {


  saveUserScreenshotsAPI({projectKey:projectKey, screenshots: [...screenshotArray]})
}
 
const setScreenshotSetSlides = () => {
  // setScreenshotStatus(true)
  getUserGoogleSlides()
    .then((res) => setSlides([...res.slides]))
    .then(() => setHaveToken(true))
    .then(() => setResponded(true))
}



  useEffect(() => {
    setScreenshotSetSlides()
      },[])



        const [shotArtboard, setShotArtboard] = useState()

       async function updateScreenshots() {
          Object.keys(artboards).forEach((artboard, i) =>  setTimeout(() => {
            setShotArtboard(artboard);
          }, i * 250))
         
        }


        const [sending, setSending] = useState(false)
    
async function saveScenes() {
  saveScreenshots()
} 

    const handleExportClick = (id, slidesLength) => {
      setSending(true)
      saveUserScreenshotsAPI({projectKey:projectKey, screenshots: [...screenshotArray]})
      .then(() =>exportToGSlides({presentationID:id,index:slidesLength, projectKey:projectKey}))
      .then(() =>setScreenshotStatus(false))
      .then(() =>setSending(false))
      
    }



  return (<><LoadingOverlay loaderProps={{ color: 'black' }} visible={sending} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
  {haveToken && !responded ? <div style={{width:"100%", height:"50%", display:"flex", justifyContent:"center", alignItems:"center"}}>
  <div>
  <Loader color="black" /></div></div> :
  slides.map((mapped) => (
      <GoogleSlideThumb key={mapped.id} id={mapped.id} name={mapped.name} handleExportClick={handleExportClick} />
    ))}</>)

}



const GoogleSlideThumb = ({id, name, handleExportClick}) => {



const slidesURL = `https://docs.google.com/presentation/d/${id}`
const [imageURL, setImageURL] = useState()
const [slidesLength, setSlidesLength] = useState()
const [showExportButton, setShowExportButton] = useState(false)

const getPresentationInfo = () => getUserGoogleSlidesThumbAndLength(id)

useEffect(() => {
  !imageURL && getPresentationInfo()
  .then(res => {
    setImageURL(res.imageURL)
    setSlidesLength(res.slidesLength)
    setShowExportButton(true)
  })
},[])



  return (
  
    <Card key={id} withBorder radius="md" p={0} style={{ marginBottom: 20, borderRadius: 16 }}
      shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
      
      <Group wrap="nowrap" gap={0}>
      <a href={slidesURL} target="_blank" style={{cursor: 'pointer', textDecoration:'none',backgroundColor:'none', border:'none'}}>
        <div style={{ maxWidth: 160, maxHeight: 90 }}>

          {imageURL ? <img referrerPolicy="no-referrer" src={imageURL} style={{ width: "100%", height: "100%" }} /> :
            <div style={{ width: 160, height: 90, backgroundColor: '#F4B400' }} />}
        </div></a>
        <Stack style={{ marginLeft: 20 }}>
          <Text size="sm" style={{ whiteSpace: 'nowrap', marginLeft: 5, marginRight: 5, marginTop: 5, textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 140 }}>{name}</Text>
         {showExportButton ? <Button
            onClick={() => handleExportClick(id, slidesLength)}
            style={{
              width: 150, paddingTop: 1, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
            // }}>Coming soon</Button>
            }}>Export to slides</Button> : <Loader color="black" />}
        </Stack>
      </Group></Card>
  )
}