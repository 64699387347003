import React, { useState} from "react";
import { MenuItem } from "@mui/material/";
import SquareIcon from '@mui/icons-material/Square';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import { useStore } from "../zustand/objects";

import { ColorInput } from '@mantine/core';

const ModelColourButton = ({ colour, id }) => {

  const swatchColours = ['#000000', '#C4A484', '#00FFFF', '#359DED', '#77D356', '#C0C0C0', '#808080', '#FFA500', '#CD303E', '#FF00FF', '#FAC710', '#FFFFFF']

  const colours = [
    { value: null, label: 'None' },
    { value: 'black', label: 'Black' },
    { value: '#C4A484', label: 'Brown' },
    { value: 'lightBlue', label: 'Light Blue' },
    { value: '#359DED', label: 'Blue' },
    { value: 'lightGreen', label: 'Light Green' },
    { value: '#77D356', label: 'Green' },
    { value: 'lightGrey', label: 'Light Grey' },
    { value: 'grey', label: 'Grey' },
    { value: 'orange', label: 'Orange' },
    { value: '#CD303E', label: 'Red' },
    { value: 'violet', label: 'Violet' },
    { value: 'white', label: 'White' },
    { value: '#FAC710', label: 'Yellow' }
  ]

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);

  };

  const [colourSet, setColourSet] = useState(colour)


  const { objectsAreLoaded, updateShapeColour, currentObjectArtboard } = useStore();

  const handleSelect = (mapped) => {

    mapped && updateShapeColour({
      id: id,
      currentObjectArtboard: currentObjectArtboard,
      colour: mapped,
      undo: false
    })
    setColourSet(mapped)
  }

  const style = {
    blockquote: {
      fontStyle: 'italic', 
      fontSize: '.75rem'
    },
    label: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      lineHeight: 2
    },
  };

  return (

    <div style={{ inline: true, height:28, marginTop:15, paddingTop:1, fontSize: 12, width:30,  display:'flex', transition: "width 0.2s", justifyContent: 'flex-start' }}>
      <ColorInput
      placeholder="Colour"
      defaultValue={colourSet}
      onChange={(e) => handleSelect(e)}
      // label="Colour"
      size="xs"
      radius={32}
      disallowInput
      withPicker={false}
      withEyeDropper={false}
      swatches={[...swatchColours]}
      swatchesPerRow={4}
    />
    </div>


  );
};

export default ModelColourButton;











