import React, { useState } from "react";
import { Box } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { Interactive, useXR } from "@react-three/xr";
import { useStore } from "../zustand/objects";
import { ChangePreviewScene, VRChangePreviewScene } from "./ChangePreviewScene";

export const TeleportFloorGrid = () => {

    const [hovered, setHovered] = useState('')

    const {
        setTeleportCount,
        teleportCount,
        updatePreviewCamera,
        previewCameraPosition
    } = useStore();

    const { camera } = useThree()

    const cameraPosition = [camera.position.x, camera.position.y, camera.position.z]

    const teleportPosition = (val) => {
        setTeleportCount(teleportCount + 1)
        camera.position.x = val[0]
        camera.position.z = val[2]
        updatePreviewCamera('position', [camera.position.x, camera.position.y, camera.position.z])
        // console.log([camera.position.x, camera.position.y, camera.position.z])
        // console.log(previewCameraPosition)
    }

    const positionArray = [
        [camera.position.x - 5, 0, camera.position.z - 5],
        [camera.position.x - 5, 0, camera.position.z - 4],
        [camera.position.x - 5, 0, camera.position.z - 3],
        [camera.position.x - 5, 0, camera.position.z - 2],
        [camera.position.x - 5, 0, camera.position.z - 1],
        [camera.position.x - 5, 0, camera.position.z],
        [camera.position.x - 5, 0, camera.position.z + 1],
        [camera.position.x - 5, 0, camera.position.z + 2],
        [camera.position.x - 5, 0, camera.position.z + 3],
        [camera.position.x - 5, 0, camera.position.z + 4],
        [camera.position.x - 5, 0, camera.position.z + 5],

        [camera.position.x - 4, 0, camera.position.z - 5],
        [camera.position.x - 4, 0, camera.position.z - 4],
        [camera.position.x - 4, 0, camera.position.z - 3],
        [camera.position.x - 4, 0, camera.position.z - 2],
        [camera.position.x - 4, 0, camera.position.z - 1],
        [camera.position.x - 4, 0, camera.position.z],
        [camera.position.x - 4, 0, camera.position.z + 1],
        [camera.position.x - 4, 0, camera.position.z + 2],
        [camera.position.x - 4, 0, camera.position.z + 3],
        [camera.position.x - 4, 0, camera.position.z + 4],
        [camera.position.x - 4, 0, camera.position.z + 5],

        [camera.position.x - 3, 0, camera.position.z - 5],
        [camera.position.x - 3, 0, camera.position.z - 4],
        [camera.position.x - 3, 0, camera.position.z - 3],
        [camera.position.x - 3, 0, camera.position.z - 2],
        [camera.position.x - 3, 0, camera.position.z - 1],
        [camera.position.x - 3, 0, camera.position.z],
        [camera.position.x - 3, 0, camera.position.z + 1],
        [camera.position.x - 3, 0, camera.position.z + 2],
        [camera.position.x - 3, 0, camera.position.z + 3],
        [camera.position.x - 3, 0, camera.position.z + 4],
        [camera.position.x - 3, 0, camera.position.z + 5],

        [camera.position.x - 2, 0, camera.position.z - 5],
        [camera.position.x - 2, 0, camera.position.z - 4],
        [camera.position.x - 2, 0, camera.position.z - 3],
        [camera.position.x - 2, 0, camera.position.z - 2],
        [camera.position.x - 2, 0, camera.position.z - 1],
        [camera.position.x - 2, 0, camera.position.z],
        [camera.position.x - 2, 0, camera.position.z + 1],
        [camera.position.x - 2, 0, camera.position.z + 2],
        [camera.position.x - 2, 0, camera.position.z + 3],
        [camera.position.x - 2, 0, camera.position.z + 4],
        [camera.position.x - 2, 0, camera.position.z + 5],

        [camera.position.x - 1, 0, camera.position.z - 5],
        [camera.position.x - 1, 0, camera.position.z - 4],
        [camera.position.x - 1, 0, camera.position.z - 3],
        [camera.position.x - 1, 0, camera.position.z - 2],
        [camera.position.x - 1, 0, camera.position.z - 1],
        [camera.position.x - 1, 0, camera.position.z],
        [camera.position.x - 1, 0, camera.position.z + 1],
        [camera.position.x - 1, 0, camera.position.z + 2],
        [camera.position.x - 1, 0, camera.position.z + 3],
        [camera.position.x - 1, 0, camera.position.z + 4],
        [camera.position.x - 1, 0, camera.position.z + 5],

        [camera.position.x, 0, camera.position.z - 5],
        [camera.position.x, 0, camera.position.z - 4],
        [camera.position.x, 0, camera.position.z - 3],
        [camera.position.x, 0, camera.position.z - 2],
        [camera.position.x, 0, camera.position.z - 1],
        // this is where the user's camera is
        [camera.position.x, 0, camera.position.z + 1],
        [camera.position.x, 0, camera.position.z + 2],
        [camera.position.x, 0, camera.position.z + 3],
        [camera.position.x, 0, camera.position.z + 4],
        [camera.position.x, 0, camera.position.z + 5],

        [camera.position.x + 1, 0, camera.position.z - 5],
        [camera.position.x + 1, 0, camera.position.z - 4],
        [camera.position.x + 1, 0, camera.position.z - 3],
        [camera.position.x + 1, 0, camera.position.z - 2],
        [camera.position.x + 1, 0, camera.position.z - 1],
        [camera.position.x + 1, 0, camera.position.z],
        [camera.position.x + 1, 0, camera.position.z + 1],
        [camera.position.x + 1, 0, camera.position.z + 2],
        [camera.position.x + 1, 0, camera.position.z + 3],
        [camera.position.x + 1, 0, camera.position.z + 4],
        [camera.position.x + 1, 0, camera.position.z + 5],

        [camera.position.x + 2, 0, camera.position.z - 5],
        [camera.position.x + 2, 0, camera.position.z - 4],
        [camera.position.x + 2, 0, camera.position.z - 3],
        [camera.position.x + 2, 0, camera.position.z - 2],
        [camera.position.x + 2, 0, camera.position.z - 1],
        [camera.position.x + 2, 0, camera.position.z],
        [camera.position.x + 2, 0, camera.position.z + 1],
        [camera.position.x + 2, 0, camera.position.z + 2],
        [camera.position.x + 2, 0, camera.position.z + 3],
        [camera.position.x + 2, 0, camera.position.z + 4],
        [camera.position.x + 2, 0, camera.position.z + 5],

        [camera.position.x + 3, 0, camera.position.z - 5],
        [camera.position.x + 3, 0, camera.position.z - 4],
        [camera.position.x + 3, 0, camera.position.z - 3],
        [camera.position.x + 3, 0, camera.position.z - 2],
        [camera.position.x + 3, 0, camera.position.z - 1],
        [camera.position.x + 3, 0, camera.position.z],
        [camera.position.x + 3, 0, camera.position.z + 1],
        [camera.position.x + 3, 0, camera.position.z + 2],
        [camera.position.x + 3, 0, camera.position.z + 3],
        [camera.position.x + 3, 0, camera.position.z + 4],
        [camera.position.x + 3, 0, camera.position.z + 5],

        [camera.position.x + 4, 0, camera.position.z - 5],
        [camera.position.x + 4, 0, camera.position.z - 4],
        [camera.position.x + 4, 0, camera.position.z - 3],
        [camera.position.x + 4, 0, camera.position.z - 2],
        [camera.position.x + 4, 0, camera.position.z - 1],
        [camera.position.x + 4, 0, camera.position.z],
        [camera.position.x + 4, 0, camera.position.z + 1],
        [camera.position.x + 4, 0, camera.position.z + 2],
        [camera.position.x + 4, 0, camera.position.z + 3],
        [camera.position.x + 4, 0, camera.position.z + 4],
        [camera.position.x + 4, 0, camera.position.z + 5],

        [camera.position.x + 5, 0, camera.position.z - 5],
        [camera.position.x + 5, 0, camera.position.z - 4],
        [camera.position.x + 5, 0, camera.position.z - 3],
        [camera.position.x + 5, 0, camera.position.z - 2],
        [camera.position.x + 5, 0, camera.position.z - 1],
        [camera.position.x + 5, 0, camera.position.z],
        [camera.position.x + 5, 0, camera.position.z + 1],
        [camera.position.x + 5, 0, camera.position.z + 2],
        [camera.position.x + 5, 0, camera.position.z + 3],
        [camera.position.x + 5, 0, camera.position.z + 4],
        [camera.position.x + 5, 0, camera.position.z + 5]
    ]

    return (<>
        {/* <ChangePreviewScene position={[camera.position.x + 15, 6, camera.position.z]}
            rotation={[0, - Math.PI / 2, 0]} />
        <ChangePreviewScene position={[camera.position.x - 15, 6, camera.position.z]}
            rotation={[0, Math.PI / 2, 0]} />
        <ChangePreviewScene position={[camera.position.x, 6, camera.position.z - 15]}
            rotation={[0, 0, 0]} />
        <ChangePreviewScene position={[camera.position.x, 6, camera.position.z + 15]}
            rotation={[0, Math.PI, 0]} /> */}
        {positionArray.map((mapped, i) => {
            return (<Box
                key={i}
                position={mapped}
                args={[0.75, 0.1, 0.75]}
                onPointerEnter={() => setHovered(i)}
                onPointerLeave={() => setHovered('')}
                onPointerUp={() => teleportPosition(mapped)}
            >
                <meshBasicMaterial
                    color={hovered === i ? "rgb(250, 199, 16)" : 'white'}
                />
            </Box>)
        })}
    </>
    )


}

export const ChangeSceneDesktop = () => {

    const {
        setTeleportCount,
        teleportCount,
        updatePreviewCamera,
        previewCameraPosition
    } = useStore();

    const { camera } = useThree()

    return (<>
        <ChangePreviewScene position={[camera.position.x + 15, 10, camera.position.z]}
            rotation={[0, - Math.PI / 2, 0]} />
        <ChangePreviewScene position={[camera.position.x - 15, 10, camera.position.z]}
            rotation={[0, Math.PI / 2, 0]} />
        <ChangePreviewScene position={[camera.position.x, 10, camera.position.z - 15]}
            rotation={[0, 0, 0]} />
        <ChangePreviewScene position={[camera.position.x, 10, camera.position.z + 15]}
            rotation={[0, Math.PI, 0]} />
    </>
    )


}


export const VRTeleportFloorGrid = () => {


    const {
        setTeleportCount,
        teleportCount,
        updatePreviewCamera
    } = useStore();



    const [hovered, setHovered] = useState('')

    const { player } = useXR()

    const teleportPosition = (val) => {
        setTeleportCount(teleportCount + 1)
        player.position.x = val[0]
        player.position.z = val[2]
        updatePreviewCamera('position', val)
    }

    const positionArray = [
        [player.position.x - 5, 0, player.position.z - 5],
        [player.position.x - 5, 0, player.position.z - 4],
        [player.position.x - 5, 0, player.position.z - 3],
        [player.position.x - 5, 0, player.position.z - 2],
        [player.position.x - 5, 0, player.position.z - 1],
        [player.position.x - 5, 0, player.position.z],
        [player.position.x - 5, 0, player.position.z + 1],
        [player.position.x - 5, 0, player.position.z + 2],
        [player.position.x - 5, 0, player.position.z + 3],
        [player.position.x - 5, 0, player.position.z + 4],
        [player.position.x - 5, 0, player.position.z + 5],

        [player.position.x - 4, 0, player.position.z - 5],
        [player.position.x - 4, 0, player.position.z - 4],
        [player.position.x - 4, 0, player.position.z - 3],
        [player.position.x - 4, 0, player.position.z - 2],
        [player.position.x - 4, 0, player.position.z - 1],
        [player.position.x - 4, 0, player.position.z],
        [player.position.x - 4, 0, player.position.z + 1],
        [player.position.x - 4, 0, player.position.z + 2],
        [player.position.x - 4, 0, player.position.z + 3],
        [player.position.x - 4, 0, player.position.z + 4],
        [player.position.x - 4, 0, player.position.z + 5],

        [player.position.x - 3, 0, player.position.z - 5],
        [player.position.x - 3, 0, player.position.z - 4],
        [player.position.x - 3, 0, player.position.z - 3],
        [player.position.x - 3, 0, player.position.z - 2],
        [player.position.x - 3, 0, player.position.z - 1],
        [player.position.x - 3, 0, player.position.z],
        [player.position.x - 3, 0, player.position.z + 1],
        [player.position.x - 3, 0, player.position.z + 2],
        [player.position.x - 3, 0, player.position.z + 3],
        [player.position.x - 3, 0, player.position.z + 4],
        [player.position.x - 3, 0, player.position.z + 5],

        [player.position.x - 2, 0, player.position.z - 5],
        [player.position.x - 2, 0, player.position.z - 4],
        [player.position.x - 2, 0, player.position.z - 3],
        [player.position.x - 2, 0, player.position.z - 2],
        [player.position.x - 2, 0, player.position.z - 1],
        [player.position.x - 2, 0, player.position.z],
        [player.position.x - 2, 0, player.position.z + 1],
        [player.position.x - 2, 0, player.position.z + 2],
        [player.position.x - 2, 0, player.position.z + 3],
        [player.position.x - 2, 0, player.position.z + 4],
        [player.position.x - 2, 0, player.position.z + 5],

        [player.position.x - 1, 0, player.position.z - 5],
        [player.position.x - 1, 0, player.position.z - 4],
        [player.position.x - 1, 0, player.position.z - 3],
        [player.position.x - 1, 0, player.position.z - 2],
        [player.position.x - 1, 0, player.position.z - 1],
        [player.position.x - 1, 0, player.position.z],
        [player.position.x - 1, 0, player.position.z + 1],
        [player.position.x - 1, 0, player.position.z + 2],
        [player.position.x - 1, 0, player.position.z + 3],
        [player.position.x - 1, 0, player.position.z + 4],
        [player.position.x - 1, 0, player.position.z + 5],

        [player.position.x, 0, player.position.z - 5],
        [player.position.x, 0, player.position.z - 4],
        [player.position.x, 0, player.position.z - 3],
        [player.position.x, 0, player.position.z - 2],
        [player.position.x, 0, player.position.z - 1],
        // this is where the user's player is
        [player.position.x, 0, player.position.z + 1],
        [player.position.x, 0, player.position.z + 2],
        [player.position.x, 0, player.position.z + 3],
        [player.position.x, 0, player.position.z + 4],
        [player.position.x, 0, player.position.z + 5],

        [player.position.x + 1, 0, player.position.z - 5],
        [player.position.x + 1, 0, player.position.z - 4],
        [player.position.x + 1, 0, player.position.z - 3],
        [player.position.x + 1, 0, player.position.z - 2],
        [player.position.x + 1, 0, player.position.z - 1],
        [player.position.x + 1, 0, player.position.z],
        [player.position.x + 1, 0, player.position.z + 1],
        [player.position.x + 1, 0, player.position.z + 2],
        [player.position.x + 1, 0, player.position.z + 3],
        [player.position.x + 1, 0, player.position.z + 4],
        [player.position.x + 1, 0, player.position.z + 5],

        [player.position.x + 2, 0, player.position.z - 5],
        [player.position.x + 2, 0, player.position.z - 4],
        [player.position.x + 2, 0, player.position.z - 3],
        [player.position.x + 2, 0, player.position.z - 2],
        [player.position.x + 2, 0, player.position.z - 1],
        [player.position.x + 2, 0, player.position.z],
        [player.position.x + 2, 0, player.position.z + 1],
        [player.position.x + 2, 0, player.position.z + 2],
        [player.position.x + 2, 0, player.position.z + 3],
        [player.position.x + 2, 0, player.position.z + 4],
        [player.position.x + 2, 0, player.position.z + 5],

        [player.position.x + 3, 0, player.position.z - 5],
        [player.position.x + 3, 0, player.position.z - 4],
        [player.position.x + 3, 0, player.position.z - 3],
        [player.position.x + 3, 0, player.position.z - 2],
        [player.position.x + 3, 0, player.position.z - 1],
        [player.position.x + 3, 0, player.position.z],
        [player.position.x + 3, 0, player.position.z + 1],
        [player.position.x + 3, 0, player.position.z + 2],
        [player.position.x + 3, 0, player.position.z + 3],
        [player.position.x + 3, 0, player.position.z + 4],
        [player.position.x + 3, 0, player.position.z + 5],

        [player.position.x + 4, 0, player.position.z - 5],
        [player.position.x + 4, 0, player.position.z - 4],
        [player.position.x + 4, 0, player.position.z - 3],
        [player.position.x + 4, 0, player.position.z - 2],
        [player.position.x + 4, 0, player.position.z - 1],
        [player.position.x + 4, 0, player.position.z],
        [player.position.x + 4, 0, player.position.z + 1],
        [player.position.x + 4, 0, player.position.z + 2],
        [player.position.x + 4, 0, player.position.z + 3],
        [player.position.x + 4, 0, player.position.z + 4],
        [player.position.x + 4, 0, player.position.z + 5],

        [player.position.x + 5, 0, player.position.z - 5],
        [player.position.x + 5, 0, player.position.z - 4],
        [player.position.x + 5, 0, player.position.z - 3],
        [player.position.x + 5, 0, player.position.z - 2],
        [player.position.x + 5, 0, player.position.z - 1],
        [player.position.x + 5, 0, player.position.z],
        [player.position.x + 5, 0, player.position.z + 1],
        [player.position.x + 5, 0, player.position.z + 2],
        [player.position.x + 5, 0, player.position.z + 3],
        [player.position.x + 5, 0, player.position.z + 4],
        [player.position.x + 5, 0, player.position.z + 5]

    ]
 
    // return (
    //     positionArray.map((mapped, i) => {
    //         return (
    //             <Interactive
    //                 key={i}
    //                 onHover={() => setHovered(i)}
    //                 onBlur={() => setHovered('')}
    //                 onSelectEnd={() => teleportPosition(mapped)}
    //             >
    //                 <Box
    //                     position={mapped}
    //                     args={[0.75, 0.1, 0.75]}
    //                 >
    //                     <meshBasicMaterial
    //                         color={hovered === i ? "rgb(250, 199, 16)" : "white"}
    //                     />
    //                 </Box>
    //             </Interactive>)

            return (<>
                <VRChangePreviewScene position={[player.position.x + 15, 10, player.position.z]}
                    rotation={[0, - Math.PI / 2, 0]} />
                <VRChangePreviewScene position={[player.position.x - 15, 10, player.position.z]}
                    rotation={[0, Math.PI / 2, 0]} />
                <VRChangePreviewScene position={[player.position.x, 10, player.position.z - 15]}
                    rotation={[0, 0, 0]} />
                <VRChangePreviewScene position={[player.position.x, 10, player.position.z + 15]}
                    rotation={[0, Math.PI, 0]} />
                {/* {positionArray.map((mapped, i) => {
                    return (<Interactive
                        key={i}
                        onHover={() => setHovered(i)}
                        onBlur={() => setHovered('')}
                        onSelectEnd={() => teleportPosition(mapped)}
                    >
                        <Box
                            position={mapped}
                            args={[0.75, 0.1, 0.75]}
                        >
                            <meshBasicMaterial
                                color={hovered === i ? "rgb(250, 199, 16)" : "white"}
                            />
                        </Box>
                    </Interactive>)
        })} */}
    </>
    )


}