import React, { useState, useEffect, useLayoutEffect} from 'react';
import { Grid, Modal, Box, Typography } from "@mui/material/";
import { Card, Button, Text, Switch, TextInput, Group, FileButton, ActionIcon } from '@mantine/core';

import { useStore } from "../zustand/objects";
import { useAccountStore } from "../zustand/account";
import { getUserFigmaFramesAPI, checkForValidFigmaKey } from "../api.js";

import ImagesAPIMapped from "./ImagesAPIMapped";
import FigmaFramesAPIMapped from "./FigmaFramesAPIMapped";
import FigmaModalContents from "./FigmaModalContents";
import { CardGiftcardTwoTone } from '@mui/icons-material';


export default function ImageModal({id}) {

    
  const {
    imageModalOpen,
    switchImageModalOpen,
    imagePurpose,
    setImagePurpose
  } = useStore();


  

  const [textOpen, setTextOpen] = useState(false);


  const onImageSubmit = () => {
      switchImageModalOpen()

  }

  const onImageCancel = () => {
    switchImageModalOpen(null)
  }

  const clientID = '1r9xtPmBRAO8EuhJNwQ4Nk'
  const redirectURI = 'https://mbxr.io/auth-complete'
  const scope = 'files:read'
  const responseType = 'code'

  const rando = [...Array(30)].map(() => Math.random().toString(36)[2]).join('')

const updateFigmaStateAsync = async () => localStorage.setItem('figmaState', rando)

const figmaAuthURL = `https://www.figma.com/oauth?
client_id=${clientID}&
redirect_uri=${redirectURI}&
scope=${scope}&
state=${rando}&
response_type=${responseType}
`

const popup = () => window.open(figmaAuthURL, "popup", "popup=true")
// const checkPopup = () => setInterval(() => {
//   if (popup.window.location.href
//     .includes(redirectURI)) {popup.close()}
// if (!popup || !popup.closed) return;
// clearInterval(checkPopup)
// }, 1000);

const handleFigmaSubmit = () => {
  updateFigmaStateAsync().then(() => popup())
}



  // if (imagePurpose === "object") {
  //   return (
  //     <Modal
  //       open={imageModalOpen}
  //       onClose={onImageCancel}
  //       aria-labelledby="modal-modal-title"
  //       aria-describedby="modal-modal-description"
  //       disableEnforceFocus
  //     >
  //       <Card style={{
  //         borderRadius: 32, width: 500,
  //         height: 320, top: '50%', overflow: 'hidden',
  //         left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)'
  //       }}>
  //         <Grid container >
  //           <Grid item xs={12}>
  //             <Typography id="modal-modal-title" variant="h6" component="h2">
  //               Import an image object
  //             </Typography>
  //             <Grid item xs={12}>
  //               {imageModalOpen && <ImagesAPIMapped />}
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       </Card>
  //     </Modal>
  //   )
  // }
 if (imagePurpose === "objectEdit") {
    return (
    <Modal
      open={imageModalOpen}
      onClose={onImageCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <Card padding={10} style={{borderRadius:32, width: 500, padding:0,
        height: 320,  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
          {imageModalOpen && <ImagesAPIMapped id={id} />}
        </Card>
    </Modal>
  )}

  else if (imagePurpose === "texture") {
    return (
    <Modal
      open={imageModalOpen}
      onClose={onImageCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
       <Card style={{borderRadius:32, width: 500, padding:0,
        height: 320,  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
          {imageModalOpen && <ImagesAPIMapped id={id} />}
      </Card> 
    </Modal>
  )}

  else if (imagePurpose === "figma") {
    return (
    <Modal
      open={imageModalOpen}
      onClose={onImageCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus 
    >
      <Card style={{borderRadius:32, width: 500, overflow:"hidden", padding:0,
        maxaHeight: 360,  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
       <FigmaModalContents/>
      </Card>
    </Modal>
  )}
}