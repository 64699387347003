import React, { useState, useEffect } from "react";
import { MenuItem } from "@mui/material/";
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import { Select, Portal } from '@mantine/core';

import { useStore } from "../zustand/objects";

const LinksToButton = ({ destination, id }) => {

  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(68);


 
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = ({ id }) => {
    setAnchorEl(null);
    setShow(false);
    setShowText(false);
  };


  const { artboards, updateDestination, currentObjectArtboard, objectsAreLoaded } = useStore();

  const handleSelect = (mappedItem) => {
    mappedItem && updateDestination({
      id: id,
      currentObjectArtboard: currentObjectArtboard,
      destination: mappedItem,
      undo: false
    })
  }

  const style = {
    blockquote: {
      fontStyle: 'italic',
      fontSize: '.75rem',
    },
    label: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      lineHeight: 2
    },
  };

  const options = []

  const optionsMap = Object.keys(artboards).filter((mapped) => mapped !== currentObjectArtboard)
  


  return (

    <div style={{ height: "100%", width:85, display: 'flex', justifyContent: 'flex-start', zindex: 1600, overflow: 'visible' }}>
      <Select
        // style={{zIndex:2000, borderRadius: '16px', dropdown:{zIndex:2000}, options:{zIndex:2000}, group:{zIndex:2000}}}
        radius={16}
        size={'xs'}
        label="Links to scene"
        placeholder="Pick..."
        data={[...optionsMap]}
        clearable
        
        
        defaultValue={destination}
        onChange={(e) => e ? handleSelect(e) : updateDestination({
          id: id,
          currentObjectArtboard: currentObjectArtboard,
          destination: '',
          undo: false
        })}
      />

    </div>


  );
};

export default LinksToButton;













// <div style={{ height: "100%", display: 'flex', justifyContent: 'flex-start' }}>
      
// <Button
//   id="basic-button"
//   aria-controls={open ? 'basic-menu' : undefined}
//   aria-haspopup="true"
//   aria-expanded={open ? 'true' : undefined}
//   onClick={handleClick}
//   size={"small"}
//   style={{ backgroundColor: "white", boxShadow: "none", color: 'black' }}

// >
// <div style={{ height: "100%", display: 'flex' }}>
//   <label style={style.label} id="aria-label" htmlFor="aria-example-input">
//     Links to: </label>
// </div>
//   <div style={{ borderRadius: 2 }}>{destination}</div>
//   <ArrowDropDownIcon style={{ color: "black" }} />
// </Button>
// <Menu
//   id="basic-menu"
//   anchorEl={anchorEl}
//   open={open}
//   onClick={handleClose}
//   onClose={handleClose}
//   MenuListProps={{
//     'aria-labelledby': 'basic-button',
//   }}
//   style={{ zIndex: 1800 }}

// >
//   {Object.keys(artboards)
//     .filter((mapped) => mapped !== currentObjectArtboard)
//     .map((mappedItem, i) => (
//       <MenuItem key={i} value={mappedItem} onClick={() => handleSelect(mappedItem)}>{mappedItem}</MenuItem>
//     ))}

// </Menu>
// {destination !== '' && <DeleteIcon onClick={() => updateDestination({
//   id: id,
//   currentObjectArtboard: currentObjectArtboard,
//   destination: '',  
//   undo: false
// })} style={{
//   cursor: "pointer",
//   inline: true
// }} />}
// </div>