import React, { useState, useEffect } from 'react';
import Box  from "@mui/material/Box/";
import ModelDropdown from "./ModelDropdown";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import FileDirectory from "../FileDirectory.json";
import {useStore} from "../zustand/objects";


import { Button, Menu, Group, ActionIcon, rem, useMantineTheme } from '@mantine/core';

const AddModelButton = () => {
  const {setIsMenuOpen, isMenuOpen} = useStore()

  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(68);
  const [hover, setHover] = useState(false)

  useEffect(() => {
    show ? setDivWidth(149) : setDivWidth(68)
  })

  const ObjectCategories = Object.keys(FileDirectory);
  
  const ObjectCategoryArray = [...Object.keys(FileDirectory)]
  // const ObjectCategoryArray = [...Object.keys(FileDirectory), "text", "image"]

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShow(false);
    setShowText(false); 
    setIsMenuOpen(false)
  };

  // ADD TEXT TO END OF ARRAY AND THEN IN MODELDROPDOWN, HAVE TERNARY 
  // THAT ADDS AN EXTRA CUSTOM SELECT TO SELECT TEXT IF THE CATEGORY IS TEXT

  return (





<Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: "100%",
      justifyContent: 'flex-start',
      margin: "2.5px",
      paddingBottom: 0.5
    }}
    >
  
      <Menu closeOnItemClick={false} transitionProps={{ transition: 'pop' }}  withinPortal trigger="hover">
        <Menu.Target>
          <ActionIcon
            variant="transparent"
            color={!hover ? "#424242" : "#868e96"}
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
            size={'xs'}
          >
            <AddBoxOutlinedIcon fontSize='small'  />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
        {ObjectCategoryArray.map((mapped, i) => (
          <Menu.Item key={i}>
          <ModelDropdown category={mapped} />
          </Menu.Item>
        ))}
        </Menu.Dropdown>
      </Menu>

      </Box>

  );
}

export default AddModelButton;