import React, { useState, useEffect } from "react";
import { useStore, bringToArtboard } from "../zustand/objects";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// MANTINE
import classes from './BadgeCard.module.css';
import { Card, Button, Input, Text, Group, Badge, ActionIcon } from '@mantine/core';


import { Screenshot } from "../components/Screenshot";
import { isMobile } from 'react-device-detect';
import debounce from 'lodash.debounce';


const ArtboardPanel = ({ projectKey }) => {
  const {
    storyboardFields,
    artboards,
    removeArtboard,
    currentObjectArtboard,
    updateArtboardAndPreviewCamera,
    updateObjectSelected,
    addArtboardPositional,
    copyArtboardPositional,
    updateStoryboardFields,
    screenshotStatus,
    objectsAreLoaded,
    moveArtboard
  } = useStore();

  const bringUsersHere = () => {
    bringToArtboard(currentObjectArtboard)
  }

  const [artboardViewing, setArtboardViewing] = useState()
  const [editingName, setEditingName] = useState('')

  useEffect(() => {
    setArtboardViewing(currentObjectArtboard)
  })


  const handleChange = debounce((artboard, fieldType, value) => {

    updateStoryboardFields(artboard, fieldType, value)

  }, 500)


  const handleSubmit = (e) => { 
    e.preventDefault()
    e.target[0].blur()
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId && 
      destination.index === source.index
    ) {
      return
    }

    moveArtboard(source.index, destination.index)
  } 

  return <>
    <div style={{ width: "100%", marginTop:20 }}>
      <div>
        <Grid container direction="column" spacing={1} pb={0} mb={0}>
           <DragDropContext
           onDragEnd={onDragEnd}
      // {({ destination, source }) =>
      //   console.log(source,destination)}
    >
      <Droppable droppableId="artboard-list" direction="vertical">
      { (provided) => (
        <div 
        ref={provided.innerRef}
        {...provided.droppableProps}>
          {Object.keys(artboards).map((mapped, i) => (
            <div key={i} style={{width:"auto"}} >
            {/* <div key={i} style={{width:265}}> */}
          <Draggable key={i} index={i} draggableId={i.toString()}>
            {(provided) => (
              <div {...provided.draggableProps} ref={provided.innerRef}>
            <Grid key={i} item  ml={1} mb={1.5}>            
              <Card withBorder radius="md" p={0} style={{ borderRadius: 16, cursor: 'pointer' }}
                // marginLeft:artboardViewing === mapped ? 0 : 30}}
                shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                  
                <Group wrap="nowrap" gap={0}  >
                  <div {...provided.dragHandleProps} style={{position:"absolute", zIndex: 999999, }}>
                <Text c={currentObjectArtboard === mapped ? "#424242" : "#868e96"}><DragIndicatorIcon fontSize="small" /></Text>
                </div>
                  <div >
                    {objectsAreLoaded && (artboardViewing === mapped || screenshotStatus === true) ? <div style={{ width: 120, height: 85 }}>
                      {/* {artboardViewing === mapped ? <div style={{width:180,height: 102}}> */}
                      <Screenshot projectKey={projectKey} guestMode={false} artboard={mapped} /></div>
                      :
                      <div
                        style={{
                          width: 120, height: 85, borderRadius: 0, backgroundColor: '#C1C2C5'
                          // width: 130, height: 85, borderRadius: 0, backgroundColor: '#C1C2C5'
                        }}
                        onClick={() => {
                          updateObjectSelected('0')
                          updateArtboardAndPreviewCamera(mapped); 
                          setArtboardViewing(mapped);
                        }}
                        size="small" />}
                  </div>
                  <div className={classes.body} style={{ paddingLeft: 10 }}>
                    {editingName === mapped ?
                      <form onSubmit={(e) => handleSubmit(e)}>
                        <Input
                          style={{ width: 85 }}
                          type='text'
                          autoFocus
                          defaultValue={storyboardFields[mapped].name ? storyboardFields[mapped].name : 'Name'}
                          onChange={(e) => handleChange(mapped, 'name', e.target.value)}
                          onBlurCapture={() => setEditingName('')}

                        />
                        <Input type="submit" hidden />
                      </form>
                      :
                      <Text fw={currentObjectArtboard === mapped && 500} size={'xs'} 
                      c={currentObjectArtboard === mapped ? "#424242" : "#868e96"}
                      // c={currentObjectArtboard === mapped ? "black" : "#868e96"}
                        style={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: 'nowrap', marginBottom: 10, width: 95 }}
                        onClick={() => {
                          setEditingName(mapped)
                        }}>
                        {mapped}{storyboardFields[mapped].name ? ": " + storyboardFields[mapped].name : " Name"}
                      </Text>}
                    <Stack direction="row" >
                      <ActionIcon
                        onClick={() => {
                          addArtboardPositional(mapped)
                        }}
                        radius={32}
                        // color={currentObjectArtboard === mapped ? "black" : "#868e96"}
                        color={currentObjectArtboard === mapped ? "#424242" : "#868e96"}
                        size={16}
                        variant="filled"
                        style={{ marginRight: 10 }}>
                        <AddIcon fontSize="small" />
                      </ActionIcon>
                      <ActionIcon
                        onClick={() => {
                          copyArtboardPositional(mapped);
                        }}
                        // color={currentObjectArtboard === mapped ? "black" : "#868e96"}
                        color={currentObjectArtboard === mapped ? "#424242" : "#868e96"}
                        size={16}                       
                        variant="transparent"
                        style={{ marginRight: 10 }}>
                        <ContentCopyIcon fontSize="small" />
                      </ActionIcon>
                      <ActionIcon
                        disabled={currentObjectArtboard == mapped}
                        onClick={() => {
                          removeArtboard(mapped, false)
                        }}
                        // color={currentObjectArtboard === mapped ? "black" : "#868e96"}
                        color={currentObjectArtboard === mapped ? "#424242" : "#868e96"}
                        size={16}                        
                        variant="transparent"
                        style={{ marginRight: 10 }}>
                        <DeleteIcon fontSize="small" />
                      </ActionIcon>
                    </Stack>

                  </div>
                </Group>
              </Card>
            </Grid>
            </div>)}
            </Draggable></div>
          ))}
          {provided.placeholder}
          </div>

       )}
          </Droppable>
          </DragDropContext> 
        </Grid></div>

    </div>
  </>;
};

export default ArtboardPanel;















