import React, { useState, useEffect } from "react";
import { useStore } from "../zustand/objects";
import Button from '@mui/material/Button';
import LanguageIcon from '@mui/icons-material/Language';
import VisibilityIcon from '@mui/icons-material/Visibility';

const SwitchToPreviewButton = () => {
  const {
    previewVR,
    switchVRMode
  } = useStore();

  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(50);

  useEffect(() => {
    show ? setDivWidth(180) : setDivWidth(60)
  })

  const handleClose = () => {
    setShow(false);
    setShowText(false);
  };

  return (
    
      <Button
        size={"small"}
        startIcon={previewVR ? <LanguageIcon /> : <VisibilityIcon />}
        style={{ borderRadius: 0, height: "100%", backgroundColor: "none", boxShadow: "none", color: 'black' }}
        onClick={() => {
          switchVRMode();
        }}
      >
      </Button>

  );
};

export default SwitchToPreviewButton;
