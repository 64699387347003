import React, { Suspense, useState, useEffect } from "react";
import { useStore } from "../zustand/objects";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

import { getSpecificUploadModel, getSpecificAIModel } from "../api"

import {AIModelObject} from "./Model";
import {UploadedModelObject} from "./Model";

import ModelObject from "./ModelObject"

import {Button, ButtonTexture} from "./ModelFiles/UI/Button"
import {CurvedPanels, CurvedPanelsTexture} from "./ModelFiles/UI/CurvedPanels"
import {FlatPanels, FlatPanelsTexture} from "./ModelFiles/UI/FlatPanels"
import Slider from "./ModelFiles/UI/Slider"
import {StackedSidebar, StackedSidebarTexture} from "./ModelFiles/UI/StackedSidebar"
import Toggle from "./ModelFiles/UI/Toggle"
import UpwardsFacingPanel from "./ModelFiles/UI/UpwardsFacingPanel"
import {RoundedWindow, RoundedWindowTexture} from "./ModelFiles/UI/RoundedWindow"
import {RoundedTabBar,RoundedTabBarTexture} from "./ModelFiles/UI/RoundedTabBar"
import {RoundedToolbar, RoundedToolbarTexture} from "./ModelFiles/UI/RoundedToolbar"
import {TabBar,TabBarTexture} from "./ModelFiles/UI/TabBar"
import {Toolbar, ToolbarTexture} from "./ModelFiles/UI/Toolbar"
import {Window, WindowTexture} from "./ModelFiles/UI/Window"
import {AppleUI, BackgroundUI, MicrosoftUI, FloatgridsLayout, FloatgridsUI} from "./ModelFiles/UI/AppleUI"

import { ConeShape, ConeShapeTexture } from "./ModelFiles/Shape/ConeShape";
import { CubeShape, CubeShapeTexture } from "./ModelFiles/Shape/CubeShape";
import { CylinderShape, CylinderShapeTexture } from "./ModelFiles/Shape/CylinderShape";
import { RoundedBoxShape, RoundedBoxShapeTexture } from "./ModelFiles/Shape/RoundedBoxShape";
import { SphereShape, SphereShapeTexture } from "./ModelFiles/Shape/SphereShape";



export const PreviewModel = ({ destination, object, colour, pose, category, imageTexture, matrixState, curved, curveAmount, modelPath }) => {

  const { shapeHoverChange, updateArtboard, updateArtboardAndPreviewCamera, objectsAreLoaded, previewCameraPosition, artboards, currentObjectArtboard } = useStore();
  const { camera } = useThree()

  const components = {
    apple: AppleUI,
    background: BackgroundUI,
    microsoft: MicrosoftUI,
    floatgridsUi: FloatgridsUI,
    floatgridsLayout: FloatgridsLayout,
    cone: ConeShape,
    cube: CubeShape,
    cylinder: CylinderShape,
    roundedBox: RoundedBoxShape,
    sphere: SphereShape
  };

  // Correct! JSX type can be a capitalized variable.
  const SpecificStory = components[object] ? components[object] : null;


  const passdownFunctionPreview = () => {
    destination && shapeHoverChange(false)
    destination && updateArtboard(destination)
  }

  const hoverShape = (e) => {
    shapeHoverChange(e)
  }
// declare empty position, rotation and scale for .decompose()
const pos = new THREE.Vector3()
const rot = new THREE.Quaternion()
const sca = new THREE.Vector3()

// get local copy of matrixState
const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  newMatrix.decompose(pos, rot, sca)


  if (!objectsAreLoaded) {
    return
  } else {
    return (
      <>
        <Suspense fallback={null}>
          <group
            onPointerOver={() => destination && hoverShape(true)} onPointerOut={() => destination && hoverShape(false)}
            scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
            position={new THREE.Vector3().setFromMatrixPosition(matrixState)}
            rotation={new THREE.Euler().setFromQuaternion(rot)}
          >
            {(category === "shape" || category === "apple" || category === "background" || category === "microsoft" || category === "floatgridsUi" || category === "floatgridsLayout") ?  SpecificStory && <SpecificStory story={object} destination={destination} clickFunction={passdownFunctionPreview} colour={colour} pose={pose} curved={curved} curveAmount={curveAmount} imageTexture={imageTexture} />
            : <ModelObject modelPath={modelPath} story={object} destination={destination} clickFunction={passdownFunctionPreview} colour={colour} pose={pose} curved={curved} curveAmount={curveAmount} imageTexture={imageTexture} />}
          </group>
        </Suspense>

      </>
    )
  };
}; 


//UIModelTexture
export const UIModelTexture = ({ position, rotation, scale, destination, object, colour, pose, id, imageTexture, matrixState, curved, curveAmount }) => {

  const { shapeHoverChange, artboards, updateArtboard, updateArtboardAndPreviewCamera, objectsAreLoaded } = useStore();
  const { camera } = useThree()

  const components = {
    button: ButtonTexture,
    curvedPanels: CurvedPanelsTexture,
    flatPanels: FlatPanelsTexture,
    stackedSidebar: StackedSidebarTexture,
    roundedWindow: RoundedWindow,
        roundedTabBar: RoundedTabBarTexture,
        roundedToolbar: RoundedToolbarTexture,
        tabBar: TabBarTexture,
        toolBar: ToolbarTexture,
        window: WindowTexture
};


  // Correct! JSX type can be a capitalized variable.
  const SpecificStory = components[object];


  const passdownFunctionPreview = () => {
    destination && shapeHoverChange(false)
    destination && updateArtboard(destination)
  }

  const hoverShape = (e) => {
    shapeHoverChange(e)
  }
  // declare empty position, rotation and scale for .decompose()
  const pos = new THREE.Vector3()
  const rot = new THREE.Quaternion()
  const sca = new THREE.Vector3()

  // get local copy of matrixState
  const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  newMatrix.decompose(pos, rot, sca)

  return (
    <> 
      <Suspense fallback={null}>
        <group onPointerOver={() => destination && hoverShape(true)} onPointerOut={() => destination && hoverShape(false)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          position={new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={new THREE.Euler().setFromQuaternion(rot)}>
          <SpecificStory story={object} destination={destination} clickFunction={passdownFunctionPreview} colour={colour} pose={pose} imageTexture={imageTexture} curved={curved} curveAmount={curveAmount}  />
        </group>
      </Suspense>

    </>
  );
}; 



// SHAPETEXTURE
export const ShapeTexture = ({ position, rotation, scale, destination, object, colour, pose, id, imageTexture, matrixState }) => {

  const { shapeHoverChange, artboards, updateArtboard, updateArtboardAndPreviewCamera, objectsAreLoaded } = useStore();
  const { camera } = useThree()

  const components = {
    cone: ConeShapeTexture,
    cube: CubeShapeTexture,
    cylinder: CylinderShapeTexture,
    roundedBox: RoundedBoxShapeTexture,
    sphere: SphereShapeTexture
  };

  // Correct! JSX type can be a capitalized variable.
  const SpecificStory = components[object];


  const passdownFunctionPreview = () => {
    destination && shapeHoverChange(false)
    destination && updateArtboard(destination)
  }

  const hoverShape = (e) => {
    shapeHoverChange(e)
  }
  // declare empty position, rotation and scale for .decompose()
  const pos = new THREE.Vector3()
  const rot = new THREE.Quaternion()
  const sca = new THREE.Vector3()

  // get local copy of matrixState
  const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  newMatrix.decompose(pos, rot, sca)

  return (
    <>
      <Suspense fallback={null}>
        <group onPointerOver={() => destination && hoverShape(true)} onPointerOut={() => destination && hoverShape(false)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          position={new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={new THREE.Euler().setFromQuaternion(rot)}>
          <SpecificStory story={object} destination={destination} clickFunction={passdownFunctionPreview} colour={colour} pose={pose} imageTexture={imageTexture} />
        </group>
      </Suspense>

    </>
  );
};



export const PreviewAIModel = ({ databaseId, databaseURL, projectKey, guestMode, prompt, position, rotation, scale, destination, object, colour, pose, id, imageTexture, aiKey, matrixState }) => {

  const { shapeHoverChange, artboards, updateArtboard, updateArtboardAndPreviewCamera, objectsAreLoaded } = useStore();
  const { camera } = useThree()

  const [hide, setHide] = useState(true)

  //if model isn't found then we throw a hide state and dont try and load it
  const checkResult = (val) => {
    val === 404 ? setHide(true) :
    val.model.url === databaseURL ? 
    val.id === databaseId ? setHide(false) : setHide(true) 
    : setHide(true)
  }

  //check if we can/can't match model url and id in database 
  useEffect(() => {
    getSpecificAIModel(databaseId).then(res => checkResult(res))
  },[])



  const passdownFunctionPreview = () => {
    destination && shapeHoverChange(false)
    destination && updateArtboard(destination)
  }

  const hoverShape = (e) => {
    shapeHoverChange(e)
  }
  // declare empty position, rotation and scale for .decompose()
  const pos = new THREE.Vector3()
  const rot = new THREE.Quaternion()
  const sca = new THREE.Vector3()

  // get local copy of matrixState
  const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  newMatrix.decompose(pos, rot, sca)

  // console.log(aiKey)

  if (!objectsAreLoaded) {
    return <div></div>;
  } else {
    return (
      <>
        <Suspense fallback={null}>
          <group onPointerOver={() => destination && hoverShape(true)} onPointerOut={() => destination && hoverShape(false)}
            scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
            position={new THREE.Vector3().setFromMatrixPosition(matrixState)}
            rotation={new THREE.Euler().setFromQuaternion(rot)}
            onClick={(e) => {e.stopPropagation() 
              passdownFunctionPreview()}}>
            {!hide && <AIModelObject databaseURL={databaseURL} projectKey={projectKey} guestMode={guestMode} destination={destination} clickFunction={passdownFunctionPreview} colour={colour} pose={pose} scale={scale} prompt={object} />}
          </group>
        </Suspense>

      </>
    );
  }
};



export const PreviewUploadedModel = ({ uploadedURL, uploadId, destination, matrixState }) => {

  const { shapeHoverChange, updateArtboard, updateArtboardAndPreviewCamera, objectsAreLoaded, previewCameraPosition, artboards, currentObjectArtboard } = useStore();
  const { camera } = useThree()
 


  const [hide, setHide] = useState(true)

  //if model isn't found then we throw a hide state and dont try and load it
  const checkResult = (val) => {
    val === 404 ? setHide(true) :
    val.model.url === uploadedURL ? 
    val.id === uploadId ? setHide(false) : setHide(true) 
    : setHide(true)
  }

  //check if we can/can't match model url and id in database 
  useEffect(() => {
    // console.log(uploadId)
    getSpecificUploadModel(uploadId).then(res => checkResult(res))
  },[])

  const passdownFunctionPreview = () => {
    destination && shapeHoverChange(false)
    destination && updateArtboard(destination)
  }

  const hoverShape = (e) => {
    shapeHoverChange(e)
  }
// declare empty position, rotation and scale for .decompose()
const pos = new THREE.Vector3()
const rot = new THREE.Quaternion()
const sca = new THREE.Vector3()

// get local copy of matrixState
const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  newMatrix.decompose(pos, rot, sca)


  if (!objectsAreLoaded) {
    return
  } else {
    return (
      <>
        <Suspense fallback={null}>
          <group
            onPointerOver={() => destination && hoverShape(true)} onPointerOut={() => destination && hoverShape(false)}
            scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
            position={new THREE.Vector3().setFromMatrixPosition(matrixState)}
            rotation={new THREE.Euler().setFromQuaternion(rot)}
            onClick={(e) => {e.stopPropagation() 
              passdownFunctionPreview()}}> 
         {!hide && <UploadedModelObject uploadedURL={uploadedURL} />}
          </group>
        </Suspense>

      </>
    )
  };
};