import React, { useState, useEffect } from "react";
import { useStore } from "../zustand/objects";
import ShareIcon from '@mui/icons-material/Share';
import ShareModal from './ShareModal';

import {Box} from "@mui/material/";
import { ActionIcon } from '@mantine/core';

 
const SharePreviewButton = ({projectKey}) => {

  const {
    switchShareModalOpen
  } = useStore();

  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(50);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    show ? setDivWidth(90) : setDivWidth(60)
  })

  const handleClose = () => {
    setShow(false);
    setShowText(false);
  };

  return (<>

<Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: "100%",
      justifyContent: 'flex-start',
      margin: "2.5px",
      paddingBottom: 0.5
    }}
    >
  
      <ActionIcon
        size={"xs"}
        variant='transparent'
        color={!hover ? "#424242" : "#868e96"}
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
        onClick={() => {
          switchShareModalOpen()
        }}>
        <ShareIcon fontSize="small" />
      </ActionIcon>
</Box>

    <ShareModal projectKey={projectKey} />
</>
  );
};

export default SharePreviewButton;
