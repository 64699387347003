import React, { useEffect, useState, Suspense } from "react";

import { Link, useParams } from "react-router-dom";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';

import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { saveAs } from "file-saver";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { OBJExporter } from "three/examples/jsm/exporters/OBJExporter";
import { useStore } from "../zustand/objects";
import { getSpecificUserProjectGuestAccessAPI } from "../api";
import AppNavBar from "../components/AppNavBar";
import StoryboardTextField from "../components/StoryboardTextField"
import DownloaderFile from "../components/DownloaderFile";
import { Screenshot, ScreenshotStoryboardPOV } from "../components/Screenshot";
import Tooltip from '@mui/material/Tooltip';
import ModelAttributions from "../ModelAttributions.json";
import ViewAttribution from "../components/ViewAttribution";

import { Card, Button,  Image, Input, Textarea, Text, Group, Badge, ActionIcon } from '@mantine/core';


export default function PreviewStoryboardPage() {
  const { getAIModels, objectsAreLoaded, exportData, artboards, getSpecificUserProject, getSpecificUserProjectGuest, projectName, loadObjects, unloadObjects } = useStore();


  // STORYBOARD POV  NOT WORKING ON STORYBOARDS YET

  const {
    liveblocks: { enterRoom, leaveRoom },
    currentObjectArtboard
  } = useStore();

  useEffect(() => {
    enterRoom(`matchboxxr-editor${projectKey}`);
    return () => {
      leaveRoom(`matchboxxr-editor${projectKey}`);
    };
  }, [enterRoom, leaveRoom]);




  const { projectKey } = useParams();


  const [open, setOpen] = useState(false);

  const handleClose = () => { 
    setOpen(false);
  }


  async function unloadProject() {
    unloadObjects()
  }   

  const [guest, setGuest] = useState(false)

  useEffect(() => {
    unloadProject()
      .then(result =>
        getSpecificUserProjectGuestAccessAPI(projectKey)
      )
      .then((response) => (response === true) ?
        getSpecificUserProjectGuest(projectKey).then(res => setGuest(true))
        :
        getSpecificUserProject(projectKey).then(() => 
        getAIModels())
          .then(response =>
            loadObjects(),
          ))
  }, []); 

  const GridBackground = () => {
    return (
      <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "120%", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

    )
  } 

  return (<>
    <Suspense fallback={<h1>Loading...</h1>}>
      <CssBaseline />
      <GridBackground />
      <AppNavBar pageName={projectName} position="relative">
        <Typography variant="h6" color="inherit" noWrap>
          View prototype storyboard
        </Typography>
      </AppNavBar>


      <Box
        sx={{
          pt: 8,
          pl: 0
        }}
      >
        <Container sx={{ ml: 7, pl: 0 }}>
         

          <Grid container sx={{ pl: 0, mb: 2 }}>
            <Grid item xs={12} md={7} sx={{ pl: 0 }}>
              <Typography
                component="h1"
                variant="h4"
                fontWeight="700"
                // align="center"
                color="text.primary"
                gutterBottom
                sx={{ pl: 0 }}
              >
                View prototype storyboard
              </Typography>
            </Grid>
            
            <Grid item xs={12} md={5} sx={{ pl: 0 }}>
              <Stack
                sx={{ pt: 1, display: 'flex', justifyContent: 'flex-end' }}
                direction="row"
                spacing={2}
              >
                {/* <Button variant={'outlined'} style={{ height: "100%", padding: 4, backgroundColor: "white", boxShadow: "none", color: 'black', borderColor: 'black', borderRadius: 0, borderWidth: 1.5 }} onClick={() => handleOpen()} >Export Scenes</Button> */}
                {/* {objectsAreLoaded &&  <ViewAttribution />} */}
                <Button onClick={() => unloadObjects()}
                  size="small" variant="contained"
                  style={{ backgroundColor: "#FAC710", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset", padding: 4 }}
                  component={Link}
                  to={`/share/${projectKey}?artboard=${currentObjectArtboard}`}>View Preview</Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box> 

      <Container sx={{ pb: 0, pt: 2, ml: 7, pl: 0 }} >
        <Grid container spacing={4}>
          {objectsAreLoaded && Object.keys(artboards).map((mapped, i) => (
            <Suspense key={i} fallback={<h1>Loading...</h1>}>
              <Grid item  xs={12} sm={6} md={3}>
              <Card radius={16} padding={0} style={{margin:10}}
                                        shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                <Screenshot projectKey={projectKey} guestMode={guest} artboard={mapped} />
                <CardContent sx={{ flexGrow: 1, pb:0 }}>
                  <Typography gutterBottom variant="h6" component="h6">
                    Scene: {mapped}
                  </Typography>
                  <Tooltip title="This is preview mode. Scene info is written in edit mode." placement="bottom-start">
                      <span>
                        <StoryboardTextField fieldType="name" isEditable={false} artboard={mapped} />
                      </span></Tooltip>
                    <Tooltip title="This is preview mode. Scene info is written in edit mode." placement="bottom-start">
                      <span><StoryboardTextField fieldType="description" isEditable={false} artboard={mapped} />
                      </span></Tooltip>
                    <StoryboardTextField fieldType="comments" isEditable={true} artboard={mapped} />
                </CardContent>
                <Group style={{margin:10}}>
                  <Button
                    onClick={() => unloadObjects()}
                    style={{
                      width: '45%', inline: true, backgroundColor: 'black', borderRadius: 32
                    }}
                    component='a'
                    href={`/share/${projectKey}?artboard=${mapped}`}>
                    View</Button>
                </Group>
              </Card>




               


                
              </Grid>
              </Suspense>
          ))}
        </Grid>
      </Container>

    </Suspense>
  </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
