import React, { useEffect, useCallback, Suspense, useState } from "react";
import debounce from 'lodash.debounce';
import { Link, useParams } from "react-router-dom";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';
import CssBaseline from '@mui/material/CssBaseline'; 
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import { saveAs } from "file-saver";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { OBJExporter } from "three/examples/jsm/exporters/OBJExporter";
 
import DownloaderFile from "../components/DownloaderFile";
import { useStore } from "../zustand/objects";
import AppNavBar from "../components/AppNavBar";
import StoryboardTextField from "../components/StoryboardTextField"
import { Screenshot } from "../components/Screenshot";
import Tooltip from '@mui/material/Tooltip';
import ModelAttributions from "../ModelAttributions.json";
import ViewAttribution from "../components/ViewAttribution";
import { saveUserScreenshotsAPI } from "../api.js";

import { Card, Button,  Image, Input, Textarea, Text, Group, Badge, ActionIcon } from '@mantine/core';


const theme = createTheme();

export default function EditorStoryboardPage({ match }) {
  const { objectsAreLoaded, unloadObjects, loadObjects, exportData, artboards, getSpecificUserProject, projectName, updateProjectName, projectUserName, updateProjectUserName, screenshots, updateProjectUserActivity, projectUserOutcome, updateProjectUserOutcome, getAIModels } = useStore();



  const {
    liveblocks: { enterRoom, leaveRoom }
  } = useStore();

  useEffect(() => {
    enterRoom(`matchboxxr-editor${projectKey}`);
    return () => {
      leaveRoom(`matchboxxr-editor${projectKey}`);
    };
  }, [enterRoom, leaveRoom]);


  // const handleDownloadGLTFClick = () => {

  //   exportData.map(mapped => { 
  //     const exporter = new GLTFExporter();
  //     exporter.parse(mapped,
  //       function (gltf) {
  //         const objectsArray = artboards[[exportData.indexOf(mapped) + 1]].map(object => (object.id !== "camera" && object.category !== "aiModel" && object.category !== "shape" && object.category !== "image" && object.category !== "text" && object.category !== "UI" && object.category !== "uploaded" && object.category !== "apple" && object.category !== "microsoft" && object.category !== "floatgridsUi" && object.category !== "floatgridsLayout"
  //           &&
  //           `\n
  //         model name: ${object.object},
  //         model creator: ${ModelAttributions[object.object].creator},
  //         model copyright: ${ModelAttributions[object.object].copyright},
  //         model license: ${ModelAttributions[object.object].license},
  //         model disclaimer: ${ModelAttributions[object.object].disclaimer},
  //         link to original model: ${ModelAttributions[object.object].linkToOriginal}
  //         \n`
  //         ))
  //         const thingy = new Blob(objectsArray, { type: "text/plain;charset=utf-8" })
  //         saveAs(thingy, `${projectName} scene ${exportData.indexOf(mapped) + 1} creator attribution.txt`)
  //         saveAs(new Blob([JSON.stringify(gltf)], { type: 'application/json' }), `scene${exportData.indexOf(mapped) + 1}.gltf`)
  //       },
  //       // called when there is an error in the generation
  //       function (error) {
  //         // console.log('An error happened ' + error);
  //       });
  //   })
  // }

  // const handleDownloadOBJClick = () => {

  //   exportData.map(mapped => {
  //     const exporter = new OBJExporter();
  //     const data = exporter.parse(mapped);

  //     const objectsArray = artboards[[exportData.indexOf(mapped) + 1]].map(object => (object.id !== "camera" && object.category !== "aiModel" && object.category !== "shape" && object.category !== "image" && object.category !== "text"  && object.category !== "UI" && object.category !== "uploaded" && object.category !== "apple" && object.category !== "microsoft"  && object.category !== "floatgridsUi" && object.category !== "floatgridsLayout"
  //       &&
  //       `\n
  //   model name: ${object.object},
  //   model creator: ${ModelAttributions[object.object].creator},
  //   model copyright: ${ModelAttributions[object.object].copyright},
  //   model license: ${ModelAttributions[object.object].license},
  //   model disclaimer: ${ModelAttributions[object.object].disclaimer},
  //   link to original model: ${ModelAttributions[object.object].linkToOriginal}
  //   \n`
  //     ))
  //     const thingy = new Blob(objectsArray, { type: "text/plain;charset=utf-8" })

  //     saveAs(thingy, `${projectName} scene ${exportData.indexOf(mapped) + 1} creator attribution.txt`)
  //     saveAs(new Blob([data], { type: 'text/plain' }), `scene${exportData.indexOf(mapped) + 1}.obj`)
  //   })
  // }
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  const { projectKey } = useParams();

  async function unloadProject() {
    unloadObjects()
  }

  
  useEffect(() => {
    unloadProject().then(() => 
    getSpecificUserProject(projectKey)).then(() => 
    getAIModels())
    .then(result => loadObjects())
    
    // .then(result => saveScreenshots())

  }, []); // <-- empty dependency array

  // useEffect(() => {
  //   unloadProject()
  //   .then(result => 
  //     getSpecificUserProject(projectKey)).then(() => 
  //     getAIModels())
  //   .then(result => loadObjects())
  // }, []); // <-- empty dependency array

  const handleSubmitProjectName = useCallback(debounce((val) => {
    updateProjectName(val)
  }, 500))
  const handleSubmitProjectUserName = useCallback(debounce((val) => {
    updateProjectUserName(val)
  }, 500))
  const handleSubmitProjectUserActivity = useCallback(debounce((val) => {
    updateProjectUserActivity(val)
  }, 500))
  const handleSubmitProjectUserOutcome = useCallback(debounce((val) => {
    updateProjectUserOutcome(val)
  }, 500))


  const GridBackground = () => {
    return (
      <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "120%", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

    )
  }

  // SAVE PROJECT SCREENSHOTS ONLY
// const saveUserScreenshotsAPI = (
//   data
// ) => {
//   return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project/' + projectKey, {
//     method: 'POST',
//     headers: {
//       "content-type": "application/json",
//       "Authorization": `Bearer ${localStorage.getItem('user')}`
//     },
//     body: data
//   })
//     .then((response) => response.json())
//     .then((data) => {
//       return data
//     })
//     .catch((error) => {
//       console.error("Error:", error);
//     });
// }

 

const saveScreenshots = () => {
  const screenshotArray = Object.values(screenshots)

  saveUserScreenshotsAPI({projectKey:projectKey, screenshots: [...screenshotArray]})
}



  return (<>
    <CssBaseline />
    <GridBackground />
    <AppNavBar pageName={projectName} position="relative">
      <Typography variant="h6" color="inherit" noWrap>
        Edit or share your storyboard
      </Typography>
    </AppNavBar>
    <Box
      sx={{
        pt: 8,
        pl: 0
      }}
    >
      <Container sx={{ ml: 7, pl: 0 }}>
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Export this project
            </Typography>
            {Object.keys(artboards).map((mapped, i) => (<DownloaderFile key={i} artboard={mapped} />))}
            <button onClick={() => handleDownloadOBJClick()}>Download in .OBJ format</button>
            <button onClick={() => handleDownloadGLTFClick()}>Download in .GLTF format</button>
          </Box>
        </Modal> */}

        <Grid container sx={{ pl: 0, mb:2 }}>
          <Grid item xs={12} md={7} sx={{pl:0}}>
            <Typography
              component="h1"
              variant="h4"
              fontWeight="700"
              // align="center"
              color="text.primary"
              gutterBottom
              sx={{pl:0}}
            >
              Edit or share your storyboard
            </Typography>
          </Grid>
         
          <Grid item xs={12} md={5} sx={{pl:0}}>
            <Stack
              sx={{ pt: 1, display: 'flex', justifyContent: 'flex-end' }}
              direction="row" 
              spacing={2}
            >
              {/* <Button onClick={() => saveScreenshots()} 
                size="small" variant="contained"
                style={{ backgroundColor: "#FAC710", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset", padding:4}}>
                Sync screenshots</Button> */}
              {/* <ViewAttribution /> */}
              <Button onClick={() => navigator.clipboard.writeText(`https://mbxr.io/story/${projectKey}`)} 
                size="small" variant="contained"
                style={{ backgroundColor: "#FAC710", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset", padding:4}}>
                Copy Preview Link</Button>
            </Stack>
          </Grid>
        </Grid>
      </Container> 
    </Box>
    
    <Container sx={{ pb: 0, pt: 2, ml: 7, pl: 0 }} >
      <Grid container spacing={2}>
        <Suspense fallback={<img src="/image/loading.gif" style={{ width: "50px", height: "50px" }} />}>
          {objectsAreLoaded && Object.keys(artboards).map((mapped) => (
            <Grid item key={mapped} xs={12} sm={6} md={3}>
               <Card radius={16} padding={0} style={{margin:10}}
                                        shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                <Screenshot projectKey={projectKey} guestMode={false} artboard={mapped} />
                <CardContent sx={{ flexGrow: 1, pb:0 }}>
                  <Typography gutterBottom variant="h6" component="h6">
                    Scene: {mapped}
                  </Typography>
                  <StoryboardTextField fieldType="name" isEditable={true} artboard={mapped} />

                  <StoryboardTextField fieldType="description" isEditable={true} artboard={mapped} />

                  <Suspense fallback={null}>
                    <Tooltip title="This is edit mode. Comments are written in preview mode." placement="bottom-start">
                      <span>
                        <StoryboardTextField fieldType="comments" isEditable={false} artboard={mapped} />
                      </span>
                    </Tooltip>
                  </Suspense>
                </CardContent>
                <Group style={{margin:10}}>
                  <Button
                    onClick={() => unloadObjects()}
                    style={{
                      width: '45%', inline: true, backgroundColor: 'black', borderRadius: 32
                    }}
                    component='a'
                    href={`/preview/${projectKey}?artboard=${mapped}`}>
                    View</Button>
                  <Button
                    style={{
                      width: '45%', inline: true, backgroundColor: 'black', borderRadius: 32
                    }}
                    component='a'
                    href={`/editor/${projectKey}?artboard=${mapped}`}>
                    Edit</Button>
                </Group>
              </Card>
            </Grid>
          ))}</Suspense>
      </Grid>
    </Container>
  </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};