import React, { useState, useEffect, Suspense } from 'react';
import { useStore } from "../zustand/objects";
import { Grid, Modal } from "@mui/material/";
import DeleteIcon from "@mui/icons-material/Delete"

import { LoadingOverlay, Card, Button, Text, Group, ActionIcon, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import {getUserAIQuota, getUserAIModels, deleteSpecificAIModel, sendAIModelPrompt} from "../api.js"

export function AIModal({ open, close }) {
  
  const {
    currentObjectArtboard,
    addObject
  } = useStore();

const [readyToMap, setReadyToMap] = useState(false)
const [myModels, setMyModels] = useState([])
const [myQuota, setMyQuota] = useState(0)
const [quotaAlert, setQuotaAlert] = useState(false)
const [error, setError] = useState(null)
const [loading, setLoading] = useState(true)

const retrieveModels = async () => {
  setLoading(true)
  getUserAIModels().then(e => setMyModels(e)).then(e => setLoading(false))
  setError(null)
}

const handleDelete = (id) => {
  deleteSpecificAIModel(id).then(e => e === "success" && retrieveModels().then(e => setLoading(false)))
}

const handleError = (error) => {
setError(error)
setLoading(false)
}

const handleSubmit = (prompt) => {
  setLoading(true)
  sendAIModelPrompt(prompt).then(e => e === "success" ? 
  retrieveModels().then(() => getQuota().then(() => setLoading(false))) :
  handleError(e)
  )
}

const getQuota = async () => {
  getUserAIQuota().then(e => setMyQuota(e))
}

const checkQuota = async () => {
  myQuota > 0 ? setQuotaAlert(false) : setQuotaAlert(true)
}

useEffect(() => {
  checkQuota()
}, [myQuota])

  useEffect(() => {
    
    open && retrieveModels()
    // open && retrieveModels().then(e => setLoading(false))
    open && getQuota()
  }, [open])



  const form = useForm({
    initialValues: {
      prompt: ''
    },
    validate: {
      prompt: (value) => value.length > 0 ? null : 'Empty prompt',
    },
  });

//  add it to scene 
const handleSelect = (e) => {
  // console.log(e)
  addObject({
    currentObjectArtboard,
    category: 'ai',
    object: e.prompt, 
    undo: false,
    id: null,
    position: null,
    scale: null,
    rotation: null,
    matrix: null,
    pose: {
      name: e.prompt, 
      modelPath: e.model.path,
    },
    imageTexture: null, 
    image: null,
    curved: false,
    curvedAmount: 3,
    bGColour: null,
    databaseURL: e.model.url,
    databaseId: e.id
  })
  } 
    
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title" 
      aria-describedby="modal-modal-description"
    >
      
      <Card style={{borderRadius:32, width: 500, padding:0,
        height: 420,  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
    <LoadingOverlay loaderProps={{ color: 'black' }} visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <Grid container style={{ width:'100%', padding:20, margin:0, height:420, overflow:'scroll', backgroundImage: 'url("/image/background-grid.png")' }}>

          {myModels.map((model, i) => (

            <Grid key={i} item xs={4} style={{ marginTop: 5 }}>



              {model.thumbnail ? <div style={{ height: "100px", overflow: "hidden", margin: 5 }}>
                <img alt={'ai model'} style={{ width: "100%" }} src={model.thumbnail.url} />
              </div> : <div style={{ height: "100px", overflow: "hidden", margin: 5, backgroundColor: "#2d9bf0" }}>
              </div>}
                <Text fw={500}>{model.prompt}</Text>
                <Group style={{marginTop: 5}}>
                  <Button onClick={() => handleSelect(model)}
                    style={{
                      height: 20, paddingTop: 1, paddingBottom: 1, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
                    }}>
                    Add
                  </Button>
                  <ActionIcon variant="transparent" color="black" size={'xs'}
                  onClick={() => handleDelete(model.id)}
                  >
                    <DeleteIcon />
                  </ActionIcon></Group>
            </Grid>
          ))}         
        </Grid>
        <Grid container style={{padding:20}}>
        <Grid item  xs={12}>
        <Text size="xl" fw={700}>Generate a model using AI</Text>
        <Grid item  xs={12}>
        <form onSubmit={form.onSubmit((values) => !quotaAlert && handleSubmit(values.prompt))}>
        <Group justify="flex-start">
        <TextInput
          disabled={quotaAlert}
          withAsterisk
          label="Prompt"
          placeholder="Your shape prompt"
          {...form.getInputProps('prompt')}
        />
          <Button 
          disabled={quotaAlert}
          style={{
             paddingTop: 1, paddingBottom: 1, marginTop: 25, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
          }}
           type="submit">Submit</Button>
        </Group>
      </form>
          </Grid>
        
       <Group style={{marginTop:10}}>
        {error === null ? '' : <Text size="md" fw={400} color='red'>{error}</Text>}
        <Text size="md" fw={400}>Requests take 1-2 minutes. {myQuota}/10 monthly requests left.</Text>
        </Group>
        </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

