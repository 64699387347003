import React, { useRef, Suspense, useState, useEffect } from "react";
import { useStore } from "../zustand/objects";
import PreviewSplash from "./PreviewSplash";
import Cursor from "./Cursor";
import PlayerFigure from "./PlayerFigure";
import { ChangePreviewScene } from "./ChangePreviewScene";
import { CaptureHeadset, CaptureCamera } from "./CaptureHeadset";
import { PreviewObjectsMapped, VRPreviewObjectsMapped } from "./PreviewObjectsMapped";
import { TeleportFloorGrid, VRTeleportFloorGrid, ChangeSceneDesktop } from './TeleportFloorGrid'
import "../dot.css";

import { Card, Button, Text, Group, FileButton, ActionIcon, Center } from '@mantine/core';


import { Canvas, useThree } from "@react-three/fiber";
import { PointerLockControls, Sky, CubicBezierLine } from "@react-three/drei";
import { XR, Controllers, Hands, VRButton, useXREvent, useXR, TeleportationPlane } from "@react-three/xr";


const PreviewViewport = ({ projectKey, artboards, guestMode }) => {

  const [previewMode, setPreviewMode] = useState('desktop')
  const [modeSelectVisible, setModeSelectVisible] = useState(true)

  const canvasRef = useRef();
  const vRCanvasRef = useRef();
  const floorRef = useRef();


  const {
    teleportCount,
    previewCameraPosition,
    floorColour,
    objectsAreLoaded,
    currentObjectArtboard
  } = useStore();



  // - - - - websockets
  // used to be from liveblocksStore
  // useStore((state) => state.liveblocks.others);
  const others = useStore((state) => state.liveblocks.others);

  // const {
  //   liveblocks: { enterRoom, leaveRoom },
  // } = useStore();


  // useEffect(() => {
  //   enterRoom(`matchboxxr-editor${projectKey}`);
  //   return () => {
  //     leaveRoom(`matchboxxr-editor${projectKey}`);
  //   };

  // }, [enterRoom, leaveRoom]);

  const COLORS = [
    "#E57373",
    "#9575CD",
    "#4FC3F7",
    "#81C784",
    "#FFF176",
    "#FF8A65",
    "#F06292",
    "#7986CB",
  ];

  // - - - -  cursors end


  const controlsRef = useRef();

  const handleClick = (val) => {
    setModeSelectVisible(false)
    setPreviewMode(val)
  }

  const [showTiles, setShowTiles] = useState(false);

  let timer

  const handleLongPress = () => {
    timer = setTimeout(() => setShowTiles(true), 900)
  }

  const handleButtonRelease = () => {
    clearTimeout(timer)
    setShowTiles(false)
  }
  document.addEventListener("pointerdown", handleLongPress)
  document.addEventListener("pointerup", handleButtonRelease)

  function VRPreviewCameraFunction() {
    // const { session, player } = useXR()
    // if (session) {  
    //   if (teleportCount < 1) {
    //   player.position.x = previewCameraPosition[0]
    //   player.position.y = 0
    //   player.position.z = previewCameraPosition[2]}
    // } 
    useXREvent('selectstart', (event) => handleLongPress())
    useXREvent('selectend', (event) => handleButtonRelease())
  }



  if (previewMode === "desktop") {
    return <>
      <Canvas
        ref={canvasRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        camera={{
          position: [previewCameraPosition[0], 1.75, previewCameraPosition[2]],
          fov: 55
        }}
        frameloop="demand"
        shadows
        gl={{ preserveDrawingBuffer: true }}
      // onPointerDown={() => handleLongPress()}
      // onPointerUp={() => handleButtonRelease()}
      >
        <CaptureCamera currentObjectArtboard={currentObjectArtboard} />
        <Sky distance={4500000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} />
        <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>
        <PointerLockControls unlock={() => console.log("disconnect")} selector={"#splashy"} ref={controlsRef} />
        {objectsAreLoaded && <PreviewObjectsMapped projectKey={projectKey} guestMode={guestMode} artboards={artboards} />}
        <mesh
          receiveShadow
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, 0, 0]}
          ref={floorRef}
        >
          <planeGeometry attach="geometry" args={[50, 50]} />
          <meshStandardMaterial
            color={floorColour}
            attach="material"
            opacity={1}
          />
        </mesh>
        {showTiles ? <TeleportFloorGrid /> : ''}
        <ChangeSceneDesktop />
        {/* <CursorsAndHeadsets currentObjectArtboard={currentObjectArtboard} COLORS={COLORS} projectKey={projectKey}/> */}
        <Suspense fallback={null}>
          {others && others.map(({ connectionId, presence }) =>
            presence.cursor && presence.cursor.page === 'editor' && presence.cursor.artboard === currentObjectArtboard && <Cursor key={connectionId} rotation={presence.cursor.rotation} position={presence.cursor.position} color={COLORS[connectionId % COLORS.length]} mouse={presence.cursor.mouse} />
          )}</Suspense>
        <Suspense fallback={null}>
          {others && others.map(({ connectionId, presence }) =>
            presence.preview && presence.headset && presence.preview.page === 'preview' && presence.preview.artboard === currentObjectArtboard && <PlayerFigure key={connectionId} rotation={presence.headset.rotation} position={presence.headset.position} color={COLORS[connectionId % COLORS.length]} device={presence.preview.device} />
          )}</Suspense>
        {/* <ChangePreviewScene/>*/}
      </Canvas>
      {modeSelectVisible &&
        (
          // <div style={{
          //   zIndex: 100000, background: "white", position: "absolute",
          //   width: "60%",
          //   height: "auto",
          //   top: "20%",
          //   left: "30%",
          //   outline: "none",
          //   opacity: "100%",
          //   padding: 10,
          //   borderStyle: "solid",
          //   borderColor: "black"
          // }}>
          <Card style={{
            zIndex: 400, borderRadius: 32, maxWidth: 600,
            maxHeight: 500, top: '50%', overflow: 'hidden',
            left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)'
          }}>
            <h2>Welcome to preview mode</h2>
            <h4>VR teleporting</h4>
            <p>If you're in VR, point your controller at the floor and select areas to teleport to them. And hold down to enable scene change buttons.</p>
            <h4>Desktop/laptop teleporting</h4>
            <p>If you're on a computer, hold down left mouse button to enable teleportation. Use the scene change buttons the change scenes.</p>
            <p>You can also change scenes by selecting objects set as interactive.</p>
            <p>What device are you using? <i>You can refresh this page to change your selection.</i></p>
            <Group>
              <Button size="xl" onClick={() => handleClick("desktop")}
                style={{
                  backgroundColor: 'black', borderRadius: 32
                }}>Desktop</Button>
              <Button size="xl" onClick={() => handleClick("VR")}
                style={{
                  backgroundColor: 'black', borderRadius: 32
                }}>VR headset</Button>
            </Group>
            {/* <button style={{ width: "40%", height: 50, marginRight: 10 }} onClick={() => handleClick("desktop")}>Desktop</button>
          <button style={{ width: "40%", height: 50 }} onClick={() => handleClick("VR")}>VR headset</button> */}
          </Card>
          // {/* </div> */}
        )}
      <PreviewSplash />
    </>

  }
  else if (previewMode === "VR") {
    return (
      <Suspense fallback={<div></div>}>
        <VRButton sessionInit={{ optionalFeatures: ['hand-tracking'] }} />
        <Canvas
          ref={vRCanvasRef}
          camera={{
            fov: 90
            // position: [previewCameraPosition[0], 1.75, previewCameraPosition[2]]
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          frameloop="demand"
          shadows
        >
          <XR>
            <CaptureHeadset currentObjectArtboard={currentObjectArtboard} />
            <TeleportationPlane leftHand={false} rightHand={true} maxDistance={10} size={0.5} />

            <VRPreviewCameraFunction />
            <Sky distance={450000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} />
            <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>
            <Controllers rayMaterial={{ color: 'blue' }} />
            <Hands />
            <group>
              <VRPreviewObjectsMapped projectKey={projectKey} guestMode={guestMode} />
            </group>
            <CursorsAndHeadsets currentObjectArtboard={currentObjectArtboard} COLORS={COLORS} projectKey={projectKey} />


            <mesh
              receiveShadow
              rotation={[-Math.PI / 2, 0, 0]}
              position={[0, 0, 0]}
            >
              <planeGeometry attach="geometry" args={[50, 50]} />
              <meshStandardMaterial
                color={floorColour}
                attach="material"
                opacity={1}
              />
            </mesh>
            <VRTeleportFloorGrid />
            {/* {showTiles ? <VRTeleportFloorGrid /> : ''} */}
            {/* <Suspense fallback={null}>
            {others && others.map(({ connectionId, presence }) =>
            presence.cursor && presence.cursor.page === 'editor' && presence.cursor.artboard === currentObjectArtboard && <Cursor key={connectionId} rotation={presence.cursor.rotation} position={presence.cursor.position} color={COLORS[connectionId % COLORS.length]} mouse={presence.cursor.mouse} />
            )}</Suspense>
             <Suspense fallback={null}>
            {others && others.map(({ connectionId, presence }) =>
               presence.headset && presence.preview && presence.preview.page === 'preview' && presence.preview.artboard === currentObjectArtboard && <PlayerFigure key={connectionId} rotation={presence.headset.rotation} position={presence.headset.position} color={COLORS[connectionId % COLORS.length]}  device={presence.preview.device}/>
              // presence.preview && presence.preview.page === 'preview' && presence.preview.artboard === currentObjectArtboard && <PlayerFigure key={connectionId} rotation={presence.preview.rotation} position={presence.preview.position} color={COLORS[connectionId % COLORS.length]}/>
            )}</Suspense> */}

          </XR>
        </Canvas>
      </Suspense>
    )
  }


};

const CursorsAndHeadsets = ({ currentObjectArtboard, COLORS, projectKey }) => {


  const { scene } = useThree()

  // console.log(scene)

  const others = useStore((state) => state.liveblocks.others);

  return (
    <>
      {/* CURSORS */}
      <Suspense fallback={null}>
        {others && others.map(({ connectionId, presence }) =>
          presence.cursor && presence.cursor.page === 'editor' && presence.cursor.artboard === currentObjectArtboard && <Cursor key={connectionId} rotation={presence.cursor.rotation} position={presence.cursor.position} color={COLORS[connectionId % COLORS.length]} mouse={presence.cursor.mouse} />
        )}</Suspense>
      {/* HEADSETS */}
      <Suspense fallback={null}>
        {others && others.map(({ connectionId, presence }) =>
          presence.headset && presence.preview && presence.preview.page === 'preview' && presence.preview.artboard === currentObjectArtboard && <PlayerFigure key={connectionId} rotation={presence.headset.rotation} position={presence.headset.position} color={COLORS[connectionId % COLORS.length]} device={presence.preview.device} />
          // presence.preview && presence.preview.page === 'preview' && presence.preview.artboard === currentObjectArtboard && <PlayerFigure key={connectionId} rotation={presence.preview.rotation} position={presence.preview.position} color={COLORS[connectionId % COLORS.length]} device={presence.preview.device} />
        )}</Suspense>


    </>
  )

}

const styles = {
  splash: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0%",
    left: "0%",
    outline: "none",
    background: "black",
    opacity: "80%"
  },
  splashText: {
    color: "white",
    textAlign: "center",
    paddingTop: "25%"
  },
  splashButton: {
    position: "absolute",
    width: "10%",
    height: "5%",
    left: "45%",
    top: "47.5%"
  },
  wasdImage: {
    width: "12.5%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  button: {
    position: "absolute",
    width: "10%",
    height: "10%",
    top: "50%",
    left: "50%",
    color: "white",
  }
};

export default PreviewViewport; 