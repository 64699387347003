import React, { useEffect, useLayoutEffect, Suspense, useState, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Grid } from "@mui/material/";
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useStore } from "../zustand/objects";

import AppNavBar from "../components/AppNavBar";
import { Screenshot } from "../components/Screenshot";

import DeleteProjectModal from "../components/DeleteProjectModal"; 
import ProjectsTable from "../components/ProjectsTable";
import { postMiroAuthData, getFigmaFrameFromLink, saveUserScreenshotsAPI } from "../api";
import { Card, Button,  Image, Stack, TextInput, Text, Group, Badge, ActionIcon } from '@mantine/core';

// we need to store the permission key in XANO and then it can be shared with any collaborating user

export const GoogleAuthCallbackPage = () => {

    const [haveToken, setHaveToken] = useState(false)
    const [boards, setBoards] = useState([])

    // const url = window.location.href
    // var regex = /state=([^&]*)/
    // const urlParams = new URLSearchParams(window.location.hash);
    // const stateParam = url.match(regex)[1];
    // const codeParam = urlParams.get('code');
    // const tokenParam = urlParams.get('access_token');

    const urlParams = new URLSearchParams(window.location.search);
    const stateParam = urlParams.get('state');
    const codeParam = urlParams.get('code');


const getGoogleAccessToken = (data
    ) => {
      return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/googleAccessToken', {
        method: 'POST',
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('user')}`
        },
        body: JSON.stringify({
            authorisationCode: codeParam
          }) 
      })
        .then((response) => response.json())
        .then((data) => {
          return data
        })
        .catch((error) => {
          console.error("Error:", error);
    
        });
    }

// const sendToken = (data) => {
//   return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/googlesync', {
//     method: 'POST',
//     headers: {
//       "content-type": "application/json",
//       "Authorization": `Bearer ${localStorage.getItem('user')}`
//     },
//     body: JSON.stringify({
//       accessToken: tokenParam
//     })
//   })
//   .then((response) => response.json())
//   .then((data) => {
//     return data
//   })
//   .catch((error) => {
//     console.error("Error:", error);

//   });
// }


const getUserMiroBoardsAPI = (
    data
  ) => {
    return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getMiroBoardsByUser', {
      method: 'GET',
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('user')}`
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        console.error("Error:", error);
  
      });
  }
 



//   useEffect(() => {
// // GET ALL A USER'S MIRO BOARDS
// getMiroAccessToken()
// .then(() => getUserMiroBoardsAPI())
// .then((res) => setBoards([...res.response.result.data]))
// .then(() => setHaveToken(true))

//   },[])
  useEffect(() => {
// GET ALL A USER'S MIRO BOARDS
// sendToken(tokenParam)
getGoogleAccessToken()
.then((res) => console.log(res))

  },[])
    

 
    const GridBackground = () => {
        return (
            <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100vh", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

        )
    }

    return (
        <div>
            <Suspense fallback={<div>Loading... </div>}>
                <GridBackground />
                <Grid container>
                    <Grid item xs={12} md={12} sx={{ mt: 8, mb: 4 }}>
                        <Box sx={{ marginLeft: 10, flexGrow: 1 }}>
                            {/* <Typography component="h1" variant="h4" fontWeight={'700'}>
                                This is where we browse miro projects and pick one to export the mbxr projects to, using the projectKey in the URL.
                            </Typography> */}
                            <a href={`https://mbxr.io/editor/${stateParam}`}>Return to editor</a>
                            {/* <b>You can close this page</b> */}
                        </Box>
                    </Grid>
                    
                  {/* {haveToken && <MiroBoardsMapped boards={boards} projectKey={stateParam}/>} */}
                    
                </Grid>
            </Suspense>
        </div>
    )
};



export const MiroBoardsMapped = ({projectKey}) => {


  const [haveToken, setHaveToken] = useState(false)
  const [boards, setBoards] = useState([])

const {screenshots, artboards, setScreenshotStatus} = useStore()


const screenshotArray = Object.values(screenshots)

const saveScreenshots = () => {

  // saveUserScreenshotsAPI({projectKey:projectKey, screenshots: screenshots})
  saveUserScreenshotsAPI({projectKey:projectKey, screenshots: [...screenshotArray]})
}


const getUserMiroBoardsAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getMiroBoardsByUser', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}



  useEffect(() => {
    // GET ALL A USER'S MIRO BOARDS
    setScreenshotStatus(true)
getUserMiroBoardsAPI()
.then((res) => setBoards([...res.response.result.data]))
// .then(() => window.setTimeout(function () {
//   saveScreenshots()
// }, 300))
.then(() => setHaveToken(true))

// .then(() => setScreenshotStatus(false))
// .then(() => saveScreenshots())
    
      },[])



    const sendStoryboardToMiro = (data
        ) => {
          return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/CreateMiroStoryboard', {
            method: 'POST',
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('user')}`
            },
            body: JSON.stringify({
                boardId: data,
                projectKey: projectKey
              })
          })
            .then((response) => response.json())
            .then((data) => {
              return data
            })
            .catch((error) => {
              console.error("Error:", error);
        
            });
        }

        const [shotArtboard, setShotArtboard] = useState()

       async function updateScreenshots() {
          Object.keys(artboards).forEach((artboard, i) =>  setTimeout(() => {
            setShotArtboard(artboard);
          }, i * 250))
          // Object.keys(artboards).forEach((artboard, i) =>  setTimeout(() => {
          //   console.log(artboard);
          // }, i * 250))
          // Object.keys(artboards).forEach((artboard) => console.log(artboard))
          // Object.keys(artboards).forEach((artboard) => setShotArtboard(artboard))
          
        }

        // useLayoutEffect(() => {
          
        // },[shotArtboard])
    
async function saveScenes() {
  saveScreenshots()
}

    const handleExportClick = (boardId) => {
      // updateScreenshots()
      // .then(() => setShotArtboard())
      // .then(() => saveScreenshots())
      // saveScreenshots()
      saveUserScreenshotsAPI({projectKey:projectKey, screenshots: [...screenshotArray]})
      .then(() =>sendStoryboardToMiro(boardId))
      .then(() =>setScreenshotStatus(false))
      
    }

return (<> 
    {/* {Object.keys(artboards).map((mapped) => (<div key={mapped} ><Screenshot projectKey={projectKey} guestMode={false} artboard={mapped} /></div>)) } */}
  {haveToken && boards.map((mapped) => (
        <Card key={mapped.id} withBorder radius="md" p={0} style={{  width:320, marginBottom:20, borderRadius:16, cursor: 'pointer'}}
            shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
          <Group wrap="nowrap" gap={0} >
                <div style={{ width: 100, height: "auto" }}>
                    <img src={mapped.picture.imageURL} style={{width:"100%", height:"100%"}}/></div>
                <Stack style={{marginLeft:20}}>
                    <Text>{mapped.name}</Text>
                <Button
                    onClick={() => handleExportClick(mapped.id)}
                    style={{
                        width: 150, paddingTop: 1, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
                    }}>Export to board</Button>
                    </Stack>
            </Group></Card>
))}</>)

}