import React, { useState } from "react";

import FormControl from '@mui/material/FormControl';
import { useStore } from "../zustand/objects";
import { Button } from "@mui/material";
import { Input, Switch, Text, Group, Slider, ActionIcon } from '@mantine/core';



const ProjectName = () => {

  const { projectName, updateProjectName } = useStore();
  
  const [editing, setEditing] = useState(false)

  const handleClick = () => {
    setEditing(true)
  }

  const handleSubmit = (val) => {
    updateProjectName(val)
    setEditing(false)
  }

   

  return (
    
      <>
      {editing ?
        <FormControl onBlur={(e) => handleSubmit(e.target.value)} style={{maxWidth:100}}>
          <Input size="xs" defaultValue={projectName} autoFocus variant="outlined" onKeyPress={(e) => { if (e.key === 'Enter') { handleSubmit(e.target.value) } }} />
        </FormControl>
        :
        <> <Text 
          size={'xs'}
          fw={500}
          c={"#424242"}
        style={{whiteSpace:'nowrap', marginLeft:5, marginRight:5, textOverflow: 'ellipsis', overflow:'hidden', maxWidth:75}} onClick={() => handleClick()}>
          {projectName}
        </Text>
        </>}
        </>

  );
};


export default ProjectName;
