import React, { useState }  from "react";
import { createPortal } from 'react-dom';

import { ScreenshotGuest } from "./ScreenshotGuest";
import { Screenshot } from "./Screenshot";
import ScreenshotModal from "./ScreenshotModal";
import { Grid, Box } from "@mui/material/";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Card, Button,  Image, Text, Group, Badge, ActionIcon } from '@mantine/core';
import { Tooltip } from '@mantine/core';


import { useStore } from "../zustand/objects";

const OtherUser = ({ isMobile, other, open, connectionId, presence }) => {


// const {isOtherPreviewOpen, setIsOtherPreviewOpen} = useStore()

const [isOtherPreviewOpen, setIsOtherPreviewOpen] = useState(false)
const [hover, setHover] = useState(false)

const switchIsPreviewOpen = () => {
  setIsOtherPreviewOpen(!isOtherPreviewOpen)
} 

// console.log(other)
const handleWatchClick = () => {
//  isWatching ? setIsWatching(false) : setIsWatching(true)
switchIsPreviewOpen()
// setIsOtherPreviewOpen()
}

// NEED TO MOVE PREVIEW ELSEWHERE SO IT CAN STAY OPEN WHEN USER CHANGES TABS

 
  const [maximised, setMaximised] = useState(false)

  const openMaximisedClick = () => {
    setMaximised(true)
  } 

  const closeMaximisedClick = () => {
    setMaximised(false)
  }

  return (

    <Grid mt={1} container style={{width:"auto", minHeight:20, maxHeight: 140}}>
    { !isOtherPreviewOpen ? <>
    <Grid item xs={10}>
      <Text fw={400} size='xs' c={"#424242"} >
      <AccountCircleIcon fontSize="small"  style={{marginRight:2}}/>{presence.cursor && presence.cursor.name} {presence.preview && presence.preview.name} {` | `}
        {presence.cursor && presence.cursor.artboard}
        {presence.preview && presence.preview.artboard}
        </Text></Grid>
        <Grid item xs={2}>{presence.preview &&
          <Text fw={400} size='xs' c={"#424242"} c={!hover ? "#424242" : "#868e96"} 
          onPointerOver={() => setHover(true)} 
          onPointerLeave={() => setHover(false)}
          style={{marginLeft: 10}}> 
          <Tooltip label="See user view">
            <VisibilityIcon 
            
              fw={400} size='xs' 
            style={{cursor: 'pointer'}} fontSize="small" 
          onClick={() => handleWatchClick()} /></Tooltip></Text>}</Grid> </>
       : maximised ? 
      <ScreenshotModal closeMaximisedClick={closeMaximisedClick} cameraGuest={presence.headset} artboard={presence.preview.artboard} name={presence.preview.name} id={connectionId} />
        : presence.preview && isOtherPreviewOpen && <div>
          <Grid item xs={12}>
          
        <div style={{ position:'absolute', bottom:60, height:110, width:233, marginRight:20, borderRadius:5, border:'solid #DEE2E6', overflow:"hidden"}}>         

        <Group bg={'none'} style={{ position:'absolute', right:5, zIndex:99999}}>
        
        <div style={{position:'absolute',  right:60, top:80, width:'100%' }}>
        <Text fw={400} size='xs' c={"#424242"} >
        <AccountCircleIcon color={!hover ? "#424242" : "#868e96"} fontSize="small" style={{marginRight:5}}/>{presence.preview && presence.preview.name} {` | `}
        {presence.preview.artboard}
        </Text></div>
          <Text>
         <FullscreenIcon fontSize="small" onClick={openMaximisedClick} style={{marginLeft:115, marginRight: 5, cursor:'pointer'}}/>
        <CloseIcon fontSize="small" onClick={handleWatchClick} style={{cursor:'pointer'}}/>
          </Text>
        </Group>
       
        <ScreenshotGuest openMaximisedClick={openMaximisedClick} cameraGuest={presence.headset} artboard={presence.preview.artboard} id={connectionId} />
         </div>
        </Grid>
       
         </div>
         
      }
      </Grid>
        ) 
}

const styles = {
  cameraDiv: {
      width: 300,
      height: 'auto',
      float:'right',
      position: 'absolute',
      bottom: 10,
      right: 10,
      border: 'solid'
  }
}

      export default OtherUser