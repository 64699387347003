import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete"
import { useStore } from "../zustand/objects";

import { Card, Button, Text, Group, FileButton, ActionIcon } from '@mantine/core';


const ImagesAPIMapped = ({ id }) => {

    const {
        updateObjectSelected,
        transformModeChange, 
        addObject,
        updateImageTexture,
        updateImageObjectURL,
        currentObjectArtboard,
        imagePurpose,
        setImagePurpose,
        editingID,
        setEditingID
    } = useStore();
 
    const missedShape = () => {
        updateObjectSelected('0')
        transformModeChange({
            transformMode: "translate"
        })
    };


    const publicTextures = [{ name: "blackboard", size: { width: 4406, height: 2726 } }, { name: "brick-wall", size: { width: 5556, height: 3704 } }, { name: "ink-abstract", size: { width: 5824, height: 3264 } }, { name: "white-wrinkled-paper", size: { width: 4928, height: 3712 } }, { name: "wood-plank-floor", size: { width: 5824, height: 3264 } }]
    const [imageURLs, setImageURLs] = useState([])
    const [readyToMap, setReadyToMap] = useState(false)

    const [inputVal, setInputVal] = useState()

    const [sizeAlert, setSizeAlert] = useState(false)
    const [uploadAlert, setUploadAlert] = useState(false)


    // GET ALL A USER'S IMAGES
    const getUserImagesAPI = () => {
        return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/image', {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('user')}`
            },
        })
            .then((response) => response.json())
            .then(data => setImageURLs([...data]))
            .then(() => setReadyToMap(true))
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    // CREATE A NEW IMAGE RECORD
    const createNewImageRecord = (data) => {
        fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/image', {
            method: 'POST',
            body: data,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('user')}`
            }
        }).then((response) => response.json())
            .then((result) => {
                getUserImagesAPI(localStorage.getItem('user'))
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    // DELETE AN IMAGE RECORD 
    const deleteImageRecord = (data) => {
        fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/image/${data}`, {
            method: 'DELETE',
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('user')}`
            }
        })
            .then((response) => response.json())
            .then((result) => {
                getUserImagesAPI(localStorage.getItem('user'))
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    const handleChange = (e) => {

        const formData = new FormData();
        const reader = new FileReader();
        reader.readAsDataURL(e)
        reader.onload = function () {
            formData.append("image", reader.result)
            setInputVal(formData)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };


    }

    const handleSubmit = (e) => {
        createNewImageRecord(e)
    }

    useEffect(() => {    
        getUserImagesAPI()
    }, []);
   
    useEffect(() => {    
        checkImageCount()
    }, [imageURLs.length]);

    const checkImageCount = () => {
        // console.log(myModels.length)
        imageURLs.length >= 12 ? setUploadAlert(true) : setUploadAlert(false)
      }


    const addImageObject = (val, val2) => {
        addObject({ currentObjectArtboard: currentObjectArtboard, undo: false, category: "image", image: val, imageSize: val2, object: "image", bGColour: null, pose: { name: null, modelPath: null}})
        setImagePurpose(null)
    }
    const addImageTexture = (val) => {
        updateImageTexture(editingID, currentObjectArtboard, val, false)
        setImagePurpose(null)
        setEditingID(null)
        missedShape()
    }
    const editImageObjectURL = (val, val2) => {
        updateImageObjectURL(editingID, currentObjectArtboard, val, val2, false)
        setImagePurpose(null)
        setEditingID(null)
        missedShape()
    }

    const deleteImage = (id) => {
        deleteImageRecord(id)
    }

    const [viewing, setViewing] = useState('presets')


    return (
    <div>
        <Grid container style={{ width:'100%', padding:20, paddingTop:10, margin:0, height:190, overflow:'scroll', backgroundImage: 'url("/image/background-grid.png")' }}>
        {/* <div style={{ overflow: "scroll", maxHeight: "300px", paddingBottom: 10 }}> */}
            
            {readyToMap && imageURLs.map((imageSrc, i) =>
                    <Grid key={i} item md={3} mb={2.5}>
                        <div style={{ height: "100px", overflow: "hidden", margin: 5 }}>
                            <img alt={'uploaded content'} style={{ width: "100%" }} src={imageSrc.image.url} />
                        </div>
                        <Group>
                                <Button onClick={() => {
                                        (imagePurpose === "object") ? addImageObject(imageSrc.image.url, imageSrc.image.meta) : 
                                        (imagePurpose === "texture") ? addImageTexture(imageSrc.image.url) :
                                        editImageObjectURL(imageSrc.image.url, imageSrc.image.meta)
                                    }}
                                    style={{
                                        height: 20, paddingTop: 1, paddingBottom: 1, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
                                    }}>
                                    Add
                                </Button>
                               <ActionIcon variant="transparent" color="black" size={'xs'}
                                    onClick={() => deleteImage(imageSrc.id)}>
                                    <DeleteIcon />
                                </ActionIcon>
                                </Group>
                                </Grid>

                )
                }
        </Grid>
            <Grid container style={{padding:20}}>
        <Grid item xs={12}>
        {(imagePurpose === "objectEdit") ? <Text size="xl" fw={700}>Edit your existing image</Text> 
        : (imagePurpose === "texture") ?  <Text size="xl" fw={700}>Apply an image texture</Text> 
        : <Text size="xl" fw={700}>Upload your own image</Text>}
        
                <Group style={{marginTop:10}}>
                        <FileButton 
                            accept="image/png,image/jpeg"
                            onChange={(e) => {
                                const form = new FormData()
                                if (e.size > 1097152) {setSizeAlert(true)}
                                else {setSizeAlert(false)
                                    form.append("image", e, "image_upload.png");
                                    e && handleSubmit(form)
                                }
                            }}
                        >
                            {(props) => <Button
                                disabled={uploadAlert}
                                style={{
                                    paddingTop: 1, paddingBottom: 1, backgroundColor: 'black', borderRadius: 32
                                }}
                                {...props}>Upload</Button>}
                        </FileButton>
                        {uploadAlert ? <Text c={"red"} size="md" fw={400}>Max 12 uploads</Text> :
                        sizeAlert ? <Text c={"red"} size="md" fw={400}>Max size 1mb</Text> :
                        <Text size="md" fw={400}>Only .png / .jpeg files are accepted.</Text>}
                        </Group>
                </Grid>
            </Grid>

    </div>
    )
}

export default ImagesAPIMapped