import React, { Suspense, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import DeleteIcon from "@mui/icons-material/Delete"

import { ScreenshotForProjectsPage } from "../components/Screenshot";

import { Link } from "react-router-dom";

import { useStore } from "../zustand/objects";

import { useAccountStore } from "../zustand/account";
import { Card, Button,  Image, Input, Text, Group, Badge, ActionIcon } from '@mantine/core';


export default function ProjectsTable({ projects, isGuest }) {

    const { getSpecificUserProject, unloadObjects, loadObjects, objectsAreLoaded } = useStore();
    const { updateDeleteModalOpen, updateDeleteID, createNewProject, getUserProjects } = useAccountStore();

    const setProjectState = (projectKey) => {
        getSpecificUserProject(projectKey)
    }

    const handleDeleteClick = (id) => {
        updateDeleteID(id)
        updateDeleteModalOpen()
    }

    const dateConvert = (timestamp) => {
        return new Date(timestamp)
    }


    const [displayForm, setDisplayForm] = useState(true)


    async function sendProject(e) {
        let projectsLength = projects.length
        // const data = new FormData(e.currentTarget);
        // createNewProject({ name: data.get('name') })
        setDisplayForm(false)

        createNewProject({ name: `Project ${projectsLength += 1}` })
    }

    const onEditClick = (e) => {
        unloadObjects()
        setProjectState(e)
    }


    // NEED TO MAKE NEW XANO FUNCTION THAT SENDS THE PROJECT KEY TO THE NEW ARRAY AND THEN THIS IS USED TO FIND LINKED PROJECTS
 

    return (<>
        <Suspense fallback={null}>
            <CssBaseline />
            <Suspense fallback={<div>Loading... </div>}>
                <Box>
                    <Container component="form" style={{ margin: 0, padding: 0 }}>

                        <Grid sx={{ marginTop: 0 }} container>
                        
                            {projects && projects.map((row, i) => (
                               row && objectsAreLoaded && <Suspense fallback={<img src="/image/loading.gif" style={{width: "50px", height: "50px"}}/>} key={i}>
                                    <Grid item key={i} xs={12} sm={6} md={3}>
                                        <Card radius={16} padding={0} style={{margin:10}}
                                        shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                                        {/* <Card sx={{ display: 'flex', flexDirection: 'column', borderRadius: 0, marginRight: "12px", marginBottom: "12px", borderWidth: 0.5, borderStyle: "solid", borderColor: "black"  }}> */}
                                            <Box>
                                                <Suspense fallback={<img src="/image/loading.gif" style={{width: "50px", height: "50px"}}/>}>
                                                    <ScreenshotForProjectsPage floorColour={row.floorColour} artboard={row.artboards[1]} />
                                                </Suspense>
                                            </Box>
                                            <Box>
                                                <Box style={{ width: "100%", margin:10 }}>
                                                    <Text size={'sm'} fw={600}>
                                                        {row.projectName}
                                                    </Text>
                                                </Box>
                                                <Box style={{ width: "100%", margin:10 }}>
                                                <Text size={'sm'} fw={400}>
                                                        {new Date(row.created_at).toDateString()}
                                                    </Text>
                                                </Box>
                                            </Box>
                                            <Group>
                                                <Button
                                                    style={{
                                                        width:'60%', margin:10, inline: true, backgroundColor: 'black', borderRadius: 32}}
                                                        onClick={() => { onEditClick(row.projectKey) }}
                                                        component='a'
                                                        href={`/editor/${row.projectKey}`}>
                                                            Edit</Button>
                                                      {  !isGuest &&   <ActionIcon
                                                        variant='transparent'
                                                        color='black'
                                                        
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            handleDeleteClick(row.projectKey)
                                                        }}>
                                                            <DeleteIcon/>
                                                        </ActionIcon>}
                                            </Group>
                                        </Card>
                                    </Grid></Suspense>
                            ))}
                        </Grid>




                    </Container>
                </Box>
            </Suspense>



        </Suspense>
    </>
    );
}



