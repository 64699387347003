import React, { Suspense } from "react";
import { useStore } from "../zustand/objects";
import { Image } from "@react-three/drei";
import * as THREE from "three"; 

import {CurvedPanels, CurvedPanelsTexture} from "./ModelFiles/UI/CurvedPanels" 


const PreviewImageObject = ({ position, rotation, scale, destination, colour, pose, image, imageSize, matrixState, curved, curveAmount }) => {

  const { shapeHoverChange, updateArtboardAndPreviewCamera } = useStore();

  const passdownFunctionPreview = () => {
    destination && updateArtboardAndPreviewCamera(destination)
    destination && shapeHoverChange(false);

  }

  const hoverShape = (e) => {
    shapeHoverChange(e)
  }




  return (
    <>
      <Suspense fallback={null}>
        <group
          // scale={scale}
          scale={new THREE.Vector3().setFromMatrixScale(matrixState)}
          position={new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={new THREE.Euler().setFromRotationMatrix(matrixState)}
          onPointerOver={() => destination && hoverShape(true)}
          onPointerOut={() => destination && hoverShape(false)} destination={destination} onClick={passdownFunctionPreview} colour={colour} pose={pose}>
          {!curved ?
            <Image transparent opacity={1} position={[0, imageSize.height / 1600, 0]} scale={[imageSize.width / 800, imageSize.height / 800, 1]} url={image} />
            : <CurvedPanelsTexture colour={colour} imageTexture={image} curveAmount={curveAmount} width={imageSize.width / 1600} height={imageSize.height / 800}/>
          }
        </group>
      </Suspense>

    </>
  );
};

export default PreviewImageObject;




