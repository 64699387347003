import React, { useEffect } from "react";
import { useStore } from "../zustand/objects";
import {Box} from "@mui/material/";
import UndoIcon from '@mui/icons-material/Undo';
import { ActionIcon } from '@mantine/core';

const UndoButton = () => {
  const {
    undoActions,
    redoActions,
    undoRedoZustand,
    saveUserProject

  } = useStore();

const undo = useStore((state) => state.liveblocks.room?.history.undo);
const redo = useStore((state) => state.liveblocks.room?.history.redo);


 const asyncUndo = async () => { 
  undo()
  undoRedoZustand('undo')
 }
  
  const handleUndoClick = () => {
    
        asyncUndo().then(() => saveUserProject())
  }

  const asyncRedo = async () => {
    redo()
    undoRedoZustand('redo')
   }


  const handleRedoClick = () => {
    asyncRedo().then(() => saveUserProject())
  }

  // KEYBOARD OBJECT FUNCTIONS 
  // function handleKeyDown(event) {
  //   if (event.repeat) { return }  
  //   if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
  //     handleUndoClick() 
  //   }
  // } 


  // console.log(undoActions.length)

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.repeat) { return }  
      if (event.key === 'z' && (event.ctrlKey || event.metaKey) && !event.shiftKey) {
        undoActions.length > 0 && handleUndoClick()
      }
      if (event.key === 'z' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
        redoActions.length > 0 && handleRedoClick()
      }
    }
    document.addEventListener("keydown", handleKeyDown)
  
    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [undoActions.length, redoActions.length])

  // useEffect(() => {
  //     document.addEventListener('keydown', handleKeyDown)
  // }, []); 

  // useHotkeys('ctrl+z, meta+z', () => handleUndoClick(),[])

  return (
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: "100%",
      justifyContent: 'flex-start',
      margin: "2.5px",
      paddingBottom: 0.5
    }}
    >
      <ActionIcon disabled={undoActions.length <= 0}
      size={'sm'}
        onClick={() => handleUndoClick()}
        // onClick={() => undoRemake()}
        variant="transparent"
        color="#424242"
        bg={'none'}
      >
        <UndoIcon  fontSize={'small'} />
      </ActionIcon>
  {/* <button style={{backgroundColor: "none"}} disabled={undoActions.length <= 0} size={"small"} onClick={() => undoRemake()}>undo</button> */}
  </Box>
  );
};

export default UndoButton;
