/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, Edges } from '@react-three/drei'
import * as THREE from "three";

export default function VRHeadset({ ...props }) {

  const material = new THREE.MeshStandardMaterial({
    color: props.colour,
    transparent: false, opacity: 1
});

  const group = useRef()
  const { nodes, materials } = useGLTF('/model/vr_headset-transformed.glb')
  return (
    <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
      props.clickFunction()}}>
      <group position={[0,0.04,0]}>
      <group position={[0, 0, 0]} rotation={[0, Math.PI / 2, 0]} scale={2}>
      <mesh geometry={nodes.vr_headset.geometry} material={material} rotation={[0, Math.PI, 0]}>
            <Edges
    scale={1}
    threshold={20} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
    color="grey"
  /></mesh>
      </group>
      </group>
    </group>
  )
}

useGLTF.preload('/model/littleStuff/technology/VR Headset-transformed.glb')
