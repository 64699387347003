import React, { useState } from "react";
import { Box, Text, Html } from "@react-three/drei";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Interactive, useXR } from "@react-three/xr";
import { useStore } from "../zustand/objects";

export const ChangePreviewScene = ({ position, rotation }) => {

    const [hovered, setHovered] = useState('')

    const {
        artboards,
        currentObjectArtboard,
        updateArtboard,
        storyboardFields
    } = useStore();

    const currentArtboard = parseInt(currentObjectArtboard)

    const stringArray = Object.keys(artboards)
    var numberArray = stringArray.map(Number);


    const changeArtboard = (val) => {
        setHovered('')
        val === 'back' ?
            updateArtboard((currentArtboard - 1).toString()) 
            // numberArray.includes(currentArtboard - 1) && updateArtboard(currentArtboard - 1) 
            :
            updateArtboard((currentArtboard + 1).toString())
            // numberArray.includes(currentArtboard + 1) && updateArtboard(currentArtboard + 1)
    }

    return (
        <>
            <group
                position={position}
                rotation={rotation}>

                {/* CURRENT SCENE */}
                <group position={[0,0.55,0]}>
                <Html transform >
                        Scene: {storyboardFields[currentObjectArtboard].name ? storyboardFields[currentObjectArtboard].name  : currentObjectArtboard}
                    </Html>
                    </group>
                {/* BACK BUTTON */}
                <group
                    position={[-0.8, 0, 0]}
                    onPointerEnter={() => numberArray.includes(currentArtboard - 1) && setHovered('back')}
                    onPointerLeave={() => setHovered('')}
                    onPointerUp={() => hovered && numberArray.includes(currentArtboard - 1) && changeArtboard("back")}>
                    <Html transform >
                        <ArrowBackIcon />
                    </Html>
                    <Box
                        position={[0, 0, -0.05]}
                        args={[1.5, 0.5, 0.05]}
                    >
                        <meshBasicMaterial
                            color={hovered === 'back' ? "rgb(250, 199, 16)" : 'white'}
                        />
                    </Box>
                </group>

                {/* FORWARD BUTTON */}
                <group
                    position={[0.8, 0, 0]}
                    onPointerEnter={() => numberArray.includes(currentArtboard + 1) && setHovered('forwards')}
                    onPointerLeave={() => setHovered('')}
                    onPointerUp={() => hovered && numberArray.includes(currentArtboard + 1) && changeArtboard("forwards")}>
                    <Html transform>
                        <ArrowForwardIcon />
                    </Html>
                    <Box
                        position={[0, 0, -0.05]}
                        args={[1.5, 0.5, 0.05]}
                    >
                        <meshBasicMaterial
                            color={hovered === 'forwards' ? "rgb(250, 199, 16)" : 'white'}
                        />
                    </Box>
                </group>
            </group>

        </>
    )
}


export const VRChangePreviewScene = ({ position, rotation }) => {


    const {
        setTeleportCount,
        teleportCount,
        updatePreviewCamera
    } = useStore();

    const [hovered, setHovered] = useState('')

    const {
        artboards,
        currentObjectArtboard,
        updateArtboard,
        storyboardFields
    } = useStore();

    const currentArtboard = parseInt(currentObjectArtboard)

    const stringArray = Object.keys(artboards)
    var numberArray = stringArray.map(Number);


    const changeArtboard = (val) => {
        val === 'back' ?
        updateArtboard((currentArtboard - 1).toString()) 
        // numberArray.includes(currentArtboard - 1) && updateArtboard(currentArtboard - 1) 
        :
        updateArtboard((currentArtboard + 1).toString())
        // numberArray.includes(currentArtboard + 1) && updateArtboard(currentArtboard + 1)
    }


    return (
        <>
            <group
                position={position}
                rotation={rotation}>
                    
                    <Text 
                    position={[0,0.55,0]}
                    fontSize={0.45}
                    color={'black'}>
                {/* <Html transform > */}
                Scene: {storyboardFields[currentObjectArtboard].name ? storyboardFields[currentObjectArtboard].name  : currentObjectArtboard}
                    {/* </Html> */}
                    </Text>
                {/* BACK BUTTON */}
                <group position={[-0.8, 0, 0]}>
                    <Interactive
                        onHover={() => numberArray.includes(currentArtboard - 1) && setHovered('back')}
                        onBlur={() => setHovered('')}
                        onSelectEnd={() => hovered && numberArray.includes(currentArtboard - 1) && changeArtboard("back")}
                    >
                        {/* <Html transform >
                        <ArrowBackIcon />
                        </Html> */}
                        <Text
                        position={[0, 0, 0]}
                        fontSize={0.45}
                        color={'black'}
                        >
                            BACK
                        </Text>
                        <Box
                            position={[0, 0, -0.05]}
                            args={[1.5, 0.5, 0.05]}
                        >
                            <meshBasicMaterial
                                color={hovered === 'back' ? "rgb(250, 199, 16)" : 'white'}
                            />
                        </Box>
                    </Interactive>
                </group>

                {/* FORWARD BUTTON */}
                <group position={[0.8, 0, 0]}>
                    <Interactive
                        onHover={() => numberArray.includes(currentArtboard + 1) && setHovered('forwards')}
                        onBlur={() => setHovered('')}
                        onSelectEnd={() => hovered && numberArray.includes(currentArtboard + 1) && changeArtboard("forwards")}
                    >
                        {/* <Html transform occlude>
                            <ArrowForwardIcon />
                        </Html> */}
                        <Text
                        position={[0, 0, 0]}
                        fontSize={0.45}
                        color={'black'}
                        >
                            NEXT
                        </Text>
                        <Box
                            position={[0, 0, -0.05]}
                            args={[1.5, 0.5, 0.05]}
                        >
                            <meshBasicMaterial
                                color={hovered === 'forwards' ? "rgb(250, 199, 16)" : 'white'}
                            />
                        </Box>
                    </Interactive>
                </group>
            </group>

        </>
    )

}

