// IMPORTED DEPENDENCIES
import React, { useState, Suspense } from 'react';
// import Select from 'react-select';

// IMPORTED COMPONENTS, STORES AND PAGES
import FileDirectory from "../FileDirectory.json";
import { useStore } from "../zustand/objects";

import ModelPoses from '../ModelPoses';

import { Button, Menu, Group, ActionIcon, rem, useMantineTheme, Select } from '@mantine/core';


// THIS COMPONENT
const ModelDropdown = ({ category }) => {


    // STORE, STATE, ETC
    const { addObject, currentObjectArtboard, switchTextModalOpen, switchUploadModalOpen, switchImageModalOpen, setImagePurpose } = useStore();
    const [textOpen, setTextOpen] = useState(false);
    const [imageUploadOpen, setImageUploadOpen] = useState(false);
    
    const [selectedVal, setSelectedVal] = useState(null);

    const textArray = [
        { value: "plain text", label: "plain text" }
    ]

    const imageArray = [
        { value: "object", label: "custom image" },
        { value: "figma", label: "link to figma frame" },
        { value: "apple", label: "Apple UI elements" },
        { value: "microsoft", label: "Microsoft UI elements" },
        { value: "floatgridsUi", label: "FloatGrids UI elements" },
        { value: "floatgridsLayout", label: "FloatGrids layouts" }
    ]

    const uploadArray = [
        { value: "+ Upload a model", label: "+ Upload a model" }
    ]


    const shapeArray = [
        { value: "cone", label: "cone" },
        { value: "cube", label: "cube" },
        { value: "cylinder", label: "cylinder" },
        { value: "roundedBox", label: "roundedBox" },
        { value: "sphere", label: "sphere" },
    ]

    
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px solid lightGrey',
            color: "hsl(0, 0%, 50%)",
            zIndex: 9999
        }),
        control: (provided, state) => ({
            ...provided,
            marginRight: "5px",
            zIndex: 9999
        })
    } 
 
    // FUNCTIONS 
    const handleChange = (e) => {
        if (category === "shape") {
            addObject({
                currentObjectArtboard,
                category: category,
                object: e.value,
                undo: false,
                id: null,
                position: null,
                scale: null,
                rotation: null,
                matrix: null,
                pose: {
                    name: ModelPoses[e.value] ? ModelPoses[e.value][0] : null,
                    modelPath:null
                },            
                imageTexture: null,
                image: null,
                curved: false,
                curveAmount: 3, 
                bGColour: null
            })}
        else if (
            (e.value === 'apple') ||
            (e.value === 'microsoft') ||
            (e.value === 'floatgridsLayout') ||
            (e.value === 'floatgridsUi') 
        ) { 
        addObject({
            currentObjectArtboard,
            category: e.value,
            object: e.value,
            undo: false,
            id: null,
            position: null,
            scale: null,
            rotation: null,
            matrix: null,
            pose: {
                name: ModelPoses[e.value] ? ModelPoses[e.value][0] : null,
                modelPath:null
            },            
            imageTexture: null,
            image: null,
            curved: false,
            curveAmount: 3, 
            bGColour: null
        })} else {
        addObject({
            currentObjectArtboard,
            category: category,
            object: JSON.parse(e.value).name, 
            undo: false,
            id: null,
            position: null,
            scale: null,
            rotation: null,
            matrix: null,
            pose: {
                name:JSON.parse(e.value).default_pose.name,
                modelPath:JSON.parse(e.value).default_pose.path
            },
            imageTexture: null,
            image: null,
            curved: false,
            curveAmount: 3, 
            bGColour: null,
        })}
        setSelectedVal(null)
    };

    const onAddTextClick = () => {
        switchTextModalOpen()
    }
    const onAddImageClick = (e) => {
        switchImageModalOpen()
        setImagePurpose(e.value)
    }
    const onAddUploadClick = () => {
        switchUploadModalOpen()
    }

    // RETURNED JSX
    if (category === "text") {
        return ( 
            <>
                
                <Select
                    action="clear"
                    placeholder={"Add " + category}
                    onChange={() =>
                        onAddTextClick()
                    }
                    data={textArray.map((mapped) => (
                        { value: mapped.value, label: mapped.label }
                    ))
                    }
                /> 
            </>
        )
    }
    // RETURNED JSX
    else if (category === "image") {
        return (
            <>
                
                <Select
                    action="clear"
                    placeholder={"Add image/UI"}
                    onChange={(e) =>
                        
                       e === 'apple' ? handleChange({value: e}) :  e === 'microsoft' ? handleChange({value: e}) : e === 'floatgridsUi' ? handleChange({value: e}) : e === 'floatgridsLayout' ? handleChange({value: e})
                       : onAddImageClick({value:e})
                    }

                    data={imageArray.map((mapped) => (
                        { value: mapped.value, label: mapped.label }
                    ))
                    }
                />
            </>
        )
    }
    else if (category === "uploaded") {
        return (
            <>
                <button onClick={onAddUploadClick}>Upload a model</button>
            </>)
    }
   else if (category === "shape") {
        return (

                <Select
                    action="clear"
                    placeholder={"Add " + category}
                    onChange={(e) =>
                        handleChange({value: e})
                    }
                    data={shapeArray.map((mapped) => (
                        { value: mapped.value, label: mapped.label }
                    ))
                    }
                />
                )}
                else if (category === "UI") {
                    return (
            
                            <Select
                            action="clear"
                            placeholder={"Add " + category}
                            onChange={(e) =>
                                handleChange({value: e})
                            }
                                data={FileDirectory[category].map((mapped) => (
                                    { value: mapped.name, label: mapped.name }                
                                ))
                                } 
                            />
                            )}
    else {
        return (
            <Select
                action="clear"
                placeholder={"Add " + category.replace( /([A-Z])/g, " $1" ).toLowerCase()}
                onChange={(e) =>
                    handleChange({value: e})
                }
                data={Object.keys(FileDirectory[category]).map((mapped) => (
                    {
                        group: mapped,
                        items: FileDirectory[category][mapped].map((option) => (
                            {value: JSON.stringify(option), label: option.name}
                        )
                    )}

                ))
                } 
            />
        );
    }
}

export default ModelDropdown; 