import React, { useState, useEffect, Suspense } from 'react';
import { useStore } from "../zustand/objects";
import { Grid, Modal } from "@mui/material/";
import DeleteIcon from "@mui/icons-material/Delete"
import { LoadingOverlay, Card, Button, Text, Group, ActionIcon, Select } from '@mantine/core';
import { useForm } from '@mantine/form';

import { ScreenshotForProjectsPage } from "./Screenshot"

import {getTemplateScenes} from "../api.js"

export function TemplateModal({ open, close, category }) {
  
  const {
    artboards,
    addArtboardTemplatePositional
  } = useStore();

  const [loading, setLoading] = useState(true)
  const [scenes, setScenes] = useState()

  const handleLoadScenes = () => {
    // getTemplateScenes(category).then(e => console.log(e))
    getTemplateScenes(category).then(e => setScenes(e)).then(() => setLoading(false))
    
  }

  useEffect(() => { 
open && handleLoadScenes()
  },[open])

const handleSubmit = (index, val) => {
    addArtboardTemplatePositional(index - 1, val)
}
    

  return (
    <Modal
    style={{zIndex: 10}}
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title" 
      aria-describedby="modal-modal-description"
    >
      
      <Card style={{borderRadius:32, width: 700, padding:0,
        height: 500,  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
    <LoadingOverlay loaderProps={{ color: 'black' }} visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <Grid container spacing={1} style={{ width:'100%', padding:20, margin:0, height:500, overflow:'scroll', backgroundImage: 'url("/image/background-grid.png")' }}>
            <Grid item xs={12}>
            <Text size="xl" fw={700}>Add a {category} template scene to project</Text>
            </Grid>
       {scenes && !loading && scenes.map((mapped, i) => (
               <Grid key={i} item xs={6} sm={6} md={4}>
              <Card radius={16} padding={0} style={{margin:10}} shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                <ScreenshotForProjectsPage artboard={mapped.scene} />
                <div style={{margin:5}}>
                  <Text fw={700}>
                    {mapped.Name}
                  </Text>
                  <Text size="sm">
                    {mapped.storyboard_fields.description}
                  </Text>
                  </div>
                <Group style={{margin:10}}>
                  
                    <form style={{zIndex: 99999 }}
                    onSubmit={e => {
                        e.preventDefault()
                        handleSubmit(e.target[1].value, mapped)}}>
                    <Select 
                           action="clear"
                           label="Scene"
                           placeholder="1"
                           data={[...Object.keys(artboards)]}
                           defaultValue={1}
                       />
                       <Button
                       type='submit'
                    style={{
                       marginTop:25, inline: true, backgroundColor: 'black', borderRadius: 32
                    }}>
                    Add</Button></form>
                </Group>
              </Card>   
              </Grid>

          ))}
         
        </Grid>
      </Card>
    </Modal>
  );
}

