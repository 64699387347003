import React, { useState, useEffect } from "react";
import SquareIcon from '@mui/icons-material/Square';
import ImageIcon from '@mui/icons-material/Image';
import { useStore } from "../zustand/objects";
import {Box} from "@mui/material/";
import { Button, Menu, Group, ActionIcon, rem, useMantineTheme } from '@mantine/core';


import ModelPoses from '../ModelPoses';

export const FloorColourButton = () => {

  // const [show, setShow] = useState(false);
  // const [showText, setShowText] = useState(false);
  // const [divWidth, setDivWidth] = useState(68);

  // useEffect(() => {
  //   show ? setDivWidth(159) : setDivWidth(68)
  // })

  const colours = [
    { value: 'black', label: 'Black' },
    { value: '#C4A484', label: 'Brown' },
    { value: 'lightBlue', label: 'Light Blue' },
    { value: '#359DED', label: 'Blue' },
    { value: 'lightGreen', label: 'Light Green' },
    { value: '#77D356', label: 'Green' },
    { value: 'lightGrey', label: 'Light Grey' },
    { value: 'grey', label: 'Grey' },
    { value: 'orange', label: 'Orange' },
    { value: '#CD303E', label: 'Red' },
    { value: 'violet', label: 'Violet' },
    { value: 'white', label: 'White' },
    { value: '#FAC710', label: 'Yellow' }
  ]



  const { floorColour, objectsAreLoaded, setFloorColour } = useStore();

  const handleSelect = (mapped) => { mapped && setFloorColour(mapped.value) }

  return (



<Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: "100%",
      justifyContent: 'flex-start',
      margin: "2.5px",
      paddingBottom: 0.5
    }}
    >
    <Menu transitionProps={{ transition: 'pop' }}  withinPortal trigger="hover">
    <Menu.Target>
      <ActionIcon
        variant="transparent"
        color={'black'}
        size={'sm'}
      >
        <div style={{ height: 15, width: 15, backgroundColor: objectsAreLoaded ? floorColour : "white", borderColor: "black", borderStyle: "solid", marginRight: 6, borderRadius: 2 }}></div>
      </ActionIcon>
    </Menu.Target>
    <Menu.Dropdown>
    {colours.map((mapped, i) => (
      <Menu.Item key={i} value={mapped.value} onClick={() => handleSelect(mapped)}><SquareIcon style={{ color: mapped.value }} />{mapped.label}</Menu.Item>
    ))}
    </Menu.Dropdown>
  </Menu>
  </Box>

  );
};







export const ImageButton = () => {

  const { addObject, currentObjectArtboard, switchTextModalOpen, switchUploadModalOpen, switchImageModalOpen, setImagePurpose } = useStore();

  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(68);
  const [hover, setHover] = useState(false)

  useEffect(() => {
    show ? setDivWidth(159) : setDivWidth(68)
  })
 
  const imageArray = [
    // { value: "object", label: "custom image" },
    // { value: "figma", label: "link to figma frame" },
    { value: "apple", label: "Apple UI elements" },
    { value: "background", label: "Background spaces" },
    { value: "floatgridsUi", label: "FloatGrids UI elements" },
    { value: "floatgridsLayout", label: "FloatGrids layouts" },
    { value: "microsoft", label: "Microsoft UI elements" }
]

// FUNCTIONS 
const handleChange = (e) => {
  addObject({
    currentObjectArtboard,
    category: e.value,
    object: e.value,
    undo: false,
    id: null,
    position: null,
    scale: null,
    rotation: null,
    matrix: null,
    pose: {
        name: ModelPoses[e.value] ? ModelPoses[e.value][0] : null,
        modelPath:null
    },            
    imageTexture: null,
    image: null,
    curved: false,
    curveAmount: 3, 
    bGColour: null
})
};

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [clicked, setClicked] = useState(false)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setClicked(true)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShow(false);
    setShowText(false);
    setClicked(false)
  };


  const { objectsAreLoaded } = useStore();

  const handleSelect = (e) => handleChange(e) 

  return (
<Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: "100%",
      justifyContent: 'flex-start',
      margin: "2.5px",
      paddingBottom: 0.5
    }}
    >
    <Menu transitionProps={{ transition: 'pop' }}  withinPortal trigger="hover">
    <Menu.Target>
      <ActionIcon
        variant="transparent"
        color={!hover ? "#424242" : "#868e96"}
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
        size={'xs'}
      >
        <ImageIcon fontSize="small"/>
      </ActionIcon>
    </Menu.Target>
    <Menu.Dropdown>
    {imageArray.map((mapped, i) => (
      // <Menu.Item key={i} value={mapped.value} onClick={() => console.log(mapped)}>{mapped.label}</Menu.Item>
      <Menu.Item key={i} value={mapped.value} onClick={() => handleSelect(mapped)}>{mapped.label}</Menu.Item>
    ))}
    </Menu.Dropdown>
  </Menu>
  </Box>

  );
};