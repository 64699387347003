import React, {useCallback, useEffect} from "react";
import debounce from 'lodash.debounce';

import { useThree, useFrame } from "react-three-fiber";
import { useStore } from "../zustand/objects";
import { useAccountStore } from "../zustand/account";
 
export default function CaptureCursor({currentObjectArtboard}) {
  const setCursor = useStore((state) => state.setCursor);
  const setPreview = useStore((state) => state.setPreview);
  const {isMenuOpen} = useStore()
  const { camera, raycaster, mouse } = useThree()
 
  const useMouseMove = (onMouseMove) => {
    useEffect(() => {
       
      // !isMenuOpen && document.getElementById("viewport_canvas").addEventListener("pointermove", onMouseMove)
      !isMenuOpen && document.addEventListener("pointermove", onMouseMove)

        return () => {
          // document.getElementById("viewport_canvas").removeEventListener("pointermove", onMouseMove)
            document.removeEventListener("pointermove", onMouseMove)
        }
    }, [onMouseMove])
}

useEffect(() => {
  setPreview(null)
},[]) 

useMouseMove(e => {
  handlePointerMoveSet({ rotation: camera.rotation, position: raycaster.ray.origin, mouse: mouse, page: 'editor', device: 'computer', artboard: currentObjectArtboard, name: localStorage.getItem('firstName')  })
});

// I think it happened when changing artboard, maybe it's caused by changing artboard and not yet having a rotation
// useEffect(() => {
//   handlePointerMoveSet({ rotation: camera.rotation, position: raycaster.ray.origin, mouse: mouse, page: 'editor', device: 'computer', artboard: currentObjectArtboard, name: localStorage.getItem('firstName')  })
// },[currentObjectArtboard])

// also seems to occur from adding objects while texture active on something
// there is an error associated to line 1424 in object.js, which is the save function of adding an object

// IT LOOKS LIKE ITS THE SETCURSOR FUNCTION THAT MAKES STUFF GO WRONG. I'VE COMMENTED OUT handlePointerMoveSet below
// and it seems to be unnecessary (might not be)
 
const handlePointerMoveSet = 
useCallback(
    debounce(
      (e) =>
    setCursor(e)
    , 20)
    )

 return ('') 
}