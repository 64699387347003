import React, { useEffect, useState, useMemo } from "react";
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useLocation, useParams } from "react-router-dom";
import PreviewViewport from "../components/PreviewViewport";
import ThirdPersonPreviewViewport from "../components/ThirdPersonPreviewViewport";
import { useStore, subscribeToEditor } from "../zustand/objects";
import SwitchToPreviewButton from "../components/SwitchToPreviewButton";
import SwitchVRMode from "../components/SwitchVRMode";
import StoryboardLinkButton from "../components/StoryboardLinkButton";
import ChangePreviewArtboard from "../components/ChangePreviewArtboard";
import { useGLTF } from '@react-three/drei'
import { getModelURLs } from '../api.js';


function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PreviewPage = ({ match }) => {

  let query = useQuery();

  const queryString = query.get("artboard")

  const { updateArtboard, getSpecificUserProject, unloadObjects, loadObjects, previewVR, objectsAreLoaded, floorColour, artboards, getAIModels } = useStore();

  const { projectKey } = useParams();


  async function unloadProject() {
    unloadObjects()

  }

 

  const {
    liveblocks: { enterRoom, leaveRoom }
  } = useStore();

  useEffect(() => {
    enterRoom(`matchboxxr-editor${projectKey}`);
    return () => {
      leaveRoom(`matchboxxr-editor${projectKey}`);
    };
  }, [enterRoom, leaveRoom]);

  
  const [modelURLs, setModelURLs] = useState()

  const prepareModelURLs = () => {
    getModelURLs(projectKey).then(e => setModelURLs(e.model_urls)).then(() => {
      modelURLs && modelURLs.map((val) => {
        useGLTF.preload(`https://x1hz-knoc-qcpv.e2.xano.io${val.modelPath}`)
      })
    })
  }


  useEffect(() => {
    unloadProject().then(() => 
    getSpecificUserProject(projectKey)).then(() => 
    getAIModels())
    .then(result => loadObjects())
    .then(res =>
      queryString ? updateArtboard(queryString) : updateArtboard('1'))
      .then(e => subscribeToEditor(projectKey))
      .then(() => prepareModelURLs())

  }, []);
  

  return previewVR ?
    <>
      {objectsAreLoaded && <PreviewViewport floorColour={floorColour} artboards={artboards} projectKey={projectKey} />}
      <Toolbar color="transparent" style={{ display: "flex", justifyContent: "end", background: 'transparent', boxShadow: 'none' }} >
        <Box>
          <Stack direction={"row"} spacing={1}>
            <Box style={{ backgroundColor: "white", borderRadius: 4, display: "flex" }} >
              <StoryboardLinkButton projectKey={projectKey} />
              <SwitchVRMode />
              <SwitchToPreviewButton mode={"preview"} projectKey={projectKey} />
            </Box>
          </Stack>
        </Box>
      </Toolbar>

    </>
    : 
    <>
      {objectsAreLoaded && <ThirdPersonPreviewViewport projectKey={projectKey} />}
      <Toolbar color="transparent" style={{ display: "flex", justifyContent: "end", background: 'transparent', boxShadow: 'none' }} >
        <Box>
          <Stack direction={"row"} spacing={1}>
            <Box style={{ backgroundColor: "white", borderRadius: 4, display: "flex" }} >
              <StoryboardLinkButton projectKey={projectKey} />
              <Divider orientation="vertical" variant="middle" flexItem style={{ height: "20px" }} />
              <SwitchVRMode />
              <Divider orientation="vertical" variant="middle" flexItem style={{ height: "20px" }} />
              <SwitchToPreviewButton mode={"preview"} projectKey={projectKey} />
              <Divider orientation="vertical" variant="middle" flexItem style={{ height: "20px" }} />
              <ChangePreviewArtboard />
            </Box>
          </Stack>
        </Box>
      </Toolbar>
    </>

};

export default PreviewPage;
