import React, { Suspense } from 'react';
import Box from '@mui/material/Box';
import debounce from 'lodash.debounce';
import { useStore } from "../zustand/objects";

export default function StoryboardTextField({ fieldType, isEditable, artboard }) {

  const { updateStoryboardFields, storyboardFields } = useStore();
  // const [value, setValue] = useState(storyboardFields[artboard][fieldType].toString());

  // Used debouncing to make sure we don't get 429 error when updaing api with new fieldvalue
  //   const handleChange = (event) => useCallback(debounce(() => {
  //   updateStoryboardFields(artboard, fieldType, event.target.value)
  // }, 500))
// console.log(storyboardFields[artboard].name.toString())
  const handleChange = debounce((e) => {
    updateStoryboardFields(artboard, fieldType, e.target.value)
  }, 500)

  //   const value = artboards[artboard].find(
  //     (x) => x.fieldType === fieldType
  //   ).fieldValue

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, p: 0 },
        mb:1,
        mr:0,
        pr:0
      }}
      noValidate
      autoComplete="off"
      onChange={(e) => handleChange(e)}
    >
      <Suspense fallback={null}>
        <textarea
        style={{width: "100%"}}
        type="text"
        defaultValue={storyboardFields[artboard][fieldType].toString()}
        placeholder={fieldType}
        disabled={!isEditable}
        />
        {/* <TextField
          // onKeyDown={ (event) => handleChange(event)}
          InputLabelProps={{ shrink: true }}
          defaultValue={storyboardFields[artboard][fieldType].toString()}
          id="outlined-multiline-flexible"
          label={fieldType}
          multiline
          maxRows={4}
          onChange={(e) => handleChange(e)}
          disabled={!isEditable}
          sx={{ p: 0 }}
          style={{ borderRadius: 0 }}
        /> */}
        </Suspense>
    </Box>
  );
}