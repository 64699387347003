import React, {useCallback, useEffect, useRef, useState,useMemo} from "react";
import debounce from 'lodash.debounce';
import * as THREE from 'three';

import { useThree, useFrame } from "react-three-fiber";
import { useXR } from "@react-three/xr";
import { useStore } from "../zustand/objects";
import { useAccountStore } from "../zustand/account";
 
export function CaptureHeadset({currentObjectArtboard}) {
  const setPreview = useStore((state) => state.setPreview); 
  const setHeadset = useStore((state) => state.setHeadset);
  const setCursor = useStore((state) => state.setCursor);
  const { camera, raycaster, mouse, scene, gl } = useThree()
  const { player } = useXR()
  const { objectsAreLoaded } = useStore()
 
  const xr = useXR();
  const prevMatrixWorld = useRef(null);
  
  const {firstName} = useAccountStore()

  // ATTEMPT AT COMPARING ROTATION AND POSITION INSTEAD OF MATRIX TO TRIGGER UPDATE
  const prevData = {
    rotation:{},
    position:{}
  }


  // useEffect(() => {
  //   if (xr.isPresenting) {
  //     prevData.rotation = new THREE.Euler( camera.rotation.x.toFixed(1),camera.rotation.y.toFixed(1),camera.rotation.z.toFixed(1), 'XYZ' )
  //     prevData.position = new THREE.Vector3(camera.position.x.toFixed(1), 1.75, camera.position.z.toFixed(1))
  //     // prevMatrixWorld.current = JSON.stringify(camera.matrixWorld); 
  //   }
  // }, [xr.isPresenting]);
  // }, [camera.matrixWorld, xr.isPresenting]);

  //   const [somethingChanged, setSomethingChanged] = useState(false)
 
  // const isDifferent = () => {
  //   if 
  //  (camera.rotation.x.toFixed(1) !== prevData.rotation.x || 
  //   camera.rotation.y.toFixed(1) !== prevData.rotation.y || 
  //   camera.rotation.z.toFixed(1) !== prevData.rotation.z || 
  //   camera.position.x.toFixed(1) !== prevData.position.x || 
  //   camera.position.y.toFixed(1) !== prevData.position.y || 
  //   camera.position.z.toFixed(1) !== prevData.position.z)
  //    {
  //     setSomethingChanged(true)
  //   }
  // }




  // useEffect(() => {
  //   // setPreview(null)
  //   // setCursor(null) 
  // },[]) 
 
  

useEffect(() => {
  // xr.isPresenting && setCursor(null) 
  setCursor(null)
  xr.isPresenting && setPreview({ mouse:mouse, page: 'preview', device: 'vr', artboard: currentObjectArtboard, name: localStorage.getItem('firstName') ? localStorage.getItem('firstName') : "Guest" })
  xr.isPresenting && setHeadset({ position: new THREE.Vector3(camera.position.x.toFixed(2), 1.75, camera.position.z.toFixed(2)), rotation: new THREE.Euler( camera.rotation.x.toFixed(2),camera.rotation.y.toFixed(2),camera.rotation.z.toFixed(2), 'XYZ' )})
  // just changed device from computer to vr 21 Sep
  // xr.isPresenting && handleCameraMoveSet({ rotation: camera.rotation, position: raycaster.ray.origin, mouse: mouse, page: 'preview', device: 'computer', artboard: currentObjectArtboard, name: localStorage.getItem('firstName') ? localStorage.getItem('firstName') : "Guest"})
},[xr.isPresenting, currentObjectArtboard])
// },[currentObjectArtboard])

useFrame(() => {
  // useMemo(() => {
    if (xr.isPresenting) {
      // if (somethingChanged === false) {isDifferent()}
      //current matrixworld is the position now
      // prev is what is was when last captured upon movement
      // so this compares the two, updating when there's a change
            // const currentMatrixWorld = JSON.stringify(camera.matrixWorld);
      // if (prevMatrixWorld.current !== currentMatrixWorld) {
        // else if (somethingChanged === true) {
      if
        (camera.rotation.x.toFixed(2) !== prevData.rotation.x ||
        camera.rotation.y.toFixed(2) !== prevData.rotation.y ||
        camera.rotation.z.toFixed(2) !== prevData.rotation.z ||
        camera.position.x.toFixed(2) !== prevData.position.x ||
        camera.position.y.toFixed(2) !== prevData.position.y ||
        camera.position.z.toFixed(2) !== prevData.position.z) {

          handleCameraMoveSet({position: new THREE.Vector3(camera.position.x.toFixed(2), 1.75, camera.position.z.toFixed(2)), rotation: new THREE.Euler( camera.rotation.x.toFixed(2),camera.rotation.y.toFixed(2),camera.rotation.z.toFixed(2), 'XYZ') })
          // handleCameraMoveSet({ rotation: new THREE.Euler( camera.rotation.x.toFixed(2),camera.rotation.y.toFixed(2),camera.rotation.z.toFixed(2), 'XYZ' ), position: new THREE.Vector3(camera.position.x.toFixed(2), 1.75, camera.position.z.toFixed(2)), mouse: mouse, page: 'preview', device: 'vr', artboard: currentObjectArtboard, name: localStorage.getItem('firstName') ? localStorage.getItem('firstName') : "Guest"})
        // handleCameraMoveSet({ position: new THREE.Vector3(camera.position.x.toFixed(1), 1.75, camera.position.z.toFixed(1)),rotation: new THREE.Euler( camera.rotation.x.toFixed(1),camera.rotation.y.toFixed(1),camera.rotation.z.toFixed(1), 'XYZ' ) })
        prevData.position = new THREE.Vector3(camera.position.x.toFixed(2), 1.75, camera.position.z.toFixed(2)) 
        prevData.rotation = new THREE.Euler( camera.rotation.x.toFixed(2),camera.rotation.y.toFixed(2),camera.rotation.z.toFixed(2), 'XYZ') 
        // setSomethingChanged(false)
        
              // prevMatrixWorld.current = currentMatrixWorld;
      }
    }
  });

   const handleCameraMoveSet = 
   useCallback(
       debounce(
         (e) =>
        //  setPreviewPositionRotation(e)
        setHeadset(e)
       , 20)
       )



 return ('')
}



export function CaptureCamera({currentObjectArtboard}) {
    const setPreview = useStore((state) => state.setPreview);
    const setHeadset = useStore((state) => state.setHeadset);
    const setCursor = useStore((state) => state.setCursor);
    const { camera, raycaster, mouse } = useThree()
    const { overTransformControls } = useStore()
    

      const useMouseMove = (onMouseMove) => {
        useEffect(() => {
          // !overTransformControls && document.addEventListener("pointermove", onMouseMove)
          document.addEventListener("pointermove", onMouseMove)
          // console.log(onMouseMove)
            return () => {
                document.removeEventListener("pointermove", onMouseMove)
            }
        }, [onMouseMove])
    }
  
    const {firstName} = useAccountStore()
 

    useEffect(() => {
      setCursor(null)
      setPreview({ mouse: mouse, page: 'preview', device: 'computer', artboard: currentObjectArtboard, name: localStorage.getItem('firstName') ? localStorage.getItem('firstName') : "Guest" })
      setHeadset({ position: raycaster.ray.origin, rotation: camera.rotation})
    },[])

    useMouseMove(e => {
      handleCameraMoveSet({ position: raycaster.ray.origin, rotation: camera.rotation})
      // handleCameraMoveSet({ position: camera.position, rotation: camera.rotation })
      // handleCameraMoveSet({ rotation: camera.rotation, position: camera.position, page: 'preview', device: 'laptop', artboard: currentObjectArtboard, name: localStorage.getItem('firstName') ? localStorage.getItem('firstName') : "Guest" })
    });


useEffect(() => {
  setPreview({ mouse: mouse, page: 'preview', device: 'computer', artboard: currentObjectArtboard, name: localStorage.getItem('firstName') ? localStorage.getItem('firstName') : "Guest" })
  setHeadset({ position: raycaster.ray.origin, rotation: camera.rotation})
},[currentObjectArtboard])


   const handleCameraMoveSet = 
   useCallback(
       debounce(
         (e) =>
         setHeadset(e)
       , 20)
       )

   return ('')
  }