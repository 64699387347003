import React, { useEffect, useState, useRef, Suspense, memo } from "react";
import { useStore } from "../zustand/objects";
import LayerListItem from "./LayerListItem";
import {isMobile} from 'react-device-detect';
import debounce from 'lodash.debounce';
import LockIcon from '@mui/icons-material/Lock';




// MANTINE
import { Accordion, Card, Button, Image, Text, Group, Badge, ActionIcon } from '@mantine/core';



const LayerList = ({mobile}) => {
  const {
    objectsAreLoaded,
    artboards,
    currentObjectArtboard,
    addToGroupedObjects,
    removeFromGroupedObjects,
    grouping,
    setGrouping,
    selectedObjectID,
    updateObjectSelected,
    groupedObjects,
    textOpen,
    textModalOpen,
    removeObject
  } = useStore();

const checkboxClick = (e) => {
  e.currentTarget.checked ? addToGroupedObjects(e.target.value) : removeFromGroupedObjects(e.target.value) 
}

const handleGroupClick = () => {
  setGrouping()
} 

const list = useRef()

const [value, setValue] = useState(null);

useEffect(() => {
  if (objectsAreLoaded === true) {
    const element = document.getElementById(selectedObjectID);
    const table = document.getElementById('table');
    if (selectedObjectID !== '0' && selectedObjectID !== null) { 
      element.scrollIntoView({ behavior: 'smooth' }) 
    } 
    else {
      table.scrollTo({top:0})}
    }
},[selectedObjectID]);

const closeListItem = () => {
  updateObjectSelected('0');
};


  // KEYBOARD OBJECT FUNCTIONS
  function handleKeyDown(event) {
    if (event.repeat) { return }
    if (event.key === 'Backspace') { 
      if (textModalOpen === false) {
      // if (textOpen === false && textModalOpen === false) {
        removeObject({ currentObjectArtboard: currentObjectArtboard, id:selectedObjectID, undo: false })
        updateObjectSelected('0')}
    } 
    else if (event.key === 'Escape') {
      closeListItem()
    }
    else if (event.key === 'Tab') { 
      
      const index = artboards[currentObjectArtboard].findIndex(obj => obj.id === selectedObjectID);
      const arrayLength = artboards[currentObjectArtboard].length
      let nextIndex;
      if (index === arrayLength - 1) { // Reached the end, cycle back to the beginning
        nextIndex = 0;
      } else {
        nextIndex = index + 1; // Move to the next object
      }  
      (textModalOpen === false) && updateObjectSelected(artboards[currentObjectArtboard][nextIndex].id);
    }
  }

  // KEYBOARD OBJECT FUNCTIONS
  function handleKeyDownNotSelected(event) {
    if (event.repeat) { return }
    if (event.key === 'Tab') {    
      // (textModalOpen === false) && console.log(artboards[currentObjectArtboard][0].id);
      (textModalOpen === false) && updateObjectSelected(artboards[currentObjectArtboard][0].id);
    }
  } 

  
  

  useEffect(() => {
    if (artboards[currentObjectArtboard].length > 0) {
      if (selectedObjectID === '0') {
        document.addEventListener('keydown', handleKeyDownNotSelected)
      }
      else {
        document.addEventListener('keydown', handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown);
      } 
      return () => document.removeEventListener('keydown', handleKeyDownNotSelected)
    }
      
      
  },[objectsAreLoaded, selectedObjectID, textModalOpen])





 
  if (!objectsAreLoaded) {
    return <div></div>;
  } else { 
    return ( 
    // <Card padding={5} style={{backgroundColor: 'rgba(255,255,255,0.5)', borderRadius:16, minHeight:50, maxHeight:600}}>
      <ul id='table' ref={list} style={{paddingLeft: 0, paddingBottom:5, overflow: 'scroll',  listStyle:"none", marginTop: "0px", marginBottom: "0px", overflowX: "hidden", overflowY: "auto"}}>
        <Accordion variant="contained" value={selectedObjectID} onChange={e => updateObjectSelected(e)}>
       {/* {artboards[currentObjectArtboard].length < 1 ? <div>Add something!</div> : <button onClick={() => handleGroupClick()} style={{ padding: 2, fontSize: '12px', marginBottom: 3}}>{grouping ? "Cancel grouping" : "Select multiple"}</button>} */}
        {artboards[currentObjectArtboard].map((objects, i) => (
          <Suspense key={objects.id} fallback={null}>
          <li style={{paddingTop: i >= 1 ? 8 : 0}}>
          <Accordion.Item value={objects.id}>
          <Accordion.Control   
           icon={objects.isLocked && <LockIcon fontSize='small'/>}>
            <Text 
            c={"#424242"} 
            // c={selectedObjectID === objects.id ? "#424242" : "#868e96"} 
            fw={selectedObjectID === objects.id && 500}
            size="xs">
              {objects.objectTitle}</Text></Accordion.Control>
            <div id={objects.id} style={{display: "flex"}}>{grouping && objects.id !== "camera" && <input value={objects.id} type="checkbox" style={{cursor: "pointer", marginRight: 5}} onChange={(e) => checkboxClick(e)} checked={groupedObjects.includes(objects.id)}/>}
            <Accordion.Panel>
            {objects.id !== 'camera' ?
            
            selectedObjectID === objects.id && <LayerListItem
              key={objects.id}
              id={objects.id} 
              position={objects.position}
              rotation={objects.rotation} 
              destination={objects.destination}
              scale={objects.scale}
              category={objects.category}
              object={objects.object}
              transformMode={objects.transformMode}
              content={objects.content}
              textKind={objects.textKind}
              colour={objects.colour}
              bGColour={objects.bGColour}
              pose={objects.pose}
              title={objects.objectTitle}
              texture={objects.imageTexture}
              isLocked={objects.isLocked}
              matrix={objects.matrixState}
              figmaID={objects.figmaID}
              figmaURL={objects.figmaURL}
              figmaSize={objects.figmaSize}
              image={objects.image}
              imageSize={objects.imageSize}
              curved={objects.curved}
              curveAmount={objects.curveAmount}
              uploadedURL={objects.uploadedURL}
            />
            : ''}
            </Accordion.Panel>
            </div>
            </Accordion.Item>
          </li>
          </Suspense>
        ))}
      </Accordion>
      </ul>
      // {/* // </Card> */}
    );
  }
};

export default LayerList;
